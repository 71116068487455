// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: Fix types in this component

import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { DatePickerStyles, DateInputWrapper } from './DatePickerStyles';
import enGB from 'date-fns/locale/en-GB';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { TextInput, TextInputProps } from '../Input/TextInput/TextInput';
import { DateRangeOption, DateRangeParam } from './types';
import DatePickerRangeAddon from './DatePickerRangeAddon';

export type DatePickerProps = {
  dateRangeAddonOptions?: DateRangeOption<DateRangeParam>[];
  useCustomMonthYearPicker?: boolean;
} & ReactDatePickerProps &
  TextInputProps;

const DatePickerInput = forwardRef<HTMLInputElement>((props, ref) => (
  <DateInputWrapper>
    <TextInput
      name="datepicker"
      type="text"
      id="datepicker"
      leftIndicatorIcon="calendar"
      rightIndicatorIcon="chevronDown"
      {...props}
      ref={ref}
    />
  </DateInputWrapper>
));

const _DatePicker = ({
  selectsRange,
  customInput,
  onChange,
  selected,
  startDate,
  endDate,
  locale,
  placeholderText,
  renderCustomHeader,
  useCustomMonthYearPicker,
  dateRangeAddonOptions,
  ...props
}: DatePickerProps) => {
  const CUSTOM_YEAR_PICKER_RANGE = 15;

  const isRangePicker = !!selectsRange || startDate !== undefined || endDate !== undefined;
  const hasRangeAddon = dateRangeAddonOptions && dateRangeAddonOptions.length;
  const dayClassName = isRangePicker && 'selects-range';

  const [date, setDate] = useState<Date | null>(selected || null);
  const [range, setRange] = useState<[Date | null, Date | null]>([startDate || null, endDate || null]);
  const [isMonthPicker, setIsMonthPicker] = useState<boolean>(false);
  const [isYearPicker, setIsYearPicker] = useState<boolean>(false);
  const [start, end] = range;

  const onChangeHandler = useCallback<ReactDatePickerProps['onChange']>(
    value => {
      if (onChange) {
        onChange(value);
      }
      isRangePicker ? setRange(value) : setDate(value);
      if (isMonthPicker) {
        setIsMonthPicker(false);
      }
      if (isYearPicker) {
        setIsYearPicker(false);
        setIsMonthPicker(true);
      }
    },
    [isRangePicker, isMonthPicker, isYearPicker, setRange, setDate, onChange]
  );

  useEffect(() => {
    if (startDate?.getTime() !== range[0]?.getTime()) setRange(([, eDate]) => [startDate, eDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (endDate?.getTime() !== range[1]?.getTime()) setRange(([sDate]) => [sDate, endDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const onClickToggleMonthYearPicker = () => {
    if (isMonthPicker) {
      setIsYearPicker(true);
      setIsMonthPicker(false);
    } else if (isYearPicker) {
      setIsYearPicker(false);
    } else {
      setIsMonthPicker(true);
    }
  };

  const resetMonthYearToggles = () => {
    setIsMonthPicker(false);
    setIsYearPicker(false);
  };

  return (
    <>
      <DatePickerStyles />
      <DatePicker
        wrapperClassName="vault-date-picker"
        popperClassName="vault-date-picker-popper"
        calendarClassName={'vault-date-picker-calendar' + (hasRangeAddon ? ' with-range-addon' : '')}
        dateFormat="dd MMMM yyyy"
        dayClassName={() => dayClassName || ''}
        selectsRange={isRangePicker}
        disabledKeyboardNavigation
        onChange={onChangeHandler}
        selected={date || start}
        startDate={start}
        endDate={end}
        locale={locale || enGB}
        showPopperArrow={false}
        placeholderText={placeholderText}
        customInput={customInput || <DatePickerInput {...props} />}
        popperPlacement="bottom-start"
        onCalendarClose={
          useCustomMonthYearPicker ? resetMonthYearToggles : props.onCalendarClose ? props.onCalendarClose : undefined
        }
        yearItemNumber={
          useCustomMonthYearPicker ? CUSTOM_YEAR_PICKER_RANGE : props.yearItemNumber ? props.yearItemNumber : undefined
        }
        showYearPicker={isYearPicker || props.showYearPicker}
        showMonthYearPicker={isMonthPicker || props.showMonthYearPicker}
        shouldCloseOnSelect={(!isMonthPicker && !isYearPicker) || props.shouldCloseOnSelect}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 1],
            },
          },
        ]}
        renderCustomHeader={
          useCustomMonthYearPicker
            ? ({
                date,
                decreaseMonth,
                increaseMonth,
                decreaseYear,
                increaseYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <>
                  <div className="react-datepicker__header">
                    <button
                      className="react-datepicker__current-month react-datepicker__navigation--toggle-mode"
                      onClick={onClickToggleMonthYearPicker}
                    >
                      {date.toLocaleString(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        locale ? (locale.hasOwnProperty('code') ? (locale as any).code : locale) : 'en-GB',
                        {
                          month: !isMonthPicker && !isYearPicker ? 'long' : undefined,
                          year: 'numeric',
                        }
                      )}
                    </button>
                  </div>
                  {!prevMonthButtonDisabled && (
                    <button
                      type="button"
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      aria-label="Previous Month"
                      onClick={isMonthPicker || isYearPicker ? decreaseYear : decreaseMonth}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                        Previous Month
                      </span>
                    </button>
                  )}
                  {!nextMonthButtonDisabled && (
                    <button
                      type="button"
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      aria-label="Next Month"
                      onClick={isMonthPicker || isYearPicker ? increaseYear : increaseMonth}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                        Next Month
                      </span>
                    </button>
                  )}
                </>
              )
            : renderCustomHeader
            ? renderCustomHeader
            : undefined
        }
        {...props}
      >
        {hasRangeAddon && (
          <DatePickerRangeAddon rangeOptions={dateRangeAddonOptions} onRangeChangeHandler={onChangeHandler} />
        )}
      </DatePicker>
    </>
  );
};

export { _DatePicker as DatePicker };
