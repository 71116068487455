import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80602 19.71H6.47102C5.26702 19.71 4.29002 18.734 4.29002 17.529V16.097C4.29002 15.519 4.06002 14.964 3.65102 14.555L2.63802 13.542C1.78602 12.69 1.78602 11.31 2.63802 10.458L3.65102 9.44502C4.06002 9.03602 4.29002 8.48202 4.29002 7.90302V6.47102C4.29002 5.26702 5.26602 4.29002 6.47102 4.29002H7.90302C8.48102 4.29002 9.03602 4.06002 9.44502 3.65102L10.458 2.63802C11.31 1.78602 12.69 1.78602 13.542 2.63802L14.555 3.65102C14.964 4.06002 15.519 4.29002 16.097 4.29002H17.529C18.733 4.29002 19.71 5.26602 19.71 6.47102V7.90302C19.71 8.48102 19.94 9.03602 20.349 9.44502L21.362 10.458C22.214 11.31 22.214 12.69 21.362 13.542L20.349 14.555C19.94 14.964 19.71 15.519 19.71 16.097V17.529C19.71 18.733 18.734 19.71 17.529 19.71H16.097C15.519 19.71 14.964 19.94 14.555 20.349L13.542 21.362C12.69 22.214 11.31 22.214 10.458 21.362L8.80602 19.71Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 15L15 9" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.249 9C9.111 9 8.999 9.112 9 9.25C9 9.388 9.112 9.5 9.25 9.5C9.388 9.5 9.5 9.388 9.5 9.25C9.5 9.112 9.388 9 9.249 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.749 14.5C14.611 14.5 14.499 14.612 14.5 14.75C14.5 14.888 14.612 15 14.75 15C14.888 15 15 14.888 15 14.75C15 14.612 14.888 14.5 14.749 14.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
