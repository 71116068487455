import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.43271 8.42315L10.9623 5.07232C11.5993 4.68632 12.398 4.68626 13.0351 5.07215L18.567 8.42318C19.0157 8.695 19.2898 9.18151 19.2898 9.70615V19.2533H14.5V14.5C14.5 13.6716 13.8284 13 13 13H11C10.1716 13 9.5 13.6716 9.5 14.5V19.2533H4.71008V9.70599C4.71008 9.18144 4.9841 8.695 5.43271 8.42315Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
