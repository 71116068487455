import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 27.9988C4.5 15.0208 15.0208 4.5 27.9988 4.5C40.9769 4.5 51.4977 15.0208 51.4977 27.9988C51.4977 40.9769 40.9769 51.4977 27.9988 51.4977C15.0208 51.4977 4.5 40.9769 4.5 27.9988ZM27.9988 1.5C13.3639 1.5 1.5 13.3639 1.5 27.9988C1.5 42.6337 13.3639 54.4977 27.9988 54.4977C42.6337 54.4977 54.4977 42.6337 54.4977 27.9988C54.4977 13.3639 42.6337 1.5 27.9988 1.5ZM36.9566 15.2278C36.5301 14.5176 35.6086 14.2875 34.8984 14.714C34.1882 15.1405 33.9581 16.0619 34.3846 16.7722C38.5399 23.6923 38.5399 32.3077 34.3846 39.2278C33.9581 39.938 34.1882 40.8595 34.8984 41.286C35.6086 41.7124 36.5301 41.4824 36.9566 40.7722C41.6826 32.9015 41.6826 23.0984 36.9566 15.2278ZM29.9051 18.3894C29.4479 17.6985 28.5172 17.5091 27.8264 17.9663C27.1355 18.4235 26.9461 19.3542 27.4033 20.045C30.7174 25.0528 30.7174 31.528 27.4033 36.5357C26.9461 37.2266 27.1355 38.1572 27.8264 38.6144C28.5172 39.0716 29.4479 38.8822 29.9051 38.1914C33.8835 32.1798 33.8835 24.4009 29.9051 18.3894ZM23.5967 21.3401C23.1464 20.6448 22.2176 20.4462 21.5223 20.8966C20.827 21.347 20.6284 22.2757 21.0788 22.971C23.1915 26.2327 23.1915 30.4123 21.0788 33.674C20.6284 34.3693 20.827 35.298 21.5223 35.7484C22.2176 36.1988 23.1464 36.0002 23.5967 35.3049C26.3522 31.0509 26.3522 25.5941 23.5967 21.3401ZM17.2776 23.9665C16.8435 23.2609 15.9196 23.0409 15.214 23.475C14.5084 23.9091 14.2884 24.833 14.7225 25.5386C15.6524 27.0498 15.6524 28.947 14.7226 30.4579C14.2884 31.1634 14.5084 32.0873 15.2139 32.5215C15.9194 32.9557 16.8433 32.7357 17.2775 32.0302C18.8008 29.5549 18.8006 26.4417 17.2776 23.9665Z"
        fill={fill}
      />
    </svg>
  );
}
