import { useMedia } from '../../hooks/useMedia';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { Text } from '../Text/Text';
import { Loader } from '../Loader/Loader';
import { ReactElement, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export interface PaginationProps {
  changePageAction: (page: number) => void;
  pagesCount: number;
  pageNumber?: number;
  isFetching?: boolean;
  pageQueryParam?: string;
  showTopPagination?: boolean;
  showBottomPagination?: boolean;
  children: ReactElement;
}

export const Pagination = ({
  changePageAction,
  pageQueryParam,
  pagesCount,
  pageNumber,
  isFetching = false,
  showTopPagination = false,
  showBottomPagination = true,
  children,
}: PaginationProps) => {
  const { t } = useTranslation();
  const isMobile = useMedia('mobile');
  const [queryParams, setQueryParams] = useSearchParams();
  const queriedPageNumber = pageQueryParam ? parseInt(queryParams.get(pageQueryParam) || `${pageNumber}` || '1') : null;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === pagesCount;

  const setPageQueryParam = useCallback(
    (num: number) => {
      pageQueryParam &&
        (queryParams.get(pageQueryParam)?.length || num !== 1) &&
        setQueryParams(
          num <= 1
            ? params => {
                params.delete(pageQueryParam);
                return params;
              }
            : params => ({ ...Object.fromEntries(params), [pageQueryParam]: `${num}` })
        );
    },
    [pageQueryParam, queryParams, setQueryParams]
  );

  const setPage = useCallback(
    (num: number) => {
      changePageAction(num);
      if (queriedPageNumber !== null && isNaN(queriedPageNumber)) {
        setPageQueryParam(num);
      }
    },
    [changePageAction, queriedPageNumber, setPageQueryParam]
  );

  // preventing pagination going out of bonds
  useEffect(() => {
    if (pagesCount > 0) {
      pageNumber !== undefined && pageNumber < 1 && changePageAction(1);
      pageNumber !== undefined && pageNumber > pagesCount && changePageAction(pagesCount);
    } else {
      console.error(`Invalid pages count passed to pagination component: ${pagesCount}`);
    }
  }, [changePageAction, pageNumber, pagesCount]);

  // updating query params when new pageNumber is passed to component
  useEffect(() => {
    if (pageNumber) {
      setPageQueryParam(pageNumber);
      setPage(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  // reacting on load to defined pagination query param changes if pageNumber not passed
  useEffect(() => {
    if (pageNumber === undefined) {
      queriedPageNumber !== null && setPage(isNaN(queriedPageNumber) ? 1 : Math.min(queriedPageNumber, pagesCount));
    }
  }, [pageNumber, pagesCount, queriedPageNumber, setPage]);

  const PaginationNavigation = () =>
    pagesCount > 0 && pageNumber ? (
      <PaginationNav>
        <PaginationActions>
          <PaginationItem size="s" variant="tertiary" onClick={() => setPage(1)} disabled={isFirstPage}>
            <Icon size="s" name="doubleChevronLeft" color="positive" />
            {!isMobile && (
              <Text size="s" color="positive">
                {t('first')}
              </Text>
            )}
          </PaginationItem>
          <PaginationItem size="s" variant="tertiary" onClick={() => setPage(pageNumber - 1)} disabled={isFirstPage}>
            <Icon size="s" name="curvedChevronLeft" color="positive" />
            {!isMobile && (
              <Text size="s" color="positive">
                {t('prev')}
              </Text>
            )}
          </PaginationItem>
        </PaginationActions>
        <PaginationCounter size="s">
          {pageNumber}/{pagesCount}
        </PaginationCounter>
        <PaginationActions>
          <PaginationItem size="s" variant="tertiary" onClick={() => setPage(pageNumber + 1)} disabled={isLastPage}>
            {!isMobile && (
              <Text size="s" color="positive">
                {t('next')}
              </Text>
            )}
            <Icon size="s" name="curvedChevronRight" color="positive" />
          </PaginationItem>
          <PaginationItem size="s" variant="tertiary" onClick={() => setPage(pagesCount)} disabled={isLastPage}>
            {!isMobile && (
              <Text size="s" color="positive">
                {t('last')}
              </Text>
            )}
            <Icon size="s" name="doubleChevronRight" color="positive" />
          </PaginationItem>
        </PaginationActions>
      </PaginationNav>
    ) : null;

  return (
    <>
      {showTopPagination && <PaginationNavigation />}
      <PaginationContentWrapper>
        {children}
        {isFetching && <Loader type="spinner" opacity={0.7} isFullScreen={false} />}
      </PaginationContentWrapper>
      {showBottomPagination && <PaginationNavigation />}
    </>
  );
};

const PaginationContentWrapper = styled.section`
  position: relative;
`;

const PaginationNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacings['2']} 0;
`;

const PaginationActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings['1']};
`;

const PaginationCounter = styled(Text)`
  padding: 0 ${({ theme }) => theme.spacings['1']};
`;

const PaginationItem = styled(Button)`
  display: flex;
  gap: ${({ theme }) => theme.spacings['0.5']};
`;
