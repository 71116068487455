import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 12C5.5 15.5899 8.41015 18.5 12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM8.25 15C8.25 14.5858 8.58579 14.25 9 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15C15.75 15.4142 15.4142 15.75 15 15.75H9C8.58579 15.75 8.25 15.4142 8.25 15ZM12.5995 7.55003C12.4579 7.36118 12.2356 7.25003 11.9995 7.25003C11.7634 7.25003 11.5412 7.36118 11.3995 7.55003L8.39952 11.55C8.22907 11.7773 8.20165 12.0814 8.3287 12.3354C8.45574 12.5895 8.71544 12.75 8.99952 12.75H14.9995C15.2836 12.75 15.5433 12.5895 15.6703 12.3354C15.7974 12.0814 15.77 11.7773 15.5995 11.55L12.5995 7.55003Z"
        fill={fill}
      />
    </svg>
  );
}
