import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M49.7787 38.0425C52.4611 34.8763 54 30.5911 54 26.2857V14.8571H50.545C50.2709 12.6471 49.2097 10.6144 47.5608 9.14076C45.9119 7.66715 43.7886 6.854 41.5897 6.854C39.3908 6.854 37.2676 7.66715 35.6187 9.14076C33.9697 10.6144 32.9086 12.6471 32.6345 14.8571H29.7048L25.5169 0H10V2.28571H23.8155L27.3581 14.8571H22.4103V26.2857C22.4103 30.5911 23.9446 34.88 26.6306 38.0425C29.3266 41.2242 33.0027 43.0949 37.0769 43.3865V61.7143H25.7949V64H50.6154V61.7143H39.3333V43.3865C43.4066 43.0921 47.0827 41.2242 49.7787 38.0425ZM41.5897 9.14286C43.1886 9.14513 44.7353 9.71989 45.9563 10.7655C47.1773 11.8112 47.9941 13.2604 48.2624 14.8571H34.9162C35.1845 13.2603 36.0014 11.8109 37.2226 10.7653C38.4439 9.71957 39.9907 9.14492 41.5897 9.14286ZM24.6667 26.2857V17.1429H28.0062L32.6092 33.4565L34.7754 32.8283L30.3528 17.1429H51.7436V26.2857C51.7436 33.5881 46.6793 41.1429 38.2051 41.1429C29.731 41.1429 24.6667 33.5881 24.6667 26.2857Z"
      />
    </svg>
  );
}
