import { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from '../Link/Link';
import { Text } from '../Text/Text';
import { useTranslation } from 'react-i18next';

export type AccordionGroupProps = {
  children: ReactNode;
  isCollapsed?: boolean;
  title?: string;
};

type AccordionGroupContextType = { updateToggle?: () => void; isGroupCollapsed?: boolean | undefined };

export const AccordionGroupContext = createContext<AccordionGroupContextType>({});

export default function AccordionGroup({ title, children, isCollapsed = true, ...props }: AccordionGroupProps) {
  const ref = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  const [isGroupCollapsed, setIsGroupCollapsed] = useState<boolean | undefined>(isCollapsed);
  const [shouldCollapse, setShouldCollapse] = useState(false);

  useEffect(() => {
    setIsGroupCollapsed(isCollapsed);
  }, [isCollapsed]);

  const toggleGroup = () => {
    // update all descendant accordions' state based on action passed
    setIsGroupCollapsed(shouldCollapse);
  };

  const updateToggle = () => {
    // triggered when any accordion descendant toggle state changed
    const groupContainer = ref.current;
    if (groupContainer) {
      // reset group collapsed state to undefined
      setIsGroupCollapsed(undefined);
      // change expand / collapse action based on current state of all accordions (whether all are expanded or not)
      setShouldCollapse(
        groupContainer.querySelectorAll('.accordion').length === groupContainer.querySelectorAll('.expanded').length
      );
    }
  };

  return (
    <AccordionGroupContext.Provider value={{ updateToggle, isGroupCollapsed }}>
      <section ref={ref}>
        <AccordionGroupHeader {...props}>
          <Text size="m">{title}</Text>
          <Link size="s" color="linkNormal" onClick={toggleGroup}>
            {shouldCollapse ? t('collapseAll') : t('expandAll')}
          </Link>
        </AccordionGroupHeader>
        {children}
      </section>
    </AccordionGroupContext.Provider>
  );
}

const AccordionGroupHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacings[1]};

  & > button {
    padding: ${({ theme }) => `${theme.spacings[1]} ${theme.spacings[1.5]}`};
    font-weight: 500;
  }
`;
