import styled from 'styled-components';
import { iconMapping } from './iconMapping';
import { IconProps } from './types';
import { useTheme } from '../../hooks/useTheme';

const defaultStroke = 'primary';
const defaultFill = 'primary';
const wrapperSizes = {
  s: '16px',
  m: '24px',
  l: '64px',
};

export type IconSize = keyof typeof wrapperSizes;

export default function Icon({
  size,
  fill = defaultFill,
  stroke = defaultStroke,
  name,
  hidden,
  color,
  ...props
}: IconProps) {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const IconComponent = (iconMapping[size] as any)[name];

  return (
    <IconWrapper size={wrapperSizes[size]} hidden={hidden} {...props}>
      <IconComponent fill={theme.color[color || fill]} stroke={theme.color[color || stroke]} />
    </IconWrapper>
  );
}

const IconWrapper = styled.span<{ size: string; hidden?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  flex-shrink: 0;
`;
