import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28ZM55 28C55 42.9117 42.9117 55 28 55C13.0883 55 1 42.9117 1 28C1 13.0883 13.0883 1 28 1C42.9117 1 55 13.0883 55 28ZM19.1772 38.7287C20.4738 34.9547 23.9542 32.2931 28 32.2931C32.0458 32.2931 35.5262 34.9547 36.8228 38.7287C37.0919 39.5122 37.9453 39.9292 38.7287 39.66C39.5122 39.3908 39.9292 38.5375 39.66 37.754C37.975 32.8493 33.4106 29.2931 28 29.2931C22.5894 29.2931 18.025 32.8493 16.34 37.754C16.0708 38.5375 16.4878 39.3908 17.2713 39.66C18.0547 39.9292 18.9081 39.5122 19.1772 38.7287ZM26.1379 18.6897C26.1379 20.7464 24.4706 22.4138 22.4138 22.4138C20.357 22.4138 18.6897 20.7464 18.6897 18.6897C18.6897 16.6329 20.357 14.9655 22.4138 14.9655C24.4706 14.9655 26.1379 16.6329 26.1379 18.6897ZM33.5862 22.4138C35.643 22.4138 37.3103 20.7464 37.3103 18.6897C37.3103 16.6329 35.643 14.9655 33.5862 14.9655C31.5294 14.9655 29.8621 16.6329 29.8621 18.6897C29.8621 20.7464 31.5294 22.4138 33.5862 22.4138Z"
        fill={fill}
      />
    </svg>
  );
}
