import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 7C4.33579 7 4 7.33579 4 7.75C4 8.16421 4.33579 8.5 4.75 8.5H19.25C19.6642 8.5 20 8.16421 20 7.75C20 7.33579 19.6642 7 19.25 7H4.75ZM4.75 15C4.33579 15 4 15.3358 4 15.75C4 16.1642 4.33579 16.5 4.75 16.5H19.25C19.6642 16.5 20 16.1642 20 15.75C20 15.3358 19.6642 15 19.25 15H4.75Z"
        fill={fill}
      />
    </svg>
  );
}
