import {
  defaultTools
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetCensoredFunc,
  GetCountryFunc,
  ValidateFunc
} from './interfaces'
import {
  validateBitCheckSum
} from './utilities'

const netherlands: CountryFunc = (personalNumber: string): CountryTools => {
  const format = '^([0-9]{9})$'

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  const validate: ValidateFunc = () => validateBitCheckSum(personalNumber, [9, 8, 7, 6, 5, 4, 3, 2, -1])

  const result: CountryTools = defaultTools()

  if (!matches || !validate()) {
    return result
  }

  result.validate = validate

  const getCensored: GetCensoredFunc = (): string => '*********'

  const getCountry: GetCountryFunc = (): string => 'netherlands'

  result.getCensored = getCensored
  result.getCountry = getCountry

  return result
}

export default netherlands
