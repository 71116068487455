import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9657 4.75H14.4188C17.0887 4.75 19.2531 6.90146 19.2531 9.55543C19.2531 12.2094 17.0887 14.3609 14.4188 14.3609H4.75006M4.75006 14.3609L8.61755 9.55528M4.75006 14.3609L8.61755 19.2525"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
