import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.238 14.7996C13.7387 15.3003 13.8885 16.0534 13.6175 16.7076C13.3465 17.3618 12.7082 17.7883 12 17.7883C11.2919 17.7883 10.6536 17.3618 10.3826 16.7076C10.1116 16.0534 10.2614 15.3003 10.7621 14.7996C11.4458 14.1159 12.5543 14.1159 13.238 14.7996"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7398 10.2977C18.4235 10.9814 18.4235 12.0899 17.7398 12.7736C17.0561 13.4573 15.9476 13.4573 15.2639 12.7736C14.5802 12.0899 14.5802 10.9814 15.2639 10.2977C15.5923 9.96936 16.0376 9.78491 16.5019 9.78491C16.9662 9.78491 17.4115 9.96936 17.7398 10.2977"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0037 13.0005V5.24728C21.0037 4.00412 19.9959 2.99634 18.7528 2.99634H5.24715C4.00399 2.99634 2.99622 4.00412 2.99622 5.24728V13.0005"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0021 16.0018C18.0854 15.9953 19.1405 16.347 20.0033 17.0022"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.24883 11.75C9.24883 12.7169 8.46501 13.5007 7.4981 13.5007C6.5312 13.5007 5.74738 12.7169 5.74738 11.75C5.74738 10.7831 6.5312 9.99927 7.4981 9.99927C8.46501 9.99927 9.24883 10.7831 9.24883 11.75"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99664 17.0021C4.84492 16.3582 5.88218 16.0125 6.94718 16.0189"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0012 21.0038C13.2227 19.6699 10.7772 19.6699 8.99872 21.0038"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
