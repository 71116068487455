import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28758 7.66939L9.24268 2L9.99562 2.65808L5.32811 7.99844L9.99562 13.3388L9.24268 13.9969L4.28758 8.32748L4 7.99844L4.28758 7.66939Z"
      />
    </svg>
  );
}
