import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const LabelsWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings[1]};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Hint = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacings[1]};

  svg {
    display: block;
    margin-right: ${({ theme }) => theme.spacings[1]};
  }

  p {
    color: ${({ theme }) => theme.color.tertiary};
    margin-bottom: 0;

    .has-error & {
      color: ${({ theme }) => theme.color.negative};
      margin-left: ${({ theme }) => theme.spacings[0.5]};
    }

    .has-warning & {
      color: ${({ theme }) => theme.color.warning};
      margin-left: ${({ theme }) => theme.spacings[0.5]};
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;
