import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.863 7.41518C20.5414 5.40865 18.5918 3.45908 16.5853 4.13747L5.69246 7.82028C3.62796 8.51827 3.43203 11.2592 5.21532 12.3883L8.9189 14.7333C9.05917 14.8221 9.17838 14.9413 9.26719 15.0816L11.6121 18.7851C12.7413 20.5684 15.4822 20.3725 16.1802 18.308L19.863 7.41518ZM18.4972 6.56294C18.5015 6.68428 18.4844 6.80948 18.442 6.93475L14.7592 17.8276C14.497 18.603 13.3872 18.7847 12.8795 17.9827L10.6301 14.4301L18.4972 6.56294ZM17.4356 5.50322L9.56918 13.3696L6.01774 11.121C5.21579 10.6132 5.39748 9.50342 6.17289 9.24126L17.0657 5.55845C17.1903 5.51633 17.3148 5.49916 17.4356 5.50322Z"
        fill={fill}
      />
    </svg>
  );
}
