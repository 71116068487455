import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3184 4.75C14.8915 4.75 16.4003 5.37494 17.5127 6.48735C18.6251 7.59976 19.2501 9.10851 19.2501 10.6817M13.3184 7.38633C14.1923 7.38633 15.0305 7.73352 15.6485 8.35153C16.2666 8.96953 16.6137 9.80773 16.6137 10.6817M9.8977 10.4794C9.98116 10.394 10.0375 10.2859 10.0597 10.1687C10.0818 10.0514 10.0688 9.93017 10.0223 9.82028L8.57296 6.43724C8.51568 6.30397 8.41242 6.19572 8.28201 6.13221C8.1516 6.0687 8.00271 6.05415 7.86247 6.09122L5.20375 6.79314C5.07064 6.82763 4.95323 6.90639 4.87082 7.01647C4.78841 7.12655 4.74591 7.26139 4.75031 7.39883C4.91964 10.4908 6.20533 13.4163 8.36864 15.632C10.5851 17.7957 13.5116 19.0812 16.6045 19.2497C16.7417 19.254 16.8764 19.2115 16.9863 19.1291C17.0961 19.0466 17.1746 18.9293 17.2088 18.7963L17.9114 16.1362C17.9487 15.9961 17.9343 15.8472 17.8709 15.7167C17.8075 15.5863 17.6993 15.483 17.5661 15.4257L14.1857 13.9771C14.075 13.9295 13.9526 13.916 13.8342 13.9384C13.7159 13.9609 13.6069 14.0181 13.5213 14.103L12.3442 15.3438L11.9299 15.0397C10.6994 14.1369 9.69192 12.9644 8.98457 11.6121L8.84424 11.3438L9.8977 10.4794Z"
        stroke={stroke}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
