import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90372 8.6178C8.90372 6.89195 10.2939 5.5 12.0001 5.5C13.7062 5.5 15.0964 6.89195 15.0964 8.6178C15.0964 10.3414 13.7098 11.732 12.0068 11.7356H11.9934C10.2903 11.732 8.90372 10.3414 8.90372 8.6178ZM9.00811 12.1235C8.02541 11.2759 7.40372 10.0187 7.40372 8.6178C7.40372 6.0714 9.45764 4 12.0001 4C14.5425 4 16.5964 6.0714 16.5964 8.6178C16.5964 10.0186 15.9748 11.2757 14.9923 12.1233C17.3336 12.9975 19.0008 15.2543 19.0008 17.9005C19.0008 19.0624 18.0589 20.0042 16.897 20.0042H7.10391C5.94206 20.0042 5.00018 19.0624 5.00018 17.9005C5.00018 15.2546 6.66711 12.9979 9.00811 12.1235ZM11.9916 13.2356H11.1651C8.58874 13.2356 6.50018 15.3242 6.50018 17.9005C6.50018 18.2339 6.77048 18.5042 7.10391 18.5042H16.897C17.2305 18.5042 17.5008 18.2339 17.5008 17.9005C17.5008 15.3242 15.4122 13.2356 12.8358 13.2356H12.0085C12.0057 13.2356 12.0029 13.2356 12.0001 13.2356C11.9972 13.2356 11.9944 13.2356 11.9916 13.2356Z"
        fill={stroke}
      />
    </svg>
  );
}
