import baseColors from './base';

export type ForegroundColors = keyof typeof _foreground;

export const _foreground = {
  primary: baseColors.grey[1],
  secondary: baseColors.grey[6],
  tertiary: baseColors.grey[7],
  disabled: baseColors.opacityBlack[6],
  linkNormal: baseColors.green[5],
  linkPress: baseColors.green[4],
  positive: baseColors.green[6],
  warning: baseColors.yellow[6],
  negative: baseColors.red[6],
  info: baseColors.blue[6],
  highlight: baseColors.purple[7],

  invertedPrimary: baseColors.grey[15],
  invertedSecondary: baseColors.grey[12],
  invertedTertiary: baseColors.grey[9],
  invertedDisabled: baseColors.opacityWhite[6],
  invertedLinkNormal: baseColors.green[9],
  invertedLinkPress: baseColors.green[7],
  invertedPositive: baseColors.green[11],
  invertedWarning: baseColors.yellow[11],
  invertedNegative: baseColors.red[11],
  invertedInfo: baseColors.blue[11],
};
