import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 6.08063L11 9.91937C11 10.7579 10.0301 11.2241 9.3753 10.7002L6.97609 8.78087C6.47568 8.38054 6.47568 7.61946 6.97609 7.21913L9.3753 5.29976C10.0301 4.77595 11 5.24212 11 6.08063Z"
        fill={fill}
      />
    </svg>
  );
}
