import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50006 6.74999C5.50007 6.05964 6.05971 5.5 6.75007 5.5L12.0977 5.50001C12.5119 5.50001 12.8477 5.16422 12.8477 4.75001C12.8477 4.3358 12.5119 4.00001 12.0977 4.00001L6.75007 4C5.23129 4 4.00007 5.23121 4.00006 6.74998L4 17.2542C3.99999 18.773 5.23121 20.0043 6.75 20.0043L12.0977 20.0043C12.5119 20.0043 12.8477 19.6685 12.8477 19.2543C12.8477 18.84 12.5119 18.5043 12.0977 18.5043L6.75 18.5043C6.05964 18.5043 5.5 17.9446 5.5 17.2542L5.50006 6.74999ZM14.4784 6.95216C14.7794 6.66762 15.2541 6.68097 15.5387 6.98199L19.7982 11.4881C20.0715 11.7772 20.0715 12.2294 19.7982 12.5185L15.5387 17.0246C15.2541 17.3256 14.7794 17.339 14.4784 17.0544C14.1774 16.7699 14.1641 16.2952 14.4486 15.9942L17.5116 12.7539H8.17723C7.76302 12.7539 7.42723 12.4181 7.42723 12.0039C7.42723 11.5897 7.76302 11.2539 8.17723 11.2539L17.5127 11.2539L14.4486 8.0124C14.1641 7.71139 14.1774 7.2367 14.4784 6.95216Z"
        fill={fill}
      />
    </svg>
  );
}
