import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 7.5V8H10.5V7.5C10.5 6.39543 11.3954 5.5 12.5 5.5C13.6046 5.5 14.5 6.39543 14.5 7.5ZM9 8V7.5C9 5.567 10.567 4 12.5 4C14.433 4 16 5.567 16 7.5V8H16.1597C17.1999 8 18.0664 8.79732 18.1528 9.83391L18.8195 17.8339C18.9167 18.9999 17.9965 20 16.8264 20H8.1736C7.00352 20 6.08334 18.9999 6.18051 17.8339L6.84718 9.83391C6.93356 8.79733 7.80009 8 8.84027 8H9ZM16 9.5H14.5H12.5V11.5479C12.574 11.5907 12.6438 11.6438 12.7071 11.7071L13.7071 12.7071C13.8946 12.8946 14 13.149 14 13.4142V16C14 16.5523 13.5523 17 13 17H11C10.4477 17 10 16.5523 10 16V13.4142C10 13.149 10.1054 12.8946 10.2929 12.7071L11.2929 11.7071C11.3562 11.6438 11.426 11.5907 11.5 11.5479V9.5H10.5H9H8.84027C8.58022 9.5 8.36359 9.69933 8.34199 9.95848L7.67533 17.9585C7.65103 18.25 7.88108 18.5 8.1736 18.5H16.8264C17.1189 18.5 17.349 18.25 17.3247 17.9585L16.658 9.95848C16.6364 9.69933 16.4198 9.5 16.1597 9.5H16ZM12 13.1213L12.5 13.6213V15.5H11.5V13.6213L12 13.1213Z"
        fill={fill}
      />
    </svg>
  );
}
