import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45 18.4531V38.1563C45 40.3654 43.2091 42.1562 41 42.1562H8C5.79086 42.1562 4 40.3654 4 38.1562V9C4 6.79086 5.79086 5 8 5H14.981C16.4961 5 17.8811 5.85601 18.5587 7.21115L20.0156 10.125M45 18.4531H27.3758C25.9868 18.4531 24.6972 17.7325 23.9692 16.5495L20.0156 10.125M45 18.4531V14.125C45 11.9159 43.2091 10.125 41 10.125H20.0156"
        stroke={stroke}
        stroke-width="3"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
