import LoomiDisplayRegularWoff2 from './Loomi/LoomiDisplayRegular.woff2';

import LoomiHeadlineRegularWoff2 from './Loomi/LoomiHeadlineRegular.woff2';

import RoobertSemiBoldWoff2 from './Roobert/Roobert-SemiBold.woff2';

import RoobertBoldWoff2 from './Roobert/Roobert-Bold.woff2';

import RoobertRegularWoff2 from './Roobert/Roobert-Regular.woff2';

const GlobalStyle = () => {
  return (
    <style>
      {`
@font-face {
  font-family: 'Loomi Display';
  src: url(${LoomiDisplayRegularWoff2}) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Loomi Headline';
  src: url(${LoomiHeadlineRegularWoff2}) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Loomi Text';
  src: url(${LoomiHeadlineRegularWoff2}) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url(${RoobertSemiBoldWoff2}) format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Roobert';
  src: url(${RoobertBoldWoff2}) format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Roobert';
  src: url(${RoobertRegularWoff2}) format('woff2');
}


`}
    </style>
  );
};

export default GlobalStyle;
