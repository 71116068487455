import { useMedia } from 'hooks/useMedia';
import styled from 'styled-components';
import { Text } from '../../Text/Text';

const TransposedCellStyled = styled.td<{
  isMobile: boolean;
  labelMaxWidth?: number;
}>`
  padding: ${({ theme }) => `${theme.spacings['1.5']} ${theme.spacings['4']} ${theme.spacings['1.5']}`} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  vertical-align: top;
  word-break: break-word;
  width: ${({ isMobile, labelMaxWidth }) => (isMobile || !labelMaxWidth ? 'auto' : `${labelMaxWidth}px`)};

  &:first-child {
    word-break: inherit;
  }

  &:last-child {
    padding-right: 0;
  }
`;

type Props = {
  label: string;
  labelMaxWidth?: number;
  highlighted?: boolean;
};

export const TransposedCell = ({ label, labelMaxWidth, highlighted = false }: Props) => {
  const isMobile = useMedia('mobile');

  return (
    <TransposedCellStyled labelMaxWidth={labelMaxWidth} isMobile={isMobile}>
      <Text weight={highlighted ? 'semiBold' : 'normal'} size="s">
        {label}
      </Text>
    </TransposedCellStyled>
  );
};
