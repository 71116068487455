import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00006 6.75C4.00006 6.33579 4.33585 6 4.75006 6H19.2543C19.6685 6 20.0043 6.33579 20.0043 6.75C20.0043 7.16421 19.6685 7.5 19.2543 7.5H4.75006C4.33585 7.5 4.00006 7.16421 4.00006 6.75ZM4.00006 11.9996C4.00006 11.5854 4.33585 11.2496 4.75006 11.2496H19.2543C19.6685 11.2496 20.0043 11.5854 20.0043 11.9996C20.0043 12.4138 19.6685 12.7496 19.2543 12.7496H4.75006C4.33585 12.7496 4.00006 12.4138 4.00006 11.9996ZM4.75006 16.4992C4.33585 16.4992 4.00006 16.835 4.00006 17.2492C4.00006 17.6634 4.33585 17.9992 4.75006 17.9992H19.2543C19.6685 17.9992 20.0043 17.6634 20.0043 17.2492C20.0043 16.835 19.6685 16.4992 19.2543 16.4992H4.75006Z"
        fill={fill}
      />
    </svg>
  );
}
