import styled from 'styled-components';
import Select from 'react-select';
import { fontSize, fontFaceNames, lineHeight } from '../../../typography';
import { baseToPx } from '../../../spacings';
import { INPUT_PLACEHOLDER_COLOR } from '../../Inputs/Input/InputBase/InputBase.styles';
import { mediaQueries } from '../../../media';

export const StyledSelect = styled(Select)<{ error?: string; width?: string }>`
  width: 100%;

  // Reset margins
  .react-select__input-container,
  .react-select__placeholder,
  .react-select__value-container,
  .react-select__indicator,
  .react-select__menu,
  .react-select__multi-value,
  .react-select__menu-list {
    margin: 0;
    padding: 0;
    font-family: ${fontFaceNames.Roobert};
    font-size: ${fontSize.label.s};
    @media ${mediaQueries.mobile} {
      font-size: ${fontSize.label.m};
      line-height: ${lineHeight.label.xl};
    }
  }

  // Hide unwanted elements
  .react-select__indicator-separator {
    display: none;
  }

  // Set default text color
  .react-select__input-container,
  .react-select__option--is-focused,
  .react-select__option--is-selected,
  .react-select__multi-value__remove:hover {
    color: ${({ theme }) => theme.color.base.grey.black};
  }

  // Input styles
  .react-select__control,
  .react-select__control:hover {
    width: ${({ width }) => width || 'auto'};
    padding: ${baseToPx(0.875)} ${baseToPx(1.25)} ${baseToPx(0.875)} ${baseToPx(1.5)};
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.color.border.negativeNormalBorder : theme.color.border.defaultNormalBorder};
    border-radius: ${baseToPx(0.5)};
    min-height: ${({ theme }) => theme.spacings['5']};
    color: ${({ theme }) => theme.color.base.grey[1]};
  }

  .react-select__control--is-focused,
  .react-select__control--is-focused:hover {
    border: 1px solid ${({ theme }) => theme.color.border.focusBorder};
    outline: 1px solid ${({ theme }) => theme.color.border.focusBorder} !important;
    box-shadow: none;
  }

  .react-select__placeholder {
    position: absolute;
    color: ${INPUT_PLACEHOLDER_COLOR};
    white-space: nowrap;
    overflow: hidden;
    width: 95%;
    text-overflow: ellipsis;
  }

  .react-select__control--is-focused svg {
    fill: ${({ theme }) => theme.color.base.grey[9]};
  }

  // Menu styles
  .react-select__menu {
    padding: ${baseToPx(1)};
    border: none;
    margin-top: ${baseToPx(0.25)};
    font-family: ${fontFaceNames.Roobert};
    box-shadow: ${({ theme }) =>
      `0 ${theme.spacings['0.5']} ${theme.spacings['1']} ${theme.color.base.opacityBlack['4']}`};
  }

  .react-select__menu-list {
    display: flex;
    flex-direction: column;
    gap: ${baseToPx(0.5)};
  }

  .react-select__option {
    border-radius: ${baseToPx(0.75)};
    padding: ${baseToPx(1)} ${baseToPx(1.5)};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.color.background.tertiaryBg};
    }
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: ${({ theme }) => theme.color.background.altBg};
  }

  // Select Multi styles
  .react-select__value-container--is-multi {
    gap: ${baseToPx(0.5)};
    flex-wrap: nowrap;
  }

  .react-select__multi-value {
    border-radius: ${baseToPx(0.5)};
    background-color: ${({ theme }) => theme.color.background.tertiaryBg};
    margin: 0;
  }

  .react-select__multi-value__label {
    padding: ${({ theme }) => `${theme.spacings[0.5]} ${theme.spacings[1]}`};
    font-size: ${fontSize.label.xs};
    font-family: ${fontFaceNames.Roobert};
  }

  .react-select__multi-value__remove {
    flex-shrink: 0;

    &:hover {
      background-color: ${({ theme }) => theme.color.background.altBg};
    }
  }

  // Disabled styles
  .react-select__control--is-disabled {
    border: 1px solid ${({ theme }) => theme.color.background.secondaryBg};
    color: ${INPUT_PLACEHOLDER_COLOR};
    background-color: ${({ theme }) => theme.color.background.secondaryBg};
    opacity: 0.5;
    outline: none;

    svg {
      fill: ${INPUT_PLACEHOLDER_COLOR};
    }
  }
`;
