import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  title?: string;
};

// defined as a component to map `fill` to `stroke`
const ReactComponent = ({ fill, title }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    {title && <title>{title}</title>}
    <rect x="0" y="4" width="24" height="16" rx="3.5" stroke={fill} stroke-dasharray="4 4" />
  </svg>
);

export default ReactComponent;
