import { useState, useEffect } from 'react';
import { devices, mediaQueries } from '../media';

export function useMedia(device: devices) {
  const isWindowObjectDefined = typeof window !== 'undefined';
  const query = mediaQueries[device];
  const [matches, setMatches] = useState(isWindowObjectDefined && window.matchMedia(query).matches);

  useEffect(() => {
    const media = isWindowObjectDefined ? window.matchMedia(query) : undefined;
    if (media) {
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener('change', listener);

      return () => media.addEventListener('change', listener);
    }
  }, [query, matches, isWindowObjectDefined]);

  return matches;
}
