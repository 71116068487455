import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke, fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2 18.2485H4.8C3.8055 18.2485 3 17.4095 3 16.3736V7.62344C3 6.58756 3.8055 5.74854 4.8 5.74854H19.2C20.1945 5.74854 21 6.58756 21 7.62344V16.3727C21 17.4086 20.1945 18.2485 19.2 18.2485V18.2485Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0004 18.2513V17.3279C17.0004 15.7988 18.3432 14.5586 20.0001 14.5586H21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99959 5.74854V6.67193C6.99959 8.20103 5.6568 9.44123 3.9999 9.44123H3"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14.5586H3.9999C5.6568 14.5586 6.99959 15.7988 6.99959 17.3279V18.2513"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 9.44123H20.0001C18.3432 9.44123 17.0004 8.20103 17.0004 6.67193V5.74854"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.591 10.409C14.4697 11.2877 14.4697 12.7123 13.591 13.591C12.7123 14.4697 11.2877 14.4697 10.409 13.591C9.53033 12.7123 9.53033 11.2877 10.409 10.409C11.2877 9.53033 12.7123 9.53033 13.591 10.409Z"
        fill={fill}
      />
    </svg>
  );
}
