import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 21H6.5C5.395 21 4.5 20.105 4.5 19V5C4.5 3.895 5.395 3 6.5 3H17.5C18.605 3 19.5 3.895 19.5 5V19C19.5 20.105 18.605 21 17.5 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 7.5H16" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 10.5H16" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 15H16" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 18H16" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 6.75V6" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 11.25V12" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.198 10.714C8.392 11.032 8.724 11.252 9.125 11.252H9.5H9.947C10.529 11.252 11 10.78 11 10.199C11 9.716 10.671 9.295 10.203 9.177L8.797 8.825C8.329 8.707 8 8.286 8 7.803C8 7.222 8.472 6.75 9.053 6.75H9.5H9.875C10.275 6.75 10.606 6.969 10.8 7.286"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
