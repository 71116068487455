import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66991 11.7077L2 6.753L2.65802 6L7.99892 10.6672L13.3398 6L13.9978 6.753L8.32793 11.7077L7.99892 11.9952L7.66991 11.7077Z"
      />
    </svg>
  );
}
