import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 15C9.75 15.4142 9.41421 15.75 9 15.75C8.58579 15.75 8.25 15.4142 8.25 15V14.75C8.25 11.0221 11.2721 8 15 8H17.4393L14.7197 5.28033C14.4268 4.98744 14.4268 4.51256 14.7197 4.21967C15.0126 3.92678 15.4874 3.92678 15.7803 4.21967L19.7803 8.21967C20.0732 8.51256 20.0732 8.98744 19.7803 9.28033L15.7803 13.2803C15.4874 13.5732 15.0126 13.5732 14.7197 13.2803C14.4268 12.9874 14.4268 12.5126 14.7197 12.2197L17.4393 9.5H15C12.1005 9.5 9.75 11.8505 9.75 14.75V15ZM5.5 6.75021C5.5 6.05986 6.05964 5.50021 6.75 5.50021H10.0242C10.4384 5.50021 10.7742 5.16443 10.7742 4.75021C10.7742 4.336 10.4384 4.00021 10.0242 4.00021H6.75C5.23122 4.00021 4 5.23143 4 6.75021V17.2543C4 18.7731 5.23122 20.0043 6.75 20.0043H17.2541C18.7728 20.0043 20.0041 18.7731 20.0041 17.2543V15C20.0041 14.5858 19.6683 14.25 19.2541 14.25C18.8398 14.25 18.5041 14.5858 18.5041 15V17.2543C18.5041 17.9446 17.9444 18.5043 17.2541 18.5043H6.75C6.05964 18.5043 5.5 17.9446 5.5 17.2543V6.75021Z"
        fill={fill}
      />
    </svg>
  );
}
