import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45094 5.09981C6.9703 4.40746 7.7853 4 8.6508 4H15.3536C16.2191 4 17.0341 4.40746 17.5534 5.09981L19.4542 7.63368C19.8113 8.10973 20.0043 8.68877 20.0043 9.28387V17.2517C20.0043 18.7704 18.7731 20.0017 17.2543 20.0017H6.75006C5.23128 20.0017 4.00006 18.7704 4.00006 17.2517V9.28387C4.00006 8.68877 4.1931 8.10973 4.5502 7.63368L6.45094 5.09981ZM8.6508 5.5C8.25739 5.5 7.88694 5.68521 7.65087 5.99991L6.24983 7.86762H17.7545L16.3535 5.99991C16.1174 5.68521 15.747 5.5 15.3536 5.5H8.6508ZM5.50006 17.2517V9.36762H18.5043V17.2517C18.5043 17.942 17.9447 18.5017 17.2543 18.5017H6.75006C6.05971 18.5017 5.50006 17.942 5.50006 17.2517ZM12.7539 11.5179C12.7539 11.1037 12.4182 10.7679 12.0039 10.7679C11.5897 10.7679 11.2539 11.1037 11.2539 11.5179V14.7507L9.58318 13.3586C9.26495 13.0935 8.79203 13.1365 8.52688 13.4547C8.26173 13.773 8.30477 14.2459 8.623 14.511L11.5238 16.928C11.802 17.1597 12.2059 17.1597 12.484 16.928L15.3849 14.511C15.7031 14.2459 15.7461 13.773 15.481 13.4547C15.2158 13.1365 14.7429 13.0935 14.4247 13.3586L12.7539 14.7507V11.5179Z"
        fill={stroke}
      />
    </svg>
  );
}
