import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6289 17.1638C1.95097 13.3417 2.63898 8.10663 6.21357 5.10625C9.78816 2.10587 15.0632 2.33571 18.3633 5.63565C21.6633 8.93558 21.8934 14.2106 18.8932 17.7853C15.8929 21.36 10.6579 22.0483 6.83569 19.3705L3.99842 20.0009L4.6289 17.1638Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1714 8.18602C11.327 7.8706 11.6482 7.6709 12 7.6709C12.3517 7.6709 12.6729 7.8706 12.8286 8.18602L13.3975 9.33926C13.532 9.61202 13.7922 9.80109 14.0932 9.84484L15.3656 10.0298C15.7136 10.0804 16.0027 10.3241 16.1114 10.6585C16.2201 10.9929 16.1296 11.3601 15.8779 11.6056L14.9569 12.504C14.7393 12.7162 14.64 13.022 14.6914 13.3216L14.9087 14.5887C14.9682 14.9353 14.8257 15.2856 14.5412 15.4923C14.2567 15.6991 13.8795 15.7264 13.5682 15.5628L12.4299 14.9645C12.1608 14.823 11.8393 14.823 11.5702 14.9645L10.4318 15.5628C10.1205 15.7264 9.74331 15.6991 9.45881 15.4923C9.17431 15.2856 9.03185 14.9353 9.09131 14.5887L9.30867 13.3216C9.36007 13.022 9.26079 12.7162 9.04316 12.504L8.12216 11.6056C7.87044 11.3601 7.7799 10.9929 7.88861 10.6585C7.99732 10.3241 8.28642 10.0804 8.6344 10.0298L9.90685 9.84484C10.2078 9.80109 10.468 9.61202 10.6026 9.33926L11.1714 8.18602Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
