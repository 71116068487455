import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 7C10.1193 7 9 8.11929 9 9.5V46.5C9 47.8807 10.1193 49 11.5 49H26.975C27.8034 49 28.475 49.6716 28.475 50.5C28.475 51.3284 27.8034 52 26.975 52H11.5C8.46244 52 6 49.5376 6 46.5V9.5C6 6.46243 8.46243 4 11.5 4H40.4C43.4376 4 45.9 6.46244 45.9 9.5V26.9773C45.9 27.8057 45.2284 28.4773 44.4 28.4773C43.5716 28.4773 42.9 27.8057 42.9 26.9773V9.5C42.9 8.11929 41.7807 7 40.4 7H11.5Z"
        fill={fill}
      />
      <path
        d="M15.7 14.2273C14.8715 14.2273 14.2 14.8988 14.2 15.7273C14.2 16.5557 14.8715 17.2273 15.7 17.2273H17.75C18.5784 17.2273 19.25 16.5557 19.25 15.7273C19.25 14.8988 18.5784 14.2273 17.75 14.2273H15.7Z"
        fill={fill}
      />
      <path
        d="M23.8999 14.2273C23.0715 14.2273 22.3999 14.8988 22.3999 15.7273C22.3999 16.5557 23.0715 17.2273 23.8999 17.2273H36.1999C37.0283 17.2273 37.6999 16.5557 37.6999 15.7273C37.6999 14.8988 37.0283 14.2273 36.1999 14.2273H23.8999Z"
        fill={fill}
      />
      <path
        d="M14.2 23.9091C14.2 23.0807 14.8715 22.4091 15.7 22.4091H17.75C18.5784 22.4091 19.25 23.0807 19.25 23.9091C19.25 24.7375 18.5784 25.4091 17.75 25.4091H15.7C14.8715 25.4091 14.2 24.7375 14.2 23.9091Z"
        fill={fill}
      />
      <path
        d="M23.8999 22.4091C23.0715 22.4091 22.3999 23.0807 22.3999 23.9091C22.3999 24.7375 23.0715 25.4091 23.8999 25.4091H36.1999C37.0283 25.4091 37.6999 24.7375 37.6999 23.9091C37.6999 23.0807 37.0283 22.4091 36.1999 22.4091H23.8999Z"
        fill={fill}
      />
      <path
        d="M14.2 32.0909C14.2 31.2625 14.8715 30.5909 15.7 30.5909H25.95C26.7784 30.5909 27.45 31.2625 27.45 32.0909C27.45 32.9194 26.7784 33.5909 25.95 33.5909H15.7C14.8715 33.5909 14.2 32.9194 14.2 32.0909Z"
        fill={fill}
      />
      <path
        d="M15.7 38.7727C14.8715 38.7727 14.2 39.4443 14.2 40.2727C14.2 41.1011 14.8715 41.7727 15.7 41.7727H22.875C23.7034 41.7727 24.375 41.1011 24.375 40.2727C24.375 39.4443 23.7034 38.7727 22.875 38.7727H15.7Z"
        fill={fill}
      />
      <path
        d="M39.2749 36.7272C40.1033 36.7272 40.7749 37.3988 40.7749 38.2272V39.7955H42.35C43.1784 39.7955 43.85 40.467 43.85 41.2955C43.85 42.1239 43.1784 42.7955 42.35 42.7955H40.7749V44.3636C40.7749 45.192 40.1033 45.8636 39.2749 45.8636C38.4465 45.8636 37.7749 45.192 37.7749 44.3636V42.7955H36.2C35.3715 42.7955 34.7 42.1239 34.7 41.2955C34.7 40.467 35.3715 39.7955 36.2 39.7955H37.7749V38.2272C37.7749 37.3988 38.4465 36.7272 39.2749 36.7272Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.275 30.5909C33.3549 30.5909 28.55 35.3804 28.55 41.2955C28.55 47.2106 33.3549 52 39.275 52C45.1952 52 50 47.2106 50 41.2955C50 35.3804 45.1952 30.5909 39.275 30.5909ZM31.55 41.2955C31.55 37.0435 35.0055 33.5909 39.275 33.5909C43.5446 33.5909 47 37.0435 47 41.2955C47 45.5475 43.5446 49 39.275 49C35.0055 49 31.55 45.5475 31.55 41.2955Z"
        fill={fill}
      />
    </svg>
  );
}
