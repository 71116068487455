import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.618 4C9.0992 4 7.86798 5.23122 7.86798 6.75V7.86781H6.75006C5.23128 7.86781 4.00006 9.09903 4.00006 10.6178V17.2543C4.00006 18.773 5.23128 20.0043 6.75006 20.0043H13.3865C14.9053 20.0043 16.1365 18.773 16.1365 17.2543V16.1364H17.2544C18.7732 16.1364 20.0044 14.9052 20.0044 13.3864V6.75C20.0044 5.23122 18.7732 4 17.2544 4H10.618ZM14.6365 16.1364H10.618C9.0992 16.1364 7.86798 14.9052 7.86798 13.3864V9.36781H6.75006C6.05971 9.36781 5.50006 9.92746 5.50006 10.6178V17.2543C5.50006 17.9446 6.05971 18.5043 6.75006 18.5043H13.3865C14.0769 18.5043 14.6365 17.9446 14.6365 17.2543V16.1364ZM9.36798 6.75C9.36798 6.05964 9.92762 5.5 10.618 5.5H17.2544C17.9448 5.5 18.5044 6.05964 18.5044 6.75V13.3864C18.5044 14.0768 17.9448 14.6364 17.2544 14.6364H10.618C9.92762 14.6364 9.36798 14.0768 9.36798 13.3864V6.75Z"
        fill={fill}
      />
    </svg>
  );
}
