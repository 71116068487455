import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22022 2.27122C3.95579 1.9524 3.48297 1.90831 3.16415 2.17274C2.84532 2.43717 2.80123 2.91 3.06567 3.22882L6.47735 7.34222C6.8378 7.77681 6.8378 8.43734 6.47735 8.87193L3.06567 12.9853C2.80123 13.3042 2.84532 13.777 3.16415 14.0414C3.48297 14.3058 3.95579 14.2617 4.22022 13.9429L7.63191 9.82952C8.45295 8.83961 8.45295 7.37453 7.63191 6.38462L4.22022 2.27122ZM10.2202 2.27122C9.95579 1.9524 9.48297 1.90831 9.16415 2.17274C8.84532 2.43717 8.80123 2.91 9.06567 3.22882L12.4773 7.34222C12.8378 7.77681 12.8378 8.43734 12.4773 8.87193L9.06567 12.9853C8.80123 13.3042 8.84532 13.777 9.16415 14.0414C9.48297 14.3058 9.95579 14.2617 10.2202 13.9429L13.6319 9.82952C14.4529 8.83961 14.4529 7.37453 13.6319 6.38462L10.2202 2.27122Z"
        fill={fill}
      />
    </svg>
  );
}
