// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Button from '../../Button/Button';
import { baseToPx } from '../../../spacings';
import styled from 'styled-components';
import { DateRangeOption, DateRangeParam } from './types';
import { ReactDatePickerProps } from 'react-datepicker';

type DatePickerRangeAddonProps = {
  rangeOptions: DateRangeOption<DateRangeParam>[];
  onRangeChangeHandler: ReactDatePickerProps['onChange'];
};

export default function DatePickerRangeAddon({ rangeOptions, onRangeChangeHandler }: DatePickerRangeAddonProps) {
  return (
    <List>
      {rangeOptions.map(({ value, label }) => {
        return (
          <ListElement key={`listElement-${label}`}>
            <Button
              type="button"
              variant="secondary"
              icon="checkmark"
              label={label}
              onClick={e => {
                onRangeChangeHandler([new Date(value.from), new Date(value.to)], undefined);
              }}
            />
          </ListElement>
        );
      })}
    </List>
  );
}

const listElementWidth = '144px';

const List = styled.ul`
  padding: ${({ theme }) => theme.spacings['1.5']};
  margin: 0;
  list-style: none;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListElement = styled.li`
  padding: 0;

  button {
    padding: ${({ theme }) => theme.spacings['1']} ${({ theme }) => theme.spacings['1.5']};
    width: ${listElementWidth};
    height: auto;
    background: none;
    border: none;
    border-radius: ${baseToPx(0.5)};
    text-align: left;
    justify-content: space-between;

    :before {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: hsla(0, 0%, 91%, 1);
      cursor: pointer;
    }

    &:active,
    &:focus {
      svg {
        display: inline-block;
      }
    }

    span {
      color: hsla(0, 0%, 6%, 1);
      line-height: ${baseToPx(2.5)};
      font-size: ${baseToPx(1.75)};
      font-weight: normal;
    }

    svg {
      display: none;
    }
  }
`;
