import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12.4001H10" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 12.4001H17" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 8.90009H10" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 8.90009H17" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5 16.9001V4.57632C4.99999 4.21964 5.18995 3.88997 5.49854 3.71111L6.49145 3.13563C6.80037 2.95656 7.18134 2.95579 7.49098 3.13363L8.99582 3.99798L9.00062 3.99998C9.0273 3.98428 9.9133 3.47068 10.494 3.13409C10.8023 2.95548 11.1825 2.95529 11.491 3.1336L12.9958 3.99796H13.0042L14.509 3.1337C14.8186 2.95586 15.1996 2.95663 15.5085 3.1357L16.9958 3.9977H17.0042L18.509 3.13334C18.8186 2.9555 19.1996 2.95627 19.5085 3.13534L20.5014 3.71083C20.81 3.88967 21 4.21931 21 4.57596V19.4001C21 20.2285 20.3284 20.9001 19.5 20.9001V20.9001C18.6716 20.9001 18 20.2285 18 19.4001V17.9001C18 17.3478 17.5523 16.9001 17 16.9001H4C3.44772 16.9001 3 17.3478 3 17.9001V18.9001C3 20.0047 3.89543 20.9001 5 20.9001H19.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
