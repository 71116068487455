import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM15.8074 10.2498C16.0835 9.94103 16.0569 9.4669 15.7481 9.19085C15.4393 8.91479 14.9652 8.94135 14.6891 9.25017L10.9224 13.4639C10.9157 13.4714 10.9093 13.4789 10.9029 13.4866C10.9015 13.4876 10.8983 13.4895 10.8928 13.4919C10.8787 13.4979 10.856 13.5037 10.8279 13.5037C10.7998 13.5038 10.777 13.4981 10.7628 13.492C10.7571 13.4896 10.7538 13.4876 10.7523 13.4866L9.3288 11.7592C9.06537 11.4396 8.59269 11.394 8.27304 11.6574C7.95339 11.9209 7.90781 12.3935 8.17124 12.7132L9.59653 14.4427C9.92302 14.8388 10.4011 15.0044 10.8301 15.0037C11.2537 15.0031 11.7249 14.8403 12.0498 14.4535L15.8074 10.2498Z"
        fill={fill}
      />
    </svg>
  );
}
