import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.99658"
        y="2.99658"
        width="18.0075"
        height="18.0075"
        rx="4"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.07308 9.10585L7.58646 10.2913C7.25124 10.5591 7.04567 10.9567 7.02105 11.385C6.99643 11.8133 7.15508 12.2319 7.45741 12.5363L8.43682 13.5207C8.73922 13.8249 9.15686 13.9858 9.58518 13.9633C10.0135 13.9408 10.4119 13.7369 10.6808 13.4026L11.8733 11.921C12.1831 11.5359 12.5344 11.186 12.9207 10.8776L14.4073 9.6921C14.7428 9.42455 14.9486 9.02686 14.9732 8.59845C14.9978 8.17004 14.839 7.75139 14.5364 7.44716L13.557 6.46275C13.2546 6.15858 12.8369 5.99762 12.4086 6.02015C11.9803 6.04268 11.5818 6.24658 11.313 6.5808L10.1215 8.06242C9.81153 8.44778 9.45992 8.79771 9.07308 9.10585V9.10585Z"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6496 17.0022H6.99805"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3356 10.5464L17.4163 14.0008C17.902 14.4135 18.1183 15.0618 17.9775 15.6835C17.8367 16.3051 17.3622 16.797 16.746 16.9601C16.1299 17.1232 15.4741 16.9304 15.0443 16.4598L11.4268 12.4742"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
