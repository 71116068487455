import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75295 2L11.708 7.66939L11.9956 7.99843L11.708 8.32748L6.75295 13.9969L6 13.3388L10.6675 7.99844L6 2.65808L6.75295 2Z"
      />
    </svg>
  );
}
