import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34286 4H6.51429C5.12568 4 4 5.12568 4 6.51429V8.34286H5.37143V6.51429C5.37143 5.8831 5.8831 5.37143 6.51429 5.37143H8.34286V4ZM4 17.4857V15.6571H5.37143V17.4857C5.37143 18.1169 5.8831 18.6286 6.51429 18.6286H8.34286V20H6.51429C5.12568 20 4 18.8743 4 17.4857ZM18.6286 17.4857V15.6571H20V17.4857C20 18.8743 18.8743 20 17.4857 20H15.6571V18.6286H17.4857C18.1169 18.6286 18.6286 18.1169 18.6286 17.4857ZM20 6.51429V8.34286H18.6286V6.51429C18.6286 5.8831 18.1169 5.37143 17.4857 5.37143H15.6571V4H17.4857C18.8743 4 20 5.12568 20 6.51429ZM8.25 8.25V9.75H9.75V8.25H8.25ZM6.95767 7.26867C7.0932 7.04689 7.38114 6.75 7.83333 6.75H10.1667C10.6189 6.75 10.9068 7.04689 11.0423 7.26867C11.1857 7.50333 11.25 7.77983 11.25 8.04545V9.95455C11.25 10.2202 11.1857 10.4967 11.0423 10.7313C10.9068 10.9531 10.6189 11.25 10.1667 11.25H7.83333C7.38114 11.25 7.0932 10.9531 6.95767 10.7313C6.81427 10.4967 6.75 10.2202 6.75 9.95455V8.04545C6.75 7.77983 6.81427 7.50333 6.95767 7.26867ZM14.25 8.25V9.75H15.75V8.25H14.25ZM12.9577 7.26867C13.0932 7.04689 13.3811 6.75 13.8333 6.75H16.1667C16.6189 6.75 16.9068 7.04689 17.0423 7.26867C17.1857 7.50333 17.25 7.77983 17.25 8.04545V9.95455C17.25 10.2202 17.1857 10.4967 17.0423 10.7313C16.9068 10.9531 16.6189 11.25 16.1667 11.25H13.8333C13.3811 11.25 13.0932 10.9531 12.9577 10.7313C12.8143 10.4967 12.75 10.2202 12.75 9.95455V8.04545C12.75 7.77983 12.8143 7.50333 12.9577 7.26867ZM8.25 15.75V14.25H9.75V15.75H8.25ZM7.83333 12.75C7.38114 12.75 7.0932 13.0469 6.95767 13.2687C6.81427 13.5033 6.75 13.7798 6.75 14.0455V15.9545C6.75 16.2202 6.81427 16.4967 6.95767 16.7313C7.0932 16.9531 7.38114 17.25 7.83333 17.25H10.1667C10.6189 17.25 10.9068 16.9531 11.0423 16.7313C11.1857 16.4967 11.25 16.2202 11.25 15.9545V14.0455C11.25 13.7798 11.1857 13.5033 11.0423 13.2687C10.9068 13.0469 10.6189 12.75 10.1667 12.75H7.83333ZM12.5 12.5H14V14H12.5V12.5ZM14 15.5H12.5V17H14V15.5ZM14 15.5V14H15.5H17V15.5V17H15.5V15.5H14Z"
        fill={fill}
      />
    </svg>
  );
}
