import { IconSVGProps } from '../types';

export default function SVGComponent({ fill, stroke }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9066 4.25C31.9066 2.45508 30.4515 1 28.6566 1L19.2561 0.999999C17.4611 0.999999 16.0061 2.45508 16.0061 4.25L16.0061 14.7543C16.0061 16.5492 17.4611 18.0043 19.2561 18.0043L28.6566 18.0043C30.4515 18.0043 31.9066 16.5492 31.9066 14.7543L31.9066 4.25ZM28.6566 3.5C29.0708 3.5 29.4066 3.83579 29.4066 4.25L29.4066 14.7543C29.4066 15.1685 29.0708 15.5043 28.6566 15.5043L27.0031 15.5043L27.0031 3.5L28.6566 3.5ZM24.0031 3.5L19.2561 3.5C18.8418 3.5 18.5061 3.83579 18.5061 4.25L18.5061 14.7543C18.5061 15.1685 18.8418 15.5043 19.2561 15.5043L24.0031 15.5043L24.0031 3.5Z"
        fill={fill}
      />
      <rect
        x="35.75"
        y="11.25"
        width="34.5"
        height="23.5"
        rx="2.75"
        transform="rotate(90 35.75 11.25)"
        fill="white"
        stroke={stroke}
        stroke-width="2.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 15C16.8954 15 16 15.8954 16 17V21C16 22.1046 16.8954 23 18 23H30C31.1046 23 32 22.1046 32 21V17C32 15.8954 31.1046 15 30 15H18ZM24 26C22.8954 26 22 26.8954 22 28C22 29.1046 22.8954 30 24 30C25.1046 30 26 29.1046 26 28C26 26.8954 25.1046 26 24 26ZM28 34C28 32.8954 28.8954 32 30 32C31.1046 32 32 32.8954 32 34V40C32 41.1046 31.1046 42 30 42C28.8954 42 28 41.1046 28 40V34ZM22 40C22 38.8954 22.8954 38 24 38C25.1046 38 26 38.8954 26 40C26 41.1046 25.1046 42 24 42C22.8954 42 22 41.1046 22 40ZM18 38C16.8954 38 16 38.8954 16 40C16 41.1046 16.8954 42 18 42C19.1046 42 20 41.1046 20 40C20 38.8954 19.1046 38 18 38ZM22 34C22 32.8954 22.8954 32 24 32C25.1046 32 26 32.8954 26 34C26 35.1046 25.1046 36 24 36C22.8954 36 22 35.1046 22 34ZM28 28C28 26.8954 28.8954 26 30 26C31.1046 26 32 26.8954 32 28C32 29.1046 31.1046 30 30 30C28.8954 30 28 29.1046 28 28ZM18 32C16.8954 32 16 32.8954 16 34C16 35.1046 16.8954 36 18 36C19.1046 36 20 35.1046 20 34C20 32.8954 19.1046 32 18 32ZM16 28C16 26.8954 16.8954 26 18 26C19.1046 26 20 26.8954 20 28C20 29.1046 19.1046 30 18 30C16.8954 30 16 29.1046 16 28Z"
        fill={fill}
      />
    </svg>
  );
}
