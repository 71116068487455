import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5.5C15 7.13851 16.788 8.88881 17.808 9.75435C18.2108 10.0819 18.7882 10.0819 19.191 9.75435C20.212 8.88881 22 7.13851 22 5.5"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5002 5.45C18.5278 5.45011 18.5501 5.47254 18.55 5.50013C18.5499 5.52772 18.5275 5.55004 18.4999 5.55C18.4723 5.54997 18.45 5.52759 18.45 5.5C18.4499 5.48669 18.4552 5.4739 18.4646 5.4645C18.4741 5.4551 18.4869 5.44988 18.5002 5.45"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 5.5V5.5C15 3.567 16.567 2 18.5 2V2C20.433 2 22 3.567 22 5.5V5.5"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3.51416 16H20.4874" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12.0057 4C9.79505 4 8.00293 8.02944 8.00293 13C8.00293 17.9706 9.79505 22 12.0057 22"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12.0057 10H3.51416" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M21 13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13C3 8.02944 7.02944 4 12 4"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0028 13C16.0028 17.9706 14.2107 22 12 22"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
