import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4963 6.27947C10.7884 5.98575 10.7871 5.51088 10.4933 5.21882C10.1996 4.92675 9.72476 4.92809 9.43269 5.22181L3.21818 11.4715C2.92727 11.764 2.92727 12.2366 3.21818 12.5291L9.43269 18.7788C9.72475 19.0725 10.1996 19.0739 10.4933 18.7818C10.7871 18.4898 10.7884 18.0149 10.4963 17.7212L4.80768 12.0003L10.4963 6.27947ZM18.782 6.27947C19.074 5.98575 19.0727 5.51088 18.779 5.21882C18.4853 4.92675 18.0104 4.92809 17.7183 5.22181L11.5038 11.4715C11.2129 11.764 11.2129 12.2366 11.5038 12.5291L17.7183 18.7788C18.0104 19.0725 18.4853 19.0739 18.779 18.7818C19.0727 18.4898 19.074 18.0149 18.782 17.7212L13.0933 12.0003L18.782 6.27947Z"
        fill={fill}
      />
    </svg>
  );
}
