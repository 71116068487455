import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 6C5.80229 6 6.25 6.44772 6.25 7V17C6.25 17.5523 5.80229 18 5.25 18C4.69771 18 4.25 17.5523 4.25 17V7C4.25 6.44772 4.69771 6 5.25 6ZM14.7197 8.53033C14.4268 8.23744 14.4268 7.76256 14.7197 7.46967C15.0126 7.17678 15.4874 7.17678 15.7803 7.46967L19.7803 11.4697C20.0732 11.7626 20.0732 12.2374 19.7803 12.5303L15.7803 16.5303C15.4874 16.8232 15.0126 16.8232 14.7197 16.5303C14.4268 16.2374 14.4268 15.7626 14.7197 15.4697L17.4388 12.7506H9.25C8.83579 12.7506 8.5 12.4148 8.5 12.0006C8.5 11.5863 8.83579 11.2506 9.25 11.2506H17.4399L14.7197 8.53033Z"
        fill={fill}
      />
    </svg>
  );
}
