import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M53.7072 31.6242V4.50286L49.2586 -1.74384e-06L44.5815 3.55291L39.9017 -1.74384e-06L36.47 3.47428L31.8959 -1.74384e-06L28.4641 3.47428L23.9911 0.0795421L19.5218 3.47337L16.09 -0.000915527L10.2886 4.40411V31.6242H-0.0065918V64H63.9979V31.6242H53.7072ZM12.5364 5.54148L15.8832 3.00068L19.315 6.47497L23.9911 2.92023L28.6709 6.47406L32.1027 2.99977L36.6777 6.47406L40.1095 2.99977L44.5815 6.3936L49.0509 3.00068L51.4649 5.44457V43.2046H12.5418L12.5364 5.54148ZM61.7547 61.7271H2.24119V33.8971H10.2895V43.2018H5.6964V45.4747H58.3022V43.2018H53.7072V33.8971H61.7547V61.7271Z"
      />
      <path fill={fill} d="M45.7234 13.0999H18.2767V15.3728H45.7234V13.0999Z" />
      <path fill={fill} d="M45.7234 21.2042H18.2767V23.4771H45.7234V21.2042Z" />
      <path fill={fill} d="M45.7234 29.3092H18.2767V31.5821H45.7234V29.3092Z" />
    </svg>
  );
}
