import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6C4 4.89543 4.89543 4 6 4H9.33V9.33H4V6Z" stroke={stroke} strokeWidth="1.5" />
      <rect x="9.33002" y="4" width="5.33" height="5.33" stroke={stroke} strokeWidth="1.5" />
      <path d="M14.66 4H17.99C19.0945 4 19.99 4.89543 19.99 6V9.33H14.66V4Z" stroke={stroke} strokeWidth="1.5" />
      <rect x="4" y="9.32996" width="5.33" height="5.33" stroke={stroke} strokeWidth="1.5" />
      <rect x="9.33002" y="9.32996" width="5.33" height="5.33" stroke={stroke} strokeWidth="1.5" />
      <rect x="14.66" y="9.32996" width="5.33" height="5.33" stroke={stroke} strokeWidth="1.5" />
      <path d="M4 14.66H9.33V19.99H6C4.89543 19.99 4 19.0946 4 17.99V14.66Z" stroke={stroke} strokeWidth="1.5" />
      <rect x="9.33002" y="14.66" width="5.33" height="5.33" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M14.66 14.66H19.99V17.99C19.99 19.0946 19.0945 19.99 17.99 19.99H14.66V14.66Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
}
