import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 8.5H17.5C16.3954 8.5 15.5 7.60457 15.5 6.5V3"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21H18C19.6569 21 21 19.6569 21 18V8.50931C21 7.71366 20.6839 6.95061 20.1213 6.388L17.612 3.87868C17.0494 3.31607 16.2863 3 15.4907 3H8C6.34315 3 5 4.34315 5 6V10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.5" cy="17" r="1.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <rect
        x="3"
        y="13"
        width="11"
        height="8"
        rx="2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
