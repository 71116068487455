import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65091 4C7.78533 4 6.97026 4.40753 6.45091 5.1L4.55006 7.63447C4.23739 8.05136 4.05053 8.54721 4.00894 9.06366C4.00309 9.10138 4.00006 9.14004 4.00006 9.1794C4.00006 9.19156 4.00035 9.20364 4.00092 9.21565C4.00035 9.23856 4.00006 9.2615 4.00006 9.28447V17.2542C4.00006 18.773 5.23128 20.0042 6.75006 20.0042H17.2543C18.7731 20.0042 20.0043 18.773 20.0043 17.2542V9.28447C20.0043 9.2615 20.004 9.23856 20.0035 9.21565C20.004 9.20364 20.0043 9.19156 20.0043 9.1794C20.0043 9.14004 20.0013 9.10138 19.9954 9.06366C19.9538 8.54721 19.767 8.05136 19.4543 7.63447L17.5535 5.1C17.0341 4.40753 16.219 4 15.3535 4H8.65091ZM18.1755 8.4294L16.3535 6C16.1174 5.68524 15.7469 5.5 15.3535 5.5H8.65091C8.25746 5.5 7.88698 5.68524 7.65091 6L5.82886 8.4294H8.61786H15.3865H18.1755ZM5.50006 9.9294V17.2542C5.50006 17.9446 6.05971 18.5042 6.75006 18.5042H17.2543C17.9447 18.5042 18.5043 17.9446 18.5043 17.2542V9.9294H16.1365V15.9481C16.1365 16.231 15.9772 16.4899 15.7246 16.6175C15.4721 16.7451 15.1692 16.7196 14.9414 16.5517L12.0022 14.3844L9.06297 16.5517C8.83521 16.7196 8.53232 16.7451 8.27973 16.6175C8.02714 16.4899 7.86786 16.231 7.86786 15.9481V12.5637V9.9294H5.50006ZM9.36786 12.5637V9.9294H14.6365V14.4632L12.4473 12.8489C12.1826 12.6537 11.8217 12.6537 11.5571 12.8489L9.36786 14.4632V12.5637Z"
        fill={fill}
      />
    </svg>
  );
}
