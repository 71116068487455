import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00129 3.27183C7.95172 2.71387 9.17092 3.04153 9.72478 4.01697L10.5944 3.5232C9.769 2.06959 7.93351 1.56498 6.49503 2.40945C5.59582 2.93733 5.07107 3.86492 5.00598 4.83593L5.00499 4.85071L5.00488 4.86552L5 5.50035L5.99997 5.50803L6.00473 4.88882C6.05302 4.23413 6.40776 3.62026 7.00129 3.27183ZM9.50135 7.66921C8.89585 8.02467 8.53882 8.6565 8.50224 9.32582V10H7.50224V9.3129V9.30063L7.50284 9.28839C7.55149 8.29735 8.07891 7.34467 8.99509 6.80683L9.50135 7.66921ZM8.99848 6.80695C9.95391 6.24606 10.2875 4.99646 9.7309 4.01627L10.6005 3.52251C11.4232 4.97137 10.9382 6.8278 9.50474 7.66933L8.99848 6.80695ZM8.00122 13.9971C8.61068 13.9971 9.10475 13.5064 9.10475 12.9012C9.10475 12.2959 8.61068 11.8053 8.00122 11.8053C7.39176 11.8053 6.89769 12.2959 6.89769 12.9012C6.89769 13.5064 7.39176 13.9971 8.00122 13.9971Z"
      />
    </svg>
  );
}
