import {
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  getGenderFactory,
  validateFactory
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetAgeFunc,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFunc,
  GetYearFunc,
  ValidateFunc
} from './interfaces'
import { clean, validateBitCheckSum } from './utilities'

const denmark: CountryFunc = (personalNumber: string, strict: boolean = false): CountryTools => {
  const format = '^([0-9]{2})([0-9]{2})([0-9]{2})-([0-9])([0-9]{2})([0-9])$'

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  if (!matches) {
    return defaultTools()
  }

  /* tslint:disable:object-literal-sort-keys */
  const parts = {
    day: matches[1],
    month: matches[2],
    year: matches[3],
    centuryIndicator: matches[4],
    individualNumber: matches[5],
    gender: matches[6]
  }
  /* tslint:enable:object-literal-sort-keys */

  const getYear: GetYearFunc = (): number => {
    const year = parseInt(parts.year, 10)

    // https://da.wikipedia.org/wiki/CPR-nummer#Under_eller_over_100_.C3.A5r

    const inGroup1 = ['4', '9'].includes(parts.centuryIndicator)
    const inGroup2 = ['5', '6', '7', '8'].includes(parts.centuryIndicator)
    let century = '19'

    if ((inGroup1 && year <= 36) || (inGroup2 && year <= 57)) {
      century = '20'
    } else if (inGroup2 && year >= 58) {
      century = '18'
    }

    return parseInt(`${century}${parts.year}`, 10)
  }

  // Since not all Danish numbers validate, then there is no point of checking them!
  const localValidate: ValidateFunc = (): boolean => {
    if (strict) {
      return validateBitCheckSum(clean(personalNumber), [4, 3, 2, 7, 6, 5, 4, 3, 2, 1])
    }

    return true
  }

  const validate: ValidateFunc = validateFactory(personalNumber, regex, parts, getYear, localValidate)

  if (!validate()) {
    return defaultTools()
  }

  const getBirthday: GetBirthdayFunc = getBirthdayFactory(parts, getYear)

  const getAge: GetAgeFunc = getAgeFactory(getBirthday)

  const getGender: GetGenderFunc = getGenderFactory(parts)

  const getCensored: GetCensoredFunc = (): string => `${parts.day}${parts.month}${parts.year}-****`

  const getCountry: GetCountryFunc = (): string => 'denmark'

  return {
    getAge,
    getBirthday,
    getCensored,
    getCountry,
    getGender,
    getYear,
    validate
  }
}

export default denmark
