import { IconSVGProps } from '../types';

export default function SVGComponentArrowRight({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0587 19.5L19.5 12L13.0587 4.5M19.4974 11.9989L4.5 11.9989"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
