import { MouseEvent, useCallback, useState } from 'react';
import { ButtonProps } from './types';
import { ButtonStyled, getButtonTextColor } from './Button.styles';
import Icon from '../Icon/Icon';
import { LargeIcons, MediumIcons, SmallIcons } from '../Icon/types';

export default function Button({
  size = 's',
  variant = 'primary',
  iconPosition = 'right',
  type = 'button',
  disabled = false,
  fullWidth = false,
  label,
  icon,
  iconSize = 'm',
  onClick,
  onMouseOver,
  onMouseOut,
  testId,
  ariaLabel,
  children,
  isSelected,
  ...restComponentProps
}: ButtonProps) {
  const [buttonContentColor, setButtonContentColor] = useState(() => {
    return getButtonTextColor(variant, isSelected);
  });

  const buttonOnClick = useCallback(
    (event: MouseEvent) => {
      if (typeof onClick !== 'undefined') {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <ButtonStyled
      onClick={(e: MouseEvent) => buttonOnClick(e)}
      type={type}
      variant={variant}
      size={size}
      aria-label={ariaLabel}
      data-testid={testId}
      disabled={disabled}
      iconPosition={iconPosition}
      fullWidth={fullWidth}
      onMouseOver={(e: MouseEvent) => {
        setButtonContentColor(getButtonTextColor(variant, true));
        onMouseOver && onMouseOver(e);
      }}
      onMouseOut={(e: MouseEvent) => {
        setButtonContentColor(getButtonTextColor(variant, isSelected));
        onMouseOut && onMouseOut(e);
      }}
      isSelected={isSelected}
      {...restComponentProps}
    >
      {children ? (
        children
      ) : (
        <>
          {label && label.length && <span>{label}</span>}
          {icon && iconSize === 's' && <Icon size="s" name={icon as SmallIcons} color={buttonContentColor} />}
          {icon && iconSize === 'm' && <Icon size="m" name={icon as MediumIcons} color={buttonContentColor} />}
          {icon && iconSize === 'l' && <Icon size="l" name={icon as LargeIcons} color={buttonContentColor} />}
        </>
      )}
    </ButtonStyled>
  );
}
