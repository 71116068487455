import React from 'react';
import styled from 'styled-components';

import { useTheme } from '../../hooks/useTheme';
import FlagCzechRepublic from './svg/czech_republic.svg';
import FlagDenmark from './svg/denmark.svg';
import FlagNorway from './svg/norway.svg';
import FlagPortugal from './svg/portugal.svg';
import FlagSlovakia from './svg/slovakia.svg';
import FlagSpain from './svg/spain.svg';
import FlagSweden from './svg/sweden.svg';
import FlagUnitedStates from './svg/united_states.svg';
import FlagPlaceholder from './svg/placeholder';

export type Country = keyof typeof flagMapping;

export interface FlagProps {
  /** The type of flag */
  country?: Country;
  /** Title displayed on hover */
  title?: string;
}

export const flagMapping = {
  czech_republic: FlagCzechRepublic,
  denmark: FlagDenmark,
  norway: FlagNorway,
  portugal: FlagPortugal,
  slovakia: FlagSlovakia,
  spain: FlagSpain,
  sweden: FlagSweden,
  united_states: FlagUnitedStates,
  placeholder: FlagPlaceholder,
} as const;

const Wrapper = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  height: 24px;
  width: 24px;
`;

/**
 * Flag icons, 24x24 px
 */
export const Flag = ({ country, title }: FlagProps) => {
  const theme = useTheme();
  const Component = flagMapping[country || 'placeholder'];

  return (
    <Wrapper>
      <Component fill={theme.color.primary} title={title} />
    </Wrapper>
  );
};
