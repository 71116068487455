import { baseToPx, spacing } from '../../../../spacings';
import { fontFaces, fontSize, lineHeight } from '../../../../typography';
import styled, { css } from 'styled-components';
import baseColors from '../../../../theme/colors/base';
import InputMask from 'react-input-mask';
import { mediaQueries } from '../../../../media';

export const INPUT_PLACEHOLDER_COLOR = baseColors.opacityBlack[9];

const baseInputStyle = css`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.color.base.grey[1]};
  border: none;
  padding: 0;
  margin: ${baseToPx(1.125)} ${baseToPx(1.5)};
  font-size: ${fontSize.label.s};
  font-family: ${fontFaces.Roobert};
  line-height: ${lineHeight.label.s};

  box-sizing: border-box;

  .is-active &,
  .is-focus &,
  .has-error.is-focus & {
    outline: none;
  }

  .is-disabled & {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }

  &::placeholder {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  @media ${mediaQueries.mobile} {
    font-size: ${fontSize.label.m};
    line-height: ${lineHeight.label.l};
  }
`;

export const Input = styled.input<{ $visible?: boolean }>`
  ${props => props.$visible === false && 'visibility: hidden;'};
  ${baseInputStyle}}
`;

export const MaskedInput = styled(InputMask)`
  ${baseInputStyle}}
`;

export const ParsedNumberInput = styled(Input)<{ $visible?: boolean }>`
  position: absolute;
  inset: 0;
  margin: 0;
  padding: ${baseToPx(1.125)} ${baseToPx(1.5)};
  border: 1px solid transparent;
  visibility: ${props => (props.$visible ? 'visible' : 'hidden')};
  z-index: -1;
`;

export const TextArea = styled(Input).attrs({
  as: 'textarea',
})`
  min-height: 76px;
  padding: ${baseToPx(1.125)};
  margin: 0;
`;

export const InputInfoLabel = styled.span`
  font-size: ${fontSize.label.s};
  font-family: ${fontFaces.Roobert};
  line-height: ${lineHeight.label.s};
  color: ${({ theme }) => theme.color.border.defaultNormalBorder};
`;

export const InputWrapperContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.border.defaultNormalBorder};
  border-radius: ${({ theme }) => theme.spacings[0.5]};
  font-size: ${fontSize.label.s};
  font-family: ${fontFaces.Roobert};
  line-height: ${lineHeight.label.s};
  box-sizing: border-box;
  transition: border-color 0.3s ease-in;

  .has-error & {
    border-color: ${({ theme }) => theme.color.border.negativeNormalBorder};
  }

  .is-active &,
  .is-focus &,
  .has-error.is-focus & {
    border-color: ${({ theme }) => theme.color.border.focusBorder};
    outline: 1px solid ${({ theme }) => theme.color.border.focusBorder};
  }

  .is-disabled & {
    border-color: ${({ theme }) => theme.color.background.secondaryBg};
    color: ${INPUT_PLACEHOLDER_COLOR};
    background-color: ${({ theme }) => theme.color.background.secondaryBg};
    opacity: 0.5;
    outline: none;
  }
`;

export const InputIndicatorsContainer = styled.div<{ location: 'left' | 'right' }>`
  padding-${({ location }) => location}: ${baseToPx(1.125)};
  align-items: center;
  display: flex;
  gap: ${baseToPx(0.75)};
  flex-shrink: 0;

  &:empty {
    display: none;
  }
`;

export const InputButton = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
`;

export const ClearButton = styled(InputButton)`
  margin: 0 ${spacing.s1};
  width: ${baseToPx(1.5)};
  height: ${baseToPx(1.5)};
  stroke: ${({ theme }) => theme.color.base.grey[1]};
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  .has-focus & {
    opacity: 1;
    cursor: pointer;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const Currency = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0 ${baseToPx(0.75)};
  background: ${({ theme }) => theme.color.background.tertiaryBg};
  border-radius: ${spacing.s0};
  font-size: ${fontSize.label.xs};
  font-family: ${fontFaces.Roobert};
  line-height: ${lineHeight.label.xs};
`;

export const Suffix = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-family: ${fontFaces.Roobert};
`;
