import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18497 3C4.4243 3 3 4.42183 3 6.17204C3 7.92225 4.4243 9.34408 6.18497 9.34408C7.05671 9.34408 7.84554 8.99595 8.42092 8.43099C9.00755 7.85498 9.36994 7.05596 9.36994 6.17204C9.36994 4.42183 7.94563 3 6.18497 3ZM2 6.17204C2 3.86623 3.87533 2 6.18497 2C8.4946 2 10.3699 3.86623 10.3699 6.17204C10.3699 7.15376 10.0293 8.05663 9.46058 8.76886L13.9982 13.2911L13.2923 13.9994L8.74789 9.4704C8.03982 10.0179 7.15016 10.3441 6.18497 10.3441C3.87533 10.3441 2 8.47785 2 6.17204Z"
      />
    </svg>
  );
}
