import { useState, forwardRef, ForwardedRef, ChangeEvent, useCallback, useEffect } from 'react';
import Uploady, { UPLOADER_EVENTS, UploaderEnhancer, useAbortBatch } from '@rpldy/uploady';
import { UploadDropZone } from '@rpldy/upload-drop-zone';
import { Batch, BatchItem, FileLike } from '@rpldy/shared';
import { FileDropzoneContainer } from './FileUpload.styles';
import { Text } from '../Text/Text';
import { Link } from '../Link/Link';
import { useMedia } from 'hooks/useMedia';
import { ButtonProps, asUploadButton } from '@rpldy/upload-button';
import { InputWrapper } from '../Inputs/Input/InputWrapper';
import { InputWrapperSharedProps } from '../Inputs/Input/InputWrapper/InputWrapper';
import { FileInput } from './FileInput';
import Icon from '../Icon/Icon';
import { useTranslation } from 'react-i18next';

type FileUploadInitialInfo = { name: string; ref: string };

export interface FileUploadProps extends InputWrapperSharedProps {
  /**
   * string[] with list af the accepted file formats
   * ie: ['.jpg', '.csv' 'image/*', '.pdf']
   */
  acceptedTypes?: string[];
  maxFileSize?: number;
  fileInfo?: FileUploadInitialInfo;
  uploadFileLabel: string;
  dragAndDropLabel: string;
  removeFileLabel: string;
  label?: string;
  name: string;
  testId?: string;
  wrongFormatText?: string;
  wrongSizeText?: string;
  enhancer?: UploaderEnhancer;
  onFileUploadError?: (error: string) => void;
  onFileUpdate?: (file: FileLike | false) => void;
  // Returns undefined instead of event when clearing input
  onChange?: (event: ChangeEvent<HTMLInputElement> | undefined) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const bytesToMB = (size: number) => (size / 1048576).toFixed(2);

export const FileUpload = forwardRef(
  (
    {
      fileInfo,
      acceptedTypes,
      maxFileSize,
      uploadFileLabel,
      dragAndDropLabel,
      removeFileLabel,
      label,
      name,
      errorText,
      hintText,
      warningText,
      infoText,
      wrongFormatText,
      wrongSizeText,
      required,
      testId,
      enhancer,
      onFileUpdate,
      onFileUploadError,
      onChange,
      onBlur,
    }: FileUploadProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [currentFileInfo, setCurrentFileInfo] = useState<FileUploadInitialInfo & { batchId?: string }>(
      () => fileInfo || { name: '', ref: '', batchId: '' }
    );
    const accept = acceptedTypes?.join(',');
    const isMobile = useMedia('mobile');
    const { t } = useTranslation();
    const [fileFormatError, setFileFormatError] = useState(false);
    const [fileSizeError, setFileSizeError] = useState(false);

    const getErrorText = useCallback(
      ({
        fileFormatError,
        fileSizeError,
        wrongFormatText,
        wrongSizeText,
        errorText,
        acceptedTypes,
        maxFileSize,
      }: Pick<FileUploadProps, 'wrongFormatText' | 'wrongSizeText' | 'errorText' | 'acceptedTypes' | 'maxFileSize'> & {
        fileFormatError: boolean;
        fileSizeError: boolean;
      }) => {
        switch (true) {
          case fileFormatError:
            return (
              wrongFormatText ||
              t('error.invalidFileFormat', {
                allowedFormats: acceptedTypes?.join(', '),
                suggestedAction: t('chooseDifferentFile'),
              })
            );
          case fileSizeError:
            return (
              wrongSizeText ||
              t('error.invalidFileSize', {
                maxFileSize: maxFileSize ? t('error.maxFileSize', { fileSize: bytesToMB(maxFileSize) }) : '',
                suggestedAction: t('chooseDifferentFile'),
              })
            );
          default:
            return errorText;
        }
      },
      [t]
    );

    const isValidFormat = useCallback(
      (item: BatchItem) => (acceptedTypes && acceptedTypes.length > 0 ? acceptedTypes.includes(item.file.type) : true),
      [acceptedTypes]
    );

    const isValidSize = useCallback(
      (item: BatchItem) => item.file.size && (maxFileSize ? item.file.size <= maxFileSize : true),
      [maxFileSize]
    );

    useEffect(() => {
      if (fileFormatError || fileSizeError) {
        const errorMsg = getErrorText({
          fileFormatError,
          fileSizeError,
          wrongFormatText,
          wrongSizeText,
          errorText,
          acceptedTypes,
          maxFileSize,
        });
        errorMsg && onFileUploadError && onFileUploadError(errorMsg);
      }
    }, [
      acceptedTypes,
      errorText,
      fileFormatError,
      fileSizeError,
      getErrorText,
      maxFileSize,
      onFileUploadError,
      wrongFormatText,
      wrongSizeText,
    ]);

    const listeners = {
      [UPLOADER_EVENTS.BATCH_ADD]: (batch: Batch) => {
        const item = batch.items[0];
        const hasValidFormat = isValidFormat(item);
        const hasValidSize = isValidSize(item);
        setFileFormatError(false);
        setFileSizeError(false);
        onFileUpdate && onFileUpdate(item.file);
        if (hasValidFormat && hasValidSize) {
          setCurrentFileInfo({ name: item.file.name, ref: '', batchId: batch.id });
          onChange && onChange({ target: { name, files: [item.file] } } as unknown as ChangeEvent<HTMLInputElement>);
        } else {
          !hasValidFormat && setFileFormatError(true);
          !hasValidSize && setFileSizeError(true);
        }
      },
    };

    const AbortButton = () => {
      const abortBatch = useAbortBatch();

      return (
        <Link
          size="s"
          onClick={() => {
            abortBatch(currentFileInfo.batchId || '');
            setCurrentFileInfo({ name: '', ref: '', batchId: '' });
            onChange && onChange(undefined);
          }}
        >
          {removeFileLabel}
        </Link>
      );
    };

    const FileSelectButton = asUploadButton((props: ButtonProps) => {
      return (
        <span {...props}>
          <Link>{uploadFileLabel}</Link> <Text>{dragAndDropLabel}</Text>
        </span>
      );
    });

    return (
      <InputWrapper
        label={label}
        inputId={name}
        errorText={getErrorText({
          fileFormatError,
          fileSizeError,
          wrongFormatText,
          wrongSizeText,
          errorText,
          acceptedTypes,
          maxFileSize,
        })}
        infoText={infoText}
        warningText={warningText}
        hintText={hintText}
        required={required}
      >
        <Uploady
          customInput
          accept={accept}
          multiple={false}
          listeners={listeners}
          enhancer={enhancer}
          autoUpload={false}
        >
          <FileInput ref={ref} name={name} testId={testId} onChange={onChange} onBlur={onBlur} />
          <div>
            <UploadDropZone onDragOverClassName="drag-over">
              <FileDropzoneContainer
                hasFile={!!currentFileInfo.name}
                isMobile={isMobile}
                isErrorState={!!errorText || fileFormatError || fileSizeError}
              >
                {currentFileInfo.name ? (
                  <>
                    <Icon name="positive" fill="positive" size="m" />
                    <Text>{currentFileInfo.name}</Text>
                    <AbortButton />
                  </>
                ) : (
                  <>
                    <Icon name="upload" size="s" />
                    <FileSelectButton />
                  </>
                )}
              </FileDropzoneContainer>
            </UploadDropZone>
          </div>
        </Uploady>
      </InputWrapper>
    );
  }
);
