import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5C5.23122 5 4 6.23122 4 7.75V16.25C4 17.7688 5.23122 19 6.75 19H17.25C18.7688 19 20 17.7688 20 16.25V7.75C20 6.23122 18.7688 5 17.25 5H6.75ZM5.96206 6.77956C6.17708 6.60477 6.4513 6.5 6.75 6.5H17.25C17.5487 6.5 17.8229 6.60477 18.0379 6.77956L12.797 11.1169C12.3346 11.4996 11.6654 11.4996 11.203 11.1169L5.96206 6.77956ZM5.5 8.34422V16.25C5.5 16.9404 6.05964 17.5 6.75 17.5H17.25C17.9404 17.5 18.5 16.9404 18.5 16.25V8.34422L13.7533 12.2725C12.736 13.1144 11.264 13.1144 10.2467 12.2725L5.5 8.34422Z"
        fill={fill}
      />
    </svg>
  );
}
