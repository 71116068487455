import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke, fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.80664 14H4.99999" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.79456 11.009H5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 7C6.25 6.58579 6.58579 6.25 7 6.25H8.95239H9.41667C11.0691 6.25 12.6252 7.02802 13.6167 8.35L13.0167 8.8L13.6167 8.35L14.6977 9.79134L14.0977 10.2413L14.6977 9.79134C14.8141 9.94659 14.9651 10.0726 15.1386 10.1594L15.0576 10.3214L15.1386 10.1594L15.607 10.3935C15.6162 10.3979 15.6253 10.4024 15.6342 10.4072L17.2298 11.205L16.8944 11.8758L17.2298 11.205C18.1615 11.6708 18.75 12.623 18.75 13.6647V15.25C18.75 16.3546 17.8546 17.25 16.75 17.25H16.622C16.3131 18.1239 15.4797 18.75 14.5 18.75C13.5203 18.75 12.6869 18.1239 12.378 17.25H7C6.58579 17.25 6.25 16.9142 6.25 16.5C6.25 16.0858 6.58579 15.75 7 15.75H12.378C12.6869 14.8761 13.5203 14.25 14.5 14.25C15.4797 14.25 16.3131 14.8761 16.622 15.75H16.75C17.0261 15.75 17.25 15.5261 17.25 15.25V13.6646C17.25 13.1912 16.9825 12.7583 16.559 12.5466L15.1087 11.8214H9.95239C8.98589 11.8214 8.20239 11.0379 8.20239 10.0714V7.75H7C6.58579 7.75 6.25 7.41421 6.25 7ZM13.75 16.5C13.75 16.9142 14.0858 17.25 14.5 17.25C14.9142 17.25 15.25 16.9142 15.25 16.5C15.25 16.0858 14.9142 15.75 14.5 15.75C14.0858 15.75 13.75 16.0858 13.75 16.5ZM9.70239 10.0714V7.76088C10.7747 7.84272 11.7659 8.38233 12.4167 9.25L13.2202 10.3214H9.95239C9.81432 10.3214 9.70239 10.2095 9.70239 10.0714Z"
        fill={fill}
      />
    </svg>
  );
}
