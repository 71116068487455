import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 5H18C19.105 5 20 5.895 20 7V20C20 21.105 19.105 22 18 22H6C4.895 22 4 21.105 4 20V7C4 5.895 4.895 5 6 5H10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.591 10.659C14.4697 11.5377 14.4697 12.9623 13.591 13.841C12.7123 14.7197 11.2877 14.7197 10.409 13.841C9.53033 12.9623 9.53033 11.5377 10.409 10.659C11.2877 9.78033 12.7123 9.78033 13.591 10.659"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.595 19C15.42 18.561 15.15 18.167 14.803 17.846V17.846C14.216 17.302 13.446 17 12.646 17H11.354C10.554 17 9.78403 17.302 9.19703 17.846V17.846C8.85003 18.167 8.58003 18.561 8.40503 19"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7H11C10.448 7 10 6.552 10 6V3C10 2.448 10.448 2 11 2H13C13.552 2 14 2.448 14 3V6C14 6.552 13.552 7 13 7Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
