import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28631 5.22211C5.99429 4.92834 5.51942 4.92693 5.22565 5.21894C4.93189 5.51096 4.93047 5.98583 5.22248 6.2796L10.9092 12.0004L5.22248 17.7212C4.93047 18.015 4.93188 18.4899 5.22565 18.7819C5.51942 19.0739 5.99429 19.0725 6.28631 18.7787L12.4986 12.5292C12.7894 12.2366 12.7894 11.7642 12.4986 11.4717L6.28631 5.22211ZM14.5695 5.22211C14.2775 4.92834 13.8026 4.92693 13.5089 5.21894C13.2151 5.51096 13.2137 5.98583 13.5057 6.2796L19.1924 12.0004L13.5057 17.7212C13.2137 18.015 13.2151 18.4899 13.5089 18.7819C13.8026 19.0739 14.2775 19.0725 14.5695 18.7787L20.7818 12.5292C21.0726 12.2366 21.0726 11.7642 20.7818 11.4717L14.5695 5.22211Z"
        fill={fill}
      />
    </svg>
  );
}
