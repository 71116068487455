import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 8.00005L29.253 18.5321L41 20.2314L32.5 28.4249L34.506 40L24 34.5321L13.494 40L15.5 28.4249L7 20.2314L18.747 18.5321L24 8.00005Z"
        stroke={stroke}
        stroke-width="3"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
