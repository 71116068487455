import React, { ForwardedRef, useState } from 'react';
import Select from 'react-select';
import { InputWrapper } from '../Input/InputWrapper';
import { ClearIndicator, DropdownIndicator, MultiSelectSelectedOptions, MultiValue } from './customComponents';
import { StyledSelect } from './Select.styles';
import { SelectCustomComponents, SelectOption, SelectWrapperProps } from './types';
import { guidGenerator } from '../../../utils/idGenerator';

export const SelectWrapper = React.forwardRef(
  // react-select doesn't expose its generic Select type so we can't use it here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ SelectComponent = Select, selectProps }: SelectWrapperProps, ref: ForwardedRef<any>) => {
    const [selectedValues, setSelectedValues] = useState<SelectOption[]>(
      (selectProps.defaultValue as SelectOption[]) || []
    );

    const inputId = guidGenerator();

    const customComponents: SelectCustomComponents = {
      DropdownIndicator,
      ClearIndicator,
    };

    // Apply fake Placeholder if selected options are below input
    if (selectProps.isMultiAlternative) {
      customComponents.MultiValue = MultiValue;
    }

    return (
      <InputWrapper
        inputId={inputId}
        label={selectProps.label}
        visuallyHidden={selectProps.labelHidden}
        errorText={selectProps.error}
        infoText={selectProps.infoText}
        disabled={selectProps.isDisabled}
        required={selectProps.required}
      >
        <StyledSelect
          {...selectProps}
          components={customComponents}
          as={SelectComponent}
          classNamePrefix="react-select"
          error={selectProps.error}
          inputId={inputId}
          ref={ref}
          width={selectProps.customStyles?.width}
          hideSelectedOptions={selectProps.isMultiAlternative}
          onChange={(newValue, actionMeta) => {
            selectProps.isMultiAlternative && setSelectedValues(newValue as SelectOption[]);
            selectProps.onChange && selectProps.onChange(newValue, actionMeta);
          }}
          value={selectProps.isMultiAlternative ? selectedValues : selectProps.value}
          isMulti={selectProps.isMulti || selectProps.isMultiAlternative}
        />

        {selectProps.isMultiAlternative && !!selectedValues.length && (
          <MultiSelectSelectedOptions selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
        )}
      </InputWrapper>
    );
  }
);
