import { minSupportedWidth } from 'media';
import { spacing } from 'spacings';
import styled from 'styled-components';
import { NotificationPromptProps, PromptPosition } from './NotificationPrompt';

const returnPositionCoordinates = (position?: PromptPosition) => {
  switch (position) {
    case 'top-left':
      return 'top: 0; left: 0;';
    case 'top-center':
      return 'top: 0; left: 50%; transform: translateX(-50%);';
    case 'top-right':
      return 'top: 0; right: 0;';
    case 'center-left':
      return 'top: 50%; left: 0; transform: translateY(-50%);';
    case 'center':
      return 'top: 50%; left: 50%; transform: translate(-50%, -50%);';
    case 'center-right':
      return 'top: 50%; right: 0; transform: translateY(-50%);';
    case 'bottom-left':
      return 'bottom: 0; left: 0;';
    case 'bottom-center':
      return 'bottom: 0; left: 50%; transform: translateX(-50%);';
    case 'bottom-right':
    default:
      // deliberate falltrough for bottom-right to default
      return 'bottom: 0; right: 0;';
  }
};

export const NotificationWrapper = styled.div<NotificationPromptProps & { isMobile: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: ${({ width, isMobile }) => (width ? `${width}px` : isMobile ? '100%' : '33%')};
  min-width: ${minSupportedWidth - parseInt(spacing.s6)}px;
  gap: ${({ gap }) => (gap ? `${gap}px` : spacing.s2)};
  padding: ${({ offset }) => (offset ? `${offset}px` : spacing.s4)};
  z-index: 9999;
  box-sizing: border-box;
  ${({ position }) => returnPositionCoordinates(position)};

  &:empty {
    padding: 0;
  }

  & > * {
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  }
`;
