import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6558 10.7345C18.4671 6.98824 15.2878 5 11.9989 5C8.70999 5 5.53068 6.98824 3.34197 10.7345C2.88568 11.5155 2.88568 12.4851 3.34197 13.2661C5.53068 17.0124 8.70999 19.0006 11.9989 19.0006C15.2878 19.0006 18.4671 17.0124 20.6558 13.2661C21.1121 12.4851 21.1121 11.5155 20.6558 10.7345ZM4.63713 11.4912C6.626 8.08697 9.36723 6.5 11.9989 6.5C14.6305 6.5 17.3718 8.08697 19.3606 11.4912C19.5438 11.8047 19.5438 12.1959 19.3606 12.5094C17.3718 15.9136 14.6305 17.5006 11.9989 17.5006C9.36723 17.5006 6.626 15.9136 4.63713 12.5094C4.45397 12.1959 4.45397 11.8047 4.63713 11.4912ZM12 14.8847C13.666 14.8847 15.0165 13.5932 15.0165 12C15.0165 10.4068 13.666 9.11523 12 9.11523C10.334 9.11523 8.98346 10.4068 8.98346 12C8.98346 13.5932 10.334 14.8847 12 14.8847Z"
        fill={stroke}
      />
    </svg>
  );
}
