import { ReactNode } from 'react';
import styled from 'styled-components';

export interface OverlayProps {
  background?: string;
  opacity?: number;
  isFullScreen?: boolean;
  children?: ReactNode;
}

export const Overlay = ({ background, children, opacity = 1, isFullScreen = true }: OverlayProps) => (
  <OverlayWrapper isFullScreen={isFullScreen}>
    <OverlayLayer background={background} opacity={opacity} />
    <OverlayContent>{children}</OverlayContent>
  </OverlayWrapper>
);

const OverlayWrapper = styled.div<Pick<OverlayProps, 'isFullScreen'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ isFullScreen }) => (isFullScreen ? 'fixed' : 'absolute')};
  inset: 0;
  z-index: 9998;
`;

const OverlayLayer = styled.div<Pick<OverlayProps, 'background' | 'opacity'>>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background ?? '#fff'};
  opacity: ${({ opacity }) => opacity ?? '1'};
`;

const OverlayContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
