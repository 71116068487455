import styled, { css } from 'styled-components';
import { InputSize } from 'components/Filters/types';
import Button from '../Button/Button';

const getInputWidth = (size?: InputSize) => {
  if (size === 's') return '216px';
  if (size === 'm') return '320px';
  return '216px';
};

export const FiltersBar = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacings['1.5']};
  margin-top: ${({ theme }) => theme.spacings['1']};
  margin-bottom: ${({ theme }) => theme.spacings['4']};
`;

export const FilterInputWrapper = styled.div<{ size?: 's' | 'm'; toggle?: boolean }>`
  flex-grow: 1;
  order: ${({ toggle }) => (toggle ? 2 : 1)};
  max-width: ${({ size }) => getInputWidth(size)};
  ${({ toggle }) =>
    toggle &&
    css`
      display: flex;
      align-items: center;
      flex: initial;
      max-width: initial;
      min-width: 260px;
    `}
  span {
    white-space: nowrap;
  }
`;

export const ClearButton = styled(Button)`
  order: 1;
  margin-right: auto;
`;
