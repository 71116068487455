import Hour24 from './regular/24-hour';
import AccountCards from './regular/account-cards';
import Accounting from './regular/accounting';
import AddImage from './regular/add-image';
import ArrowDown from './regular/arrow-down';
import ArrowLeft from './regular/arrow-left';
import ArrowRight from './regular/arrow-right';
import ArrowUp from './regular/arrow-up';
import Backspace from './regular/back-space';
import Bank from './regular/bank';
import Booking from './regular/booking';
import Bug from './regular/bug';
import Calendar from './regular/calendar';
import CameraOff from './regular/camera-off';
import Camera from './regular/camera';
import Cart from './regular/cart';
import Cash from './regular/cash';
import CashMoney from './regular/cash-money';
import CheckCircled from './regular/check-circled';
import Check from './regular/check';
import ChevronDown from './regular/chevron-down';
import ChevronLeft from './regular/chevron-left';
import ChevronRight from './regular/chevron-right';
import ChevronUp from './regular/chevron-up';
import Clock from './regular/clock';
import Close from './regular/close';
import CloseCircled from './regular/close-circled';
import Collect from './regular/collect';
import Copy from './regular/copy';
import Contract from './regular/contract';
import CreditCard from './regular/credit-card';
import Delivery from './regular/delivery';
import Discount from './regular/discount';
import Documents from './regular/documents';
import DoubleChevronLeft from './regular/double-chevron-left';
import DoubleChevronRight from './regular/double-chevron-right';
import Download from './regular/download';
import DragHandle from './regular/drag-handle';
import Duplicate from './regular/duplicate';
import Eco from './regular/eco';
import Edit from './regular/edit';
import Email from './regular/email';
import EmailOpen from './regular/email-open';
import EmployeesBadge from './regular/employees-badge';
import Enter from './regular/enter';
import Equal from './regular/equal';
import ExternalLink from './regular/external-link';
import Filter from './regular/filter';
import Fireworks from './regular/fireworks';
import Globe from './regular/globe';
import GlobeRegion from './regular/globe-region';
import Grid from './regular/grid';
import Help from './regular/help';
import Heart from './regular/heart';
import Home from './regular/home';
import Idk from './regular/idk';
import Image from './regular/image';
import Info from './regular/info';
import Invoice from './regular/invoice';
import KitchenDisplays from './regular/kitchen-displays';
import List from './regular/list';
import Location from './regular/location';
import LogIn from './regular/log-in';
import LogOut from './regular/log-out';
import Meetings from './regular/meetings';
import Menu from './regular/menu';
import MenuResizeLeft from './regular/menu-resize-left';
import MenuResizeRight from './regular/menu-resize-right';
import MenuResizeLeftAlt from './regular/menu-resize-left-alt';
import MenuResizeRightAlt from './regular/menu-resize-right-alt';
import Minus from './regular/minus';
import Message from './regular/message';
import MoreHorizontal from './regular/more-horizontal';
import MoreVertical from './regular/more-vertical';
import New from './regular/new';
import Note from './regular/note';
import Notification from './regular/notification';
import NotificationOn from './regular/notification-on';
import Numbers from './regular/numbers';
import OpenDrawer from './regular/open-drawer';
import Online from './regular/online';
import Orders from './regular/orders';
import Passport from './regular/passport';
import PauseCircled from './regular/pause-circled';
import Payout from './regular/payout';
import PhoneCall from './regular/phone-call';
import Phone from './regular/phone';
import Pickup from './regular/pickup';
import Placeholder from './regular/placeholder';
import PlayCircled from './regular/play-circled';
import Plus from './regular/plus';
import PointOfSale from './regular/point-of-sale';
import Printing from './regular/printing';
import Product from './regular/product';
import Progress from './regular/progress';
import QR from './regular/QR';
import QuestionCircled from './regular/question-circled';
import RatingSurvey from './regular/rating-survey';
import Receipt from './regular/receipt';
import Refresh from './regular/refresh';
import Refund from './regular/refund';
import ResizeDrag from './regular/resize-drag';
import Retail from './regular/retail';
import Return from './regular/return';
import Sanctions from './regular/sanctions';
import Search from './regular/search';
import Save from './regular/save';
import Scan from './regular/scan';
import SelfService from './regular/self-service';
import Settings from './regular/settings';
import Share from './regular/share';
import Send from './regular/send';
import Split from './regular/split';
import StockInventory from './regular/stock-inventory';
import Swap from './regular/swap';
import Swish from './regular/swish';
import Tables from './regular/tables';
import Takeaway from './regular/takeaway';
import Tax from './regular/tax';
import ThumbUp from './regular/thumb-up';
import ThumbDown from './regular/thumb-down';
import Tipping from './regular/tipping';
import Trash from './regular/trash';
import Truck16 from './regular/truck-16';
import Upload from './regular/upload';
import User from './regular/user';
import Users from './regular/users';
import VisibleOff from './regular/visible-off';
import VisibleOn from './regular/visible-on';
import Warning from './regular/warning';
import WarningCircled from './regular/warning-circled';
import Web from './regular/web';

import CheckmarkSmall from './small/checkmark';
import ChevronDownSmall from './small/chevron-down';
import ChevronLeftSmall from './small/chevron-left';
import ChevronRightSmall from './small/chevron-right';
import ChevronBoldSmall from './small/chevron-bold';
import ChevronUpSmall from './small/chevron-up';
import CloseSmall from './small/close';
import DeleteSmall from './small/delete';
import DragSmall from './small/drag';
import HelpSmall from './small/help';
import MinusSmall from './small/minus';
import NegativeSmall from './small/negative';
import PlaceholderSmall from './small/placeholder';
import PlusSmall from './small/plus';
import SearchSmall from './small/search';
import ShowSmall from './small/show';
import ExpandSmall from './small/expand';
import UploadSmall from './small/upload';
import DirectionLeftSmall from './small/direction-left';
import DirectionRightSmall from './small/direction-right';
import DirectionUpSmall from './small/direction-up';
import DirectionDownSmall from './small/direction-down';
import CurvedChevronLeftSmall from './small/curved-chevron-left';
import CurvedChevronRightSmall from './small/curved-chevron-right';
import DoubleChevronLeftSmall from './small/double-chevron-left';
import DoubleChevronRightSmall from './small/double-chevron-right';
import DragHandleSmall from './small/drag-handle';
import EditSmall from './small/edit';
import ExternalLinkSmall from './small/external-link';
import InfoSmall from './small/info';
import QuestionSmall from './small/question';
import TrashSmall from './small/trash';
import TreeSmall from './small/tree';
import VisibleOffSmall from './small/visible-off';

import CashLarge from './large/cash';
import CashMachineLarge from './large/cash-machine';
import HandLarge from './large/hand';
import HeartLarge from './large/heart';
import KioskLarge from './large/kiosk';
import PlaceholderLarge from './large/placeholder';
import ReceiptLarge from './large/receipt';
import RepeatLarge from './large/repeat';
import RestaurantLarge from './large/restaurant';
import RetailLarge from './large/retail';
import SmileyLarge from './large/smiley';
import SuccessfulLarge from './large/successful';
import SunLarge from './large/sun';
import SwirlLarge from './large/swirl';
import TaxiLarge from './large/taxi';
import TablesLarge from './large/tables';
import TerminalLarge from './large/terminal';
import TerminalOffLarge from './large/terminal-off';
import WarningLarge from './large/warning';
import WarningXlLarge from './large/warning-xl';
import AccountCardsLarge from './large/account-card';
import CloseLarge from './large/close';
import ContactlessLarge from './large/contactless';
import CreditCardLarge from './large/credit-card';
import DeleteLarge from './large/delete';
import ErrorLarge from './large/error';
import FolderLarge from './large/folder';
import GiftCardLarge from './large/gift-card';
import InsertCardLarge from './large/insert-card';
import InvoiceLarge from './large/invoice';
import KeepDryLarge from './large/keep-dry';
import MobileWalletLarge from './large/mobile-wallet';
import ReceiptsLarge from './large/receipts';
import RecycleLarge from './large/recycle';
import ReturnLarge from './large/return';
import SadEmojiLarge from './large/sad-emoji';
import StarLarge from './large/star';

export const iconMapping = {
  s: {
    checkmark: CheckmarkSmall,
    chevronDown: ChevronDownSmall,
    chevronLeft: ChevronLeftSmall,
    chevronRight: ChevronRightSmall,
    chevronBold: ChevronBoldSmall,
    chevronUp: ChevronUpSmall,
    close: CloseSmall,
    curvedChevronLeft: CurvedChevronLeftSmall,
    curvedChevronRight: CurvedChevronRightSmall,
    delete: DeleteSmall,
    directionLeft: DirectionLeftSmall,
    directionRight: DirectionRightSmall,
    directionUp: DirectionUpSmall,
    directionDown: DirectionDownSmall,
    doubleChevronLeft: DoubleChevronLeftSmall,
    doubleChevronRight: DoubleChevronRightSmall,
    menuChevronLeft: MenuResizeLeft,
    menuChevronRight: MenuResizeRight,
    menuChevronLeftAlt: MenuResizeLeftAlt,
    menuChevronRightAlt: MenuResizeRightAlt,
    dragHandle: DragHandleSmall,
    drag: DragSmall,
    edit: EditSmall,
    externalLink: ExternalLinkSmall,
    expand: ExpandSmall,
    help: HelpSmall,
    info: InfoSmall,
    minus: MinusSmall,
    negative: NegativeSmall,
    placeholder: PlaceholderSmall,
    plus: PlusSmall,
    search: SearchSmall,
    show: ShowSmall,
    trash: TrashSmall,
    tree: TreeSmall,
    upload: UploadSmall,
    visibleOff: VisibleOffSmall,
    questionSmall: QuestionSmall,
  },
  m: {
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    arrowUp: ArrowUp,
    arrowDown: ArrowDown,
    chevronDown: ChevronDown,
    chevronUp: ChevronUp,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    solidChevronRight: DoubleChevronRight,
    solidChevronDown: DoubleChevronLeft,
    '24hour': Hour24,
    accountCards: AccountCards,
    accounting: Accounting,
    addImage: AddImage,
    bank: Bank,
    backspace: Backspace,
    booking: Booking,
    bugs: Bug,
    calendar: Calendar,
    camera: Camera,
    cameraOff: CameraOff,
    cart: Cart,
    cash: Cash,
    cashMoney: CashMoney,
    checkmark: Check,
    clock: Clock,
    close: Close,
    closeCircled: CloseCircled,
    collect: Collect,
    copy: Copy,
    comments: Message,
    contract: Contract,
    creditCard: CreditCard,
    delete: Trash,
    delivery: Delivery,
    discount: Discount,
    download: Download,
    dragExpand: ResizeDrag,
    dragHandle: DragHandle,
    duplicate: Duplicate,
    eco: Eco,
    edit: Edit,
    eject: OpenDrawer,
    email: Email,
    emailOpen: EmailOpen,
    employeesBadge: EmployeesBadge,
    enter: Enter,
    equal: Equal,
    externalLink: ExternalLink,
    erase: CloseCircled,
    heart: Heart,
    home: Home,
    filter: Filter,
    fireworks: Fireworks,
    globe: Globe,
    globeRegion: GlobeRegion,
    grid: Grid,
    help: QuestionCircled,
    hide: VisibleOff,
    idk: Idk,
    image: Image,
    kitchenDisplays: KitchenDisplays,
    list: List,
    loaderRadial: Progress,
    logIn: LogIn,
    logOut: LogOut,
    menu: Menu,
    meetings: Meetings,
    minus: Minus,
    more: MoreHorizontal,
    moreVertical: MoreVertical,
    negative: WarningCircled,
    new: New,
    note: Note,
    notifications: Notification,
    notificationsOn: NotificationOn,
    numbers: Numbers,
    orders: Orders,
    online: Online,
    pause: PauseCircled,
    passport: Passport,
    phoneCall: PhoneCall,
    placeholder: Placeholder,
    plus: Plus,
    play: PlayCircled,
    pickup: Pickup,
    printing: Printing,
    pos: PointOfSale,
    positive: CheckCircled,
    products: Product,
    qr: QR,
    ratingSurvey: RatingSurvey,
    receipt: Receipt,
    refresh: Refresh,
    retail: Retail,
    return: Return,
    refund: Refund,
    sanctions: Sanctions,
    save: Save,
    scan: Scan,
    selfService: SelfService,
    search: Search,
    settings: Settings,
    show: VisibleOn,
    tables: Tables,
    user: User,
    support: Help,
    share: Share,
    split: Split,
    stockInventory: StockInventory,
    swap: Swap,
    swish: Swish,
    takeaway: Takeaway,
    tax: Tax,
    thumbUp: ThumbUp,
    thumbDown: ThumbDown,
    tipping: Tipping,
    trash: Trash,
    truck16: Truck16,
    users: Users,
    receiptInvoice: Invoice,
    phone: Phone,
    payout: Payout,
    location: Location,
    documents: Documents,
    upload: Upload,
    warningCircled: WarningCircled,
    checkmarkCircled: CheckCircled,
    infoCircled: Info,
    warningTriangle: Warning,
    send: Send,
    web: Web,
  },
  l: {
    accountCard: AccountCardsLarge,
    cash: CashLarge,
    cashMachine: CashMachineLarge,
    close: CloseLarge,
    contactless: ContactlessLarge,
    creditCard: CreditCardLarge,
    delete: DeleteLarge,
    error: ErrorLarge,
    folder: FolderLarge,
    giftCard: GiftCardLarge,
    hand: HandLarge,
    heart: HeartLarge,
    insertCard: InsertCardLarge,
    invoice: InvoiceLarge,
    keepDry: KeepDryLarge,
    kiosk: KioskLarge,
    mobileWallet: MobileWalletLarge,
    placeholder: PlaceholderLarge,
    receipts: ReceiptsLarge,
    receipt: ReceiptLarge,
    recycle: RecycleLarge,
    repeat: RepeatLarge,
    restaurant: RestaurantLarge,
    retail: RetailLarge,
    return: ReturnLarge,
    sadEmoji: SadEmojiLarge,
    smiley: SmileyLarge,
    star: StarLarge,
    successful: SuccessfulLarge,
    sun: SunLarge,
    swirl: SwirlLarge,
    taxi: TaxiLarge,
    tables: TablesLarge,
    terminal: TerminalLarge,
    terminalOff: TerminalOffLarge,
    warning: WarningLarge,
    warningXl: WarningXlLarge,
  },
} as const;
