import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.40625 0.90625C3.82046 0.90625 4.15625 1.24204 4.15625 1.65625V4.625H8.1087C8.43141 3.54069 9.43587 2.75 10.625 2.75C12.0747 2.75 13.25 3.92525 13.25 5.375C13.25 6.82475 12.0747 8 10.625 8C9.43587 8 8.43141 7.20931 8.1087 6.125H4.15625V8.375C4.15625 10.1699 5.61132 11.625 7.40625 11.625H8.1087C8.43141 10.5407 9.43587 9.75 10.625 9.75C12.0747 9.75 13.25 10.9253 13.25 12.375C13.25 13.8247 12.0747 15 10.625 15C9.43587 15 8.43141 14.2093 8.1087 13.125H7.40625C4.7829 13.125 2.65625 10.9984 2.65625 8.375V5.375V1.65625C2.65625 1.24204 2.99204 0.90625 3.40625 0.90625Z"
        fill={fill}
      />
    </svg>
  );
}
