import { useEffect, useState } from 'react';
import { baseToPx, spacings } from 'spacings';
import styled from 'styled-components';
import { fontWeight } from 'typography';
import { Tab as TabProps, TabsProps } from './types';
import { Text } from '../Text/Text';

export const Tabs = ({ tabs, initialActiveTab, onClick }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab || tabs[0]?.key || '');

  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  const onTabClick = (tab: TabProps) => {
    if (onClick) onClick(tab);
    setActiveTab(tab.key);
  };

  return (
    <TabsWrapper>
      {tabs.map(tab => {
        const isActive = tab.key === activeTab;
        return (
          <Tab isActive={isActive} disabled={tab.isDisabled} onClick={() => onTabClick(tab)} key={tab.key}>
            <Text color={isActive ? 'primary' : 'tertiary'}>{tab.label}</Text>
            {tab.suffix}
          </Tab>
        );
      })}
    </TabsWrapper>
  );
};

const TabsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacings[3]};
`;

const Tab = styled.button<{ isActive: boolean; disabled?: boolean }>`
  /* reset button styles */
  border: none;
  background-color: transparent;

  padding: 0 0 ${spacings[1]} 0;
  border-bottom-style: solid;
  border-bottom-width: ${baseToPx(0.25)};
  border-bottom-color: ${({ isActive, theme }) => (isActive ? theme.color.border.focusBorder : 'transparent')};

  font-weight: ${({ isActive }) => (isActive ? fontWeight.bold : fontWeight.normal)};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1]};
`;
