import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 7.25C11.3358 7.25 11 7.58579 11 8C11 8.41421 11.3358 8.75 11.75 8.75H19.2472C19.6614 8.75 19.9972 8.41421 19.9972 8C19.9972 7.58579 19.6614 7.25 19.2472 7.25H11.75ZM11.75 11.2496C11.3358 11.2496 11 11.5854 11 11.9996C11 12.4138 11.3358 12.7496 11.75 12.7496H19.2472C19.6614 12.7496 19.9972 12.4138 19.9972 11.9996C19.9972 11.5854 19.6614 11.2496 19.2472 11.2496H11.75ZM11 16C11 15.5858 11.3358 15.25 11.75 15.25H19.2472C19.6614 15.25 19.9972 15.5858 19.9972 16C19.9972 16.4142 19.6614 16.75 19.2472 16.75H11.75C11.3358 16.75 11 16.4142 11 16ZM8.99997 9.41421L8.99997 14.5858C8.99997 15.4767 7.92283 15.9229 7.29286 15.2929L4.70708 12.7071C4.31655 12.3166 4.31655 11.6834 4.70708 11.2929L7.29287 8.7071C7.92283 8.07714 8.99997 8.52331 8.99997 9.41421Z"
        fill={fill}
      />
    </svg>
  );
}
