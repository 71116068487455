import styled from 'styled-components';

export interface SpinnerProps {
  width?: number;
  strokeColor?: string;
  strokeWidth?: number;
  animationDurationSec?: number;
}

export const Spinner = ({
  width = 48,
  strokeColor = '#0F0F0F',
  strokeWidth = 3,
  animationDurationSec = 1,
}: SpinnerProps) => {
  return (
    <SpinnerSvg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      stroke={strokeColor}
      viewBox={`0 0 ${width} ${width}`}
      animationDurationSec={animationDurationSec}
    >
      <g className="spinner">
        <circle cx={`${width / 2}`} cy={`${width / 2}`} r={`${0.375 * width}`} fill="none" strokeWidth={strokeWidth} />
      </g>
    </SpinnerSvg>
  );
};

const SpinnerSvg = styled.svg<Pick<SpinnerProps, 'width' | 'animationDurationSec'>>`
  @keyframes spinner-momentum {
    100% {
      transform: rotate(360deg);
    }
  }

  ${({ width }) =>
    width &&
    `
    @keyframes spinner-circle {
      0% {
        stroke-dasharray: 0 ${6.25 * width};
        stroke-dashoffset: 0;
      }

      47.5% {
        stroke-dasharray: ${1.75 * width} ${6.25 * width};
        stroke-dashoffset: -${width / 1.5};
      }

      95%,
      100% {
        stroke-dasharray: ${1.75 * width} ${6.25 * width};
        stroke-dashoffset: -${2.5 * width};
      }
    }
  `};

  ${({ animationDurationSec }) =>
    animationDurationSec &&
    `
  .spinner {
    transform-origin: center;
    animation: spinner-momentum ${animationDurationSec + 0.5}s linear infinite;
  }

  .spinner circle {
    strokeLinecap: round;
    animation: spinner-circle ${animationDurationSec}s ease-in-out infinite;
  }
  `}
`;
