import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88055 4C7.52543 4 13.2138 4 13.2138 4C13.2138 4 13.2138 9.68844 13.2138 13.3333C13.2138 13.7696 12.8805 14 12.5472 14C12.2138 14 11.8805 13.7696 11.8805 13.3333C11.8805 10.5997 11.8805 6.33333 11.8805 6.33333C11.8805 6.33333 7.46823 10.7456 4.21384 14C3.55846 14.6554 2.55845 13.6554 3.21384 13C6.46827 9.74571 10.8805 5.33333 10.8805 5.33333C10.8805 5.33333 6.61419 5.33333 3.88055 5.33333C3.21383 5.33333 3.21383 4.66667 3.21383 4.66667C3.21383 4.66667 3.21383 4 3.88055 4Z"
        fill={fill}
      />
    </svg>
  );
}
