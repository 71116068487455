import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.4989 4C40.3273 4 40.9989 4.67157 40.9989 5.5V19.2024C40.9989 26.6582 34.9547 32.7024 27.4989 32.7024H12.2111L21.2342 41.4208C21.8299 41.9965 21.8463 42.9461 21.2706 43.5419C20.695 44.1376 19.7454 44.1539 19.1496 43.5783L7.4577 32.2811C7.16519 31.9984 7 31.6091 7 31.2024C7 30.7956 7.16519 30.4063 7.4577 30.1237L19.1496 18.8265C19.7454 18.2508 20.695 18.2671 21.2706 18.8629C21.8463 19.4587 21.8299 20.4083 21.2342 20.9839L12.2111 29.7024H27.4989C33.2979 29.7024 37.9989 25.0014 37.9989 19.2024V5.5C37.9989 4.67157 38.6704 4 39.4989 4Z"
        fill={fill}
      />
    </svg>
  );
}
