import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3334 11.1666C12.5845 11.1666 11.1667 12.5844 11.1667 14.3333V26.4712C11.1667 28.2201 12.5845 29.6378 14.3334 29.6378H26.4713C28.2202 29.6378 29.6379 28.2201 29.6379 26.4712V14.3333C29.6379 12.5844 28.2202 11.1666 26.4713 11.1666H14.3334ZM14.1667 14.3333C14.1667 14.2412 14.2414 14.1666 14.3334 14.1666H26.4713C26.5633 14.1666 26.6379 14.2412 26.6379 14.3333V26.4712C26.6379 26.5632 26.5633 26.6378 26.4713 26.6378H14.3334C14.2414 26.6378 14.1667 26.5632 14.1667 26.4712V14.3333ZM20.4023 34.3734C15.3017 34.3734 11.1667 38.5083 11.1667 43.609C11.1667 48.7097 15.3017 52.8446 20.4023 52.8446C25.503 52.8446 29.6379 48.7097 29.6379 43.609C29.6379 38.5083 25.503 34.3734 20.4023 34.3734ZM14.1667 43.609C14.1667 40.1652 16.9585 37.3734 20.4023 37.3734C23.8462 37.3734 26.6379 40.1652 26.6379 43.609C26.6379 47.0528 23.8462 49.8446 20.4023 49.8446C16.9585 49.8446 14.1667 47.0528 14.1667 43.609ZM34.3727 20.4022C34.3727 15.3015 38.5076 11.1666 43.6083 11.1666C48.709 11.1666 52.8439 15.3015 52.8439 20.4022C52.8439 25.5029 48.709 29.6378 43.6083 29.6378C38.5076 29.6378 34.3727 25.5029 34.3727 20.4022ZM43.6083 14.1666C40.1645 14.1666 37.3727 16.9584 37.3727 20.4022C37.3727 23.846 40.1645 26.6378 43.6083 26.6378C47.0521 26.6378 49.8439 23.846 49.8439 20.4022C49.8439 16.9584 47.0521 14.1666 43.6083 14.1666ZM37.5394 34.3734C35.7905 34.3734 34.3727 35.7912 34.3727 37.5401V49.6779C34.3727 51.4268 35.7905 52.8446 37.5394 52.8446H49.6773C51.4262 52.8446 52.8439 51.4268 52.8439 49.6779V37.5401C52.8439 35.7912 51.4262 34.3734 49.6773 34.3734H37.5394ZM37.3727 37.5401C37.3727 37.448 37.4473 37.3734 37.5394 37.3734H49.6773C49.7693 37.3734 49.8439 37.448 49.8439 37.5401V49.6779C49.8439 49.77 49.7693 49.8446 49.6773 49.8446H37.5394C37.4473 49.8446 37.3727 49.77 37.3727 49.6779V37.5401Z"
      />
    </svg>
  );
}
