import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11119 2.17274C7.43001 1.90831 7.90283 1.9524 8.16726 2.27122L11.5116 6.30339C12.3209 7.27924 12.3209 8.72333 11.5116 9.69917L8.16726 13.7313C7.90283 14.0502 7.43001 14.0943 7.11119 13.8298C6.79236 13.5654 6.74827 13.0926 7.01271 12.7737L10.357 8.74158C10.7058 8.32105 10.7058 7.68151 10.357 7.26099L7.01271 3.22882C6.74827 2.91 6.79236 2.43717 7.11119 2.17274Z"
        fill={fill}
      />
    </svg>
  );
}
