import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.5" stroke={fill} />
      <path
        d="M13.0002 16C13.0002 16.5523 12.5525 17 12.0002 17C11.448 17 11.0002 16.5523 11.0002 16C11.0002 15.4477 11.448 15 12.0002 15C12.5525 15 13.0002 15.4477 13.0002 16Z"
        fill={fill}
      />
      <path d="M12 13V7" stroke={fill} />
    </svg>
  );
}
