import { ReactNode } from 'react';
import styled from 'styled-components';
import { useMedia } from '../../hooks/useMedia';
import { NAVIGATION_WITH_PROGRESS_HEIGHT } from '../NavigationWrapper/Navigation.styles';
import { spacings } from 'spacings';

export const CONTAINER_DEFAULT_WIDTH = 640;
export const CONTAINER_MINIMUM_PAGE_PADDINGS = 64;

export interface ContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
  centerContent?: boolean;
  verticalCenterContent?: boolean;
  navigationGap?: boolean;
  spacing?: keyof typeof spacings;
}

export default function Container({
  children,
  spacing,
  fullWidth,
  centerContent,
  verticalCenterContent,
  navigationGap,
  ...restComponentProps
}: ContainerProps) {
  const isTablet = useMedia('tablet');
  const isMobile = useMedia('mobile');

  return (
    <ContainerWrapper
      isMobile={isMobile || isTablet}
      spacing={spacing}
      fullWidth={fullWidth}
      centerContent={centerContent}
      verticalCenterContent={verticalCenterContent}
      navigationGap={navigationGap}
      {...restComponentProps}
    >
      {children}
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.section<
  ContainerProps & {
    isMobile: boolean;
  }
>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ centerContent }) => (centerContent ? 'center' : 'initial')};
  gap: ${({ spacing }) => `${Number(spacing || 0) * 8}px`};
  width: ${({ isMobile, fullWidth }) => (fullWidth || isMobile ? '100%' : `${CONTAINER_DEFAULT_WIDTH}px`)};
  margin: 0 auto;
  padding: 0
    ${({ theme, isMobile, fullWidth }) => (fullWidth ? theme.spacings['4'] : isMobile ? theme.spacings['2'] : 0)};
  ${({ navigationGap }) => (navigationGap ? `padding-top: ${NAVIGATION_WITH_PROGRESS_HEIGHT}` : '')};
  ${({ verticalCenterContent, navigationGap }) =>
    verticalCenterContent && navigationGap ? `padding-bottom: ${NAVIGATION_WITH_PROGRESS_HEIGHT};` : ''};
  ${({ verticalCenterContent }) => (verticalCenterContent ? 'justify-content: center; height: 100vh;' : '')};
  box-sizing: border-box;
`;
