import styled from 'styled-components';

export const NAVIGATION_WITH_PROGRESS_HEIGHT = '104px';

export const NavigationWrapper = styled.nav<{
  horizontalPaddings?: string;
  verticalPaddings?: string;
}>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${NAVIGATION_WITH_PROGRESS_HEIGHT};
  padding: ${({ horizontalPaddings, verticalPaddings }) => `${verticalPaddings} ${horizontalPaddings}`};
  /* When changing paddings, border etc, remember to adjust NAVIGATION_WITH_PROGRESS_HEIGHT const */
  background-color: ${({ theme }) => theme.color.background.primaryBg};
  z-index: 1;
`;

export const NavigationActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[4]};
`;

export const NavigationProgressBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.background.tertiaryBg};
`;

export const NavigationProgressBarStyled = styled.div<{ progress: string }>`
  width: ${({ progress }) => progress};
  height: 100%;
  background-color: ${({ theme }) => theme.color.border.positiveNormalBorder};
  transition-property: width;
  transition-duration: 0.5s;
`;
