const BASE_SQUARE = 8;

export const baseToPx = (times: number) => `${BASE_SQUARE * times}px`;
/**
 * baseToPxple of the 8
 */

export const spacing = {
  s0: baseToPx(0.5),
  s1: baseToPx(1),
  s1_5: baseToPx(1.5),
  s2: baseToPx(2),
  s3: baseToPx(3),
  s4: baseToPx(4),
  s5: baseToPx(5),
  s6: baseToPx(6),
  s8: baseToPx(8),
  s12: baseToPx(12),
  s16: baseToPx(16),
  s24: baseToPx(24),
};

export const spacings = {
  '0.5': baseToPx(0.5),
  '1': baseToPx(1),
  '1.5': baseToPx(1.5),
  '2': baseToPx(2),
  '3': baseToPx(3),
  '4': baseToPx(4),
  '5': baseToPx(5),
  '6': baseToPx(6),
  '8': baseToPx(8),
  '12': baseToPx(12),
  '16': baseToPx(16),
  '24': baseToPx(24),
};
