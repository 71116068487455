import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7509 4.75C12.7509 4.33579 12.4151 4 12.0009 4C11.5867 4 11.2509 4.33579 11.2509 4.75L11.2509 11.2501H4.75006C4.33585 11.2501 4.00006 11.5858 4.00006 12.0001C4.00006 12.4143 4.33585 12.7501 4.75006 12.7501H11.2509L11.2509 19.2511C11.2509 19.6653 11.5867 20.0011 12.0009 20.0011C12.4151 20.0011 12.7509 19.6653 12.7509 19.2511V12.7501H19.2512C19.6654 12.7501 20.0012 12.4143 20.0012 12.0001C20.0012 11.5858 19.6654 11.2501 19.2512 11.2501H12.7509V4.75Z"
        fill={stroke}
      />
    </svg>
  );
}
