import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.2125 9.06066C40.7983 8.47487 40.7983 7.52513 40.2125 6.93934C39.6267 6.35355 38.677 6.35355 38.0912 6.93934L23.5676 21.4629L9.04447 6.93983C8.45869 6.35404 7.50894 6.35404 6.92315 6.93983C6.33737 7.52561 6.33737 8.47536 6.92315 9.06115L21.4463 23.5843L6.92274 38.1078C6.33695 38.6936 6.33695 39.6433 6.92274 40.2291C7.50853 40.8149 8.45827 40.8149 9.04406 40.2291L23.5676 25.7056L38.0916 40.2296C38.6774 40.8154 39.6271 40.8154 40.2129 40.2296C40.7987 39.6438 40.7987 38.694 40.2129 38.1083L25.6889 23.5843L40.2125 9.06066Z"
        fill={fill}
      />
    </svg>
  );
}
