import { IconSVGProps } from '../types';

export default function SVGComponent({ fill, stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.83987 17.7864L3.82162 14.2419C2.72108 12.9499 2.72108 11.0501 3.82162 9.75805L6.83987 6.21358C7.49674 5.44161 8.45936 4.99687 9.47297 4.99707H17.5453C19.4554 4.99707 21.0038 6.54547 21.0038 8.45551V15.5445C21.0038 17.4545 19.4554 19.0029 17.5453 19.0029H9.47297C8.45936 19.0031 7.49674 18.5584 6.83987 17.7864Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2814 10.2852C16.5743 9.99226 16.5743 9.51739 16.2814 9.22449C15.9885 8.9316 15.5136 8.9316 15.2207 9.22449L13.5004 10.9448L11.78 9.22449C11.4871 8.9316 11.0123 8.9316 10.7194 9.22449C10.4265 9.51738 10.4265 9.99226 10.7194 10.2852L12.4397 12.0055L10.7194 13.7258C10.4265 14.0187 10.4265 14.4936 10.7194 14.7865C11.0123 15.0794 11.4871 15.0794 11.78 14.7865L13.5004 13.0662L15.2207 14.7865C15.5136 15.0794 15.9885 15.0794 16.2814 14.7865C16.5743 14.4936 16.5743 14.0187 16.2814 13.7258L14.561 12.0055L16.2814 10.2852Z"
        fill={fill}
      />
    </svg>
  );
}
