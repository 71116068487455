import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11847 20.1205C11.7342 21.849 16.1957 20.9936 18.8905 17.7816C22.0847 13.9743 21.5893 8.30019 17.7819 5.10596L15.2514 8.12091C18.2253 10.6158 18.6126 15.05 16.1177 18.0239C14.1278 20.3956 10.8501 21.1866 8.11847 20.1191"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11847 20.1208C11.7342 21.8493 16.1957 20.9939 18.8905 17.7819C19.2203 17.3891 19.5118 16.9758 19.7636 16.5475C20.1892 13.902 19.2531 11.106 17.0511 9.25847C16.4914 8.78905 15.8865 8.41133 15.2528 8.12256C18.2267 10.6175 18.614 15.0516 16.1177 18.0255C14.1278 20.3972 10.8501 21.1883 8.11847 20.1208Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8783 3.87592C12.2625 2.14742 7.80097 3.00277 5.10627 6.2148C1.91066 10.0208 2.40745 15.6962 6.2148 18.8905L8.74528 15.8755C5.77139 13.3806 5.38272 8.94646 7.87898 5.97256C9.86888 3.60084 13.1466 2.80844 15.8783 3.87592Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8782 3.87592C12.2625 2.14742 7.80093 3.00277 5.10622 6.2148C4.7764 6.60758 4.4849 7.02088 4.23308 7.44925C3.80745 10.0947 4.74355 12.8907 6.94558 14.7382C7.50532 15.2076 8.11023 15.5854 8.74387 15.8741C5.76998 13.3792 5.38267 8.94509 7.87894 5.97119C9.86883 3.60084 13.1466 2.80844 15.8782 3.87592Z"
        fill={fill}
      />
    </svg>
  );
}
