import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M32.0398 60.9995L31.4423 60.5794C31.3646 60.5248 23.53 55.0396 15.8065 47.3911C11.2519 42.8812 7.61667 38.5055 5.00513 34.3825C1.68492 29.1468 0.00183105 24.2889 0.00183105 19.9422C0.00183105 15.3361 1.68158 11.0458 4.73102 7.86325C7.73952 4.7238 11.7233 2.99457 15.9485 2.99457C19.5236 2.99457 22.8338 3.8538 25.7863 5.54905C28.2299 6.95183 30.315 8.8592 32.0022 11.2306C33.6887 8.8592 35.7737 6.95183 38.2173 5.54905C41.1706 3.8538 44.48 2.99457 48.0551 2.99457C52.2803 2.99457 56.2641 4.7238 59.2726 7.86325C62.322 11.0458 64.0018 15.3361 64.0018 19.9422C64.0018 24.2889 62.3229 29.1468 59.0118 34.3825C56.4053 38.5022 52.7809 42.8787 48.2372 47.3911C40.533 55.0388 32.7184 60.5239 32.6399 60.5786L32.0398 60.9995ZM15.9469 5.06517C12.2974 5.06517 8.84932 6.56571 6.23862 9.29088C3.55771 12.0881 2.08104 15.8739 2.08104 19.9488C2.08104 23.8978 3.65465 28.382 6.76092 33.2789C9.28639 37.2651 12.818 41.5165 17.2572 45.9146C23.6311 52.2299 30.0826 57.0472 32.0415 58.4666C33.997 57.0464 40.431 52.2299 46.7873 45.9162C51.2165 41.5182 54.7381 37.2668 57.2577 33.2805C60.3498 28.3837 61.9226 23.8978 61.9226 19.9488C61.9192 15.8722 60.4409 12.0865 57.7667 9.29088C55.1551 6.56488 51.7071 5.06517 48.0576 5.06517C44.8535 5.06517 41.8943 5.8316 39.2619 7.34208C36.6729 8.82357 34.5344 10.948 32.8922 13.6566L32.0006 15.1315L31.108 13.6566C29.4692 10.9489 27.3274 8.82357 24.7425 7.3396C22.1101 5.82911 19.1509 5.06517 15.9469 5.06517Z"
      />
    </svg>
  );
}
