import styled, { createGlobalStyle } from 'styled-components';
import { fontFaceNames, fontSize, fontWeight, letterSpacing } from '../../../typography';
import { baseToPx, spacing } from '../../../spacings';
import { InputIndicatorsContainer } from '../../Inputs/Input/InputBase/InputBase.styles';
import 'react-datepicker/dist/react-datepicker.css';
import { minSupportedWidth } from '../../../media';

const datePickerMaxHeight = '360px';
const datePickerDayBlockDimension = baseToPx(5.5);

export const DatePickerStyles = createGlobalStyle`
.vault-date-picker {
  display: block;

  &-popper.react-datepicker-popper {
    padding: 0;
  }

  &-calendar {
    background-color: ${({ theme }) => theme.color.background.primaryBg};
    border: 0;
    border-radius: ${baseToPx(0.5)} ${baseToPx(0.5)} ${baseToPx(1)} ${baseToPx(1)};
    padding: ${spacing.s0};
    display: flex;
    flex-direction: row;
    box-shadow: ${({ theme }) =>
      `0 ${theme.spacings['0.5']} ${theme.spacings['1']} ${theme.color.base.opacityBlack['4']}`};

    .react-datepicker__header {
      padding: 0;
      border: 0;

      background-color: ${({ theme }) => theme.color.background.primaryBg};

      .react-datepicker__current-month {
        padding: ${spacing.s0} 0 0;

        color: ${({ theme }) => theme.color.primary};

        font-family: ${fontFaceNames.Roobert};
        font-size: ${fontSize.label.s};
        font-weight: ${fontWeight.semiBold};
        letter-spacing: ${letterSpacing.label.s};
        line-height: ${datePickerDayBlockDimension};
      }

      .react-datepicker__day-names {
        margin-bottom: 0;

        .react-datepicker__day-name {
          width: ${datePickerDayBlockDimension};
          padding: ${baseToPx(0.75)} 0;
          margin: 0;

          color: ${({ theme }) => theme.color.tertiary};
          
          font-family: ${fontFaceNames.Roobert};
          font-size: ${fontSize.label.s};
          font-weight: ${fontWeight.semiBold};
          letter-spacing: ${letterSpacing.label.s};
        }
      }
    }

    .react-datepicker__header--custom {
      .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
        top: ${baseToPx(1.25)};
      }
    }

    .react-datepicker__year {
      margin: 0;

      &-wrapper {
        max-width: calc(${minSupportedWidth} - ${baseToPx(1.5)});
      }

      .react-datepicker__year-text {
        width: 98px;
      }
    }

    .react-datepicker__month {
      margin: 0;

      &-container {
        display: flex;
        flex-direction: column;
      }

      .react-datepicker__month-wrapper {
        margin: 0 1px;
      }

      .react-datepicker__month-text {
        width: 98px;
      }
    }

    .react-datepicker__week {
      position: relative;
      z-index: 1;
    }

    .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
      position: relative;
      
      width: calc(${datePickerDayBlockDimension} - ${baseToPx(0.5)});
      height: calc(${datePickerDayBlockDimension} - ${baseToPx(0.5)});
      margin: ${baseToPx(0.25)};

      color: ${({ theme }) => theme.color.primary};

      font-family: ${fontFaceNames.Roobert};
      font-size: ${fontSize.label.s};
      font-weight: ${fontWeight.normal};
      letter-spacing: ${letterSpacing.label.s};
      line-height: calc(${datePickerDayBlockDimension} - ${baseToPx(0.5)});

      box-sizing: border-box;

      &:hover {
        border-radius: ${baseToPx(2.5)};
      }

      &--outside-month {
        visibility: hidden;
        pointer-events: none;
      }
      
      &--disabled {
        color: ${({ theme }) => theme.color.base.grey[8]};
        pointer-events: none;
      }

      &.react-datepicker__day--in-range:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
        background-color: transparent;
        border-radius: 0;
        width: ${datePickerDayBlockDimension};
        margin: ${baseToPx(0.25)} 0;

        &::before {
          content: '';

          position: absolute;
          top: ${baseToPx(0.25)};
          left: 0;

          width: 100%;
          height: calc(${datePickerDayBlockDimension} - ${baseToPx(1)});

          background-color: ${({ theme }) => theme.color.background.tertiaryBg};
          z-index: -1;
        }

        &:last-child::before {
          border-radius: 0 ${baseToPx(1)} ${baseToPx(1)} 0;
        }

        &:first-child::before {
          border-radius: ${baseToPx(1)} 0 0 ${baseToPx(1)};
        }
      }
      
      &.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
      &.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
      &.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
      &.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        &:not(.react-datepicker__day--selected) {
          width: ${datePickerDayBlockDimension};
          margin: ${baseToPx(0.25)} 0;
          background-color: transparent;
        }

        &::before {
          content: '';

          position: absolute;
          top: ${baseToPx(0.25)};
          left: 0;
          z-index: -1;

          width: 0;
          height: calc(${datePickerDayBlockDimension} - ${baseToPx(1)});
          border-radius: ${baseToPx(1)};

          background-color: ${({ theme }) => theme.color.background.tertiaryBg};
        }

        &.react-datepicker__day--in-selecting-range::before {
          width: 100%;
          border-radius: 0;
        }

        &:first-child::before, &.react-datepicker__day--selecting-range-start::before {
          border-radius: ${baseToPx(1)} 0px 0px ${baseToPx(1)};
        }

        &:first-child:hover::before {
          border-radius: ${baseToPx(1)};
        }

        &:last-child::before, &:hover::before{
          border-radius: 0 ${baseToPx(1)} ${baseToPx(1)} 0;
        }

        &.react-datepicker__day--selecting-range-start {
          &::before {
            width: 50%;
            left: calc(50% + ${baseToPx(0.25)});
          }

          &:hover::before {
            width: 0;
          }
        }
      }

      &.react-datepicker__day--keyboard-selected,
      &.react-datepicker__day--range-start,
      &.react-datepicker__day--range-end,
      &.react-datepicker__day--selected {
        border-radius: 100%;

        background-color: ${({ theme }) => theme.color.background.invertedSecondaryBg};
        color: ${({ theme }) => theme.color.invertedPrimary};
        
        &.selects-range:not(.react-datepicker__day--in-selecting-range) {
          &::before {
            content: '';

            position: absolute;
            top: ${baseToPx(0.25)};
            left: 0;
            z-index: -1;

            width: 0;
            height: calc(${datePickerDayBlockDimension} - ${baseToPx(1)});

            background-color: ${({ theme }) => theme.color.background.tertiaryBg};
          }
          
          &.react-datepicker__day--range-start::before {
            left: calc(50% + ${baseToPx(0.25)});
            width: 50%;
          }
          
          &.react-datepicker__day--range-end::before {
            left: -${baseToPx(0.25)};
            width: 50%;
          }

          &.react-datepicker__day--range-start.react-datepicker__day--range-end::before {
            width: 0;
          }
        }
      }
    }

    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--keyboard-selected {
      &, &:hover {
        border-radius: ${baseToPx(2.5)};

        background-color: ${({ theme }) => theme.color.background.invertedSecondaryBg};
        color: ${({ theme }) => theme.color.invertedPrimary};
      }
    }

    .react-datepicker__month--disabled {
      color: ${({ theme }) => theme.color.base.grey[8]};
    }

    .react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month--selected) {
      background-color: transparent;
      color: ${({ theme }) => theme.color.primary};
      &:hover {
        background-color: ${({ theme }) => theme.color.base.grey[14]};
      }
    }

    .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
      top: ${baseToPx(1.75)};

      &:hover .react-datepicker__navigation-icon {
        background: ${({ theme }) => theme.color.background.tertiaryBg};
        &::after, &::before {
          background: ${({ theme }) => theme.color.background.tertiaryBg};
        }
      }
    }

    .react-datepicker__navigation--toggle-mode {
      border: none;
      background: none;
      cursor: pointer;
    }

    .react-datepicker__navigation--previous {
      margin-left: ${baseToPx(0.5)};
    }

    .react-datepicker__navigation--next {
      margin-right: ${baseToPx(0.5)};
    }

    .react-datepicker__navigation-icon {
      height: ${baseToPx(0.125)};
      width: ${baseToPx(1.875)};
      background: ${({ theme }) => theme.color.background.invertedPrimaryBg};
      transition: background-color 0.15s ease-in-out;

      &.react-datepicker__navigation-icon--previous {
        transform: rotate(180deg);
      }

      &::before, &::after {
        content: "";

        position: absolute;
        left: auto;
        bottom: auto;

        width: ${baseToPx(1.25)};
        height: ${baseToPx(0.125)};
        border: 0;

        background: ${({ theme }) => theme.color.background.invertedPrimaryBg};

        transition: background-color 0.15s ease-in-out;
      }

      &::before {
        right: -${baseToPx(0.125)};
        top: ${baseToPx(0.5)};

        transform: rotate(-45deg);
      }
    
      &::after {
        right: -${baseToPx(0.125)};
        top: -${baseToPx(0.5)};

        transform: rotate(45deg);
      }
    }

    &.with-range-addon {
      max-height: ${datePickerMaxHeight};

      .react-datepicker__navigation--next {
        right: 168px;
      }
    }
  }
}
`;

export const DateInputWrapper = styled.div`
  ${InputIndicatorsContainer} {
    pointer-events: none;
  }
`;
