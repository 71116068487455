import Button from '../Button/Button';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DropdownContent } from './DropdownContent';
import { DropdownOptionProps } from './DropdownOption';

export interface DropdownProps {
  /** Set gap between dropdown items */
  gap?: number;
  /** Set max-width of dropdown component */
  maxWidth?: number;
  /** Label used in default trigger button */
  label?: string;
  /** Changes simple dropdown into option selectable one */
  isSelectable?: boolean;
  /** Method tiggered on option select */
  onOptionChosen?: (value: string) => void;
  /** Possibility to pass custom element to trigger dropdown */
  customTriggerEl?: ReactNode;
  children: ReactNode;
}

export const Dropdown = ({
  children,
  gap,
  maxWidth,
  label,
  isSelectable,
  onOptionChosen,
  customTriggerEl,
  ...props
}: DropdownProps) => {
  const dropdownBody = useRef<HTMLDivElement>(null);
  const dropdownTrigger = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [chosenValue, setChosenValue] = useState<string | undefined>(undefined);
  const onOptionSelect = (value: string) => {
    setChosenValue(value);
    onOptionChosen && onOptionChosen(value);
  };

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (
        isExpanded &&
        dropdownBody.current &&
        dropdownTrigger.current &&
        !dropdownTrigger.current.contains(e.target as Node) &&
        !dropdownBody.current.contains(e.target as Node)
      ) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, [isExpanded]);

  return (
    <DropdownWrapper {...props}>
      <DropdownTriggerWrapper ref={dropdownTrigger} onClick={() => setIsExpanded(!isExpanded)}>
        {customTriggerEl ?? (
          <Button
            type="button"
            variant="tertiary"
            icon="chevronDown"
            label={label ?? undefined}
            ariaLabel={label ?? undefined}
          />
        )}
      </DropdownTriggerWrapper>
      <DropdownBodyWrapper>
        <DropdownBody gap={gap} maxWidth={maxWidth} isExpanded={isExpanded} ref={dropdownBody}>
          <DropdownContent
            isExpanded={isExpanded}
            isSelectable={isSelectable}
            chosenValue={chosenValue}
            onOptionSelect={onOptionSelect}
          >
            {children}
          </DropdownContent>
        </DropdownBody>
      </DropdownBodyWrapper>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownTriggerWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

const DropdownBodyWrapper = styled.div`
  position: relative;
`;

const DropdownBody = styled.div<{ isExpanded: boolean } & Pick<DropdownProps, 'gap' | 'maxWidth'>>`
  position: absolute;
  right: 0;
  display: grid;
  overflow: hidden;
  box-sizing: border-box;
  width: max-content;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none')};
  max-height: ${({ isExpanded }) => (isExpanded ? '100vh' : '0')};
  gap: ${({ gap, theme }) => (gap !== undefined ? `${gap}px` : theme.spacings['1'])};
  padding: ${({ theme, isExpanded }) => (isExpanded ? `${theme.spacings['0.5']} ${theme.spacings['1']}` : '0')};
  border-radius: ${({ theme }) =>
    `${theme.spacings['0.5']} ${theme.spacings['0.5']} ${theme.spacings['1']} ${theme.spacings['1']}`};
  background: ${({ theme }) => theme.color.background.primaryBg};
  box-shadow: ${({ theme, isExpanded }) =>
    isExpanded ? `0 ${theme.spacings['0.5']} ${theme.spacings['1']} ${theme.color.base.opacityBlack['4']}` : 'none'};
  transition: max-height 0.25s ease-in;
`;

export const DropdownItem = styled.div<{ value?: string; checked?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacings['1']};
  padding: ${({ theme }) => `${theme.spacings['1']} ${theme.spacings['1.5']}`};
  word-break: break-word;
`;

export const DropdownOptionItem = styled.div<Pick<DropdownOptionProps, 'checked'>>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings['1']};
  padding: ${({ theme }) => theme.spacings['1.5']};
  border-radius: ${({ theme }) => theme.spacings['0.5']};
  word-break: break-word;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.background.tertiaryBg};
  }

  ${DropdownItem} {
    padding: 0;
  }
`;
