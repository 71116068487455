import { IconSVGProps } from '../types';

export default function SVGComponent24hour({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6289 9.64711C19.8716 10.408 19.9969 11.2014 20.0005 12C20.0005 16.4183 16.4188 20 12.0005 20C7.58221 20 4.00049 16.4183 4.00049 12C4.00049 7.58172 7.58221 4 12.0005 4C12.7991 4.00361 13.5925 4.1289 14.3534 4.37156"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0083 5.13403L18.2404 6.90188L17.1066 5.76808"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.12152 10.8716C8.12152 10.1476 8.70837 9.56079 9.43229 9.56079C10.1562 9.56079 10.7431 10.1476 10.7431 10.8716C10.7431 12.5096 8.12152 12.5096 8.12152 14.4499H10.7431"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1458 14.4499V10.0143C15.1467 9.81212 15.0134 9.63387 14.8192 9.57768C14.6249 9.52148 14.417 9.60098 14.3099 9.77241C13.7523 10.6741 13.144 11.6977 12.6185 12.602C12.531 12.7534 12.5312 12.9399 12.619 13.0911C12.7068 13.2422 12.8688 13.3349 13.0436 13.3339H15.8883"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
