import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  TableOptions,
  SortingState,
  getSortedRowModel,
  SortDirection,
  Row,
  OnChangeFn,
  ColumnSort,
} from '@tanstack/react-table';
import Icon from '../../Icon/Icon';
import { Text } from '../../Text/Text';
import { useState } from 'react';
import styled, { css } from 'styled-components';

export type TableProps<T = unknown> = {
  onRowClick?: (row: Row<T>) => void;
  onSortingChange?: (sortingState: SortingState) => void;
  isForPrint?: boolean;
  testId?: string;
  columnSortState?: ColumnSort[];
} & Omit<TableOptions<T>, 'getCoreRowModel'>;

export default function TableV2<T>({
  data,
  columns,
  onSortingChange,
  onRowClick,
  manualSorting,
  enableSorting,
  testId,
  isForPrint = false,
  columnSortState,
}: TableProps<T>) {
  const [sortedData, setSortedData] = useState<SortingState>(columnSortState ? columnSortState : []);

  const onSortingStart: OnChangeFn<SortingState> = sortingState => {
    setSortedData(sortingState);
    onSortingChange && onSortingChange(sortingState);
  };

  const table = useReactTable({
    data,
    columns,
    state: { sorting: sortedData },
    onSortingChange: onSortingStart,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting,
    enableSorting,
  });

  return (
    <TableWrapper data-testid={testId}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <TableHeadCell key={header.id} colSpan={header.colSpan} isForPrint={isForPrint}>
                {header.isPlaceholder ? null : (
                  <TableHeadCellWrapper
                    isSortable={header.column.getCanSort()}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <Text
                      size={isForPrint ? 'xxs' : 's'}
                      color={isForPrint || !header.column.getCanSort() ? 'invertedTertiary' : 'linkNormal'}
                      weight={header.column.getIsSorted() ? 'semiBold' : 'light'}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Text>

                    {header.column.getIsSorted() && (
                      // When getIsSorted returns "true", the only type returned can be "SortDirection"
                      <OrderIconWrapper sortDirection={header.column.getIsSorted() as SortDirection}>
                        <Icon size="s" name="chevronBold" />
                      </OrderIconWrapper>
                    )}
                  </TableHeadCellWrapper>
                )}
              </TableHeadCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody data-testid={`${testId || 'table'}-body`}>
        {table.getRowModel().rows.map(row => (
          <TableRow isClickable={!!onRowClick} key={row.id} onClick={onRowClick ? () => onRowClick(row) : undefined}>
            {row.getVisibleCells().map(cell => (
              <TableCell key={cell.id} isForPrint={isForPrint}>
                <Text size={isForPrint ? 'xxs' : 's'} weight="light">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Text>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map(footerGroup => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map(footer => (
              <TableFootCell key={footer.id} colSpan={footer.colSpan} isForPrint={isForPrint}>
                {footer.isPlaceholder ? null : (
                  <Text size={isForPrint ? 'xxs' : 's'} weight="semiBold">
                    {flexRender(footer.column.columnDef.footer, footer.getContext())}
                  </Text>
                )}
              </TableFootCell>
            ))}
          </tr>
        ))}
      </tfoot>
    </TableWrapper>
  );
}

const getCellPadding = (isForPrint?: boolean) => {
  if (isForPrint) return '';

  return css`
    &:first-child {
      padding-left: ${({ theme }) => theme.spacings[1]};
    }

    &:last-child {
      padding-right: ${({ theme }) => theme.spacings[1]};
    }
  `;
};

const TableWrapper = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const TableCell = styled.td<{ isForPrint?: boolean }>`
  height: ${({ isForPrint }) => (isForPrint ? '28px' : '45px')};
  min-width: ${({ theme }) => theme.spacings[3]};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border.disabledBorder}`};

  ${({ isForPrint }) => getCellPadding(isForPrint)}
`;

const TableHeadCell = styled.th<{ isForPrint?: boolean }>`
  height: ${({ isForPrint }) => (isForPrint ? '29px' : '46px')};
  min-width: ${({ theme }) => theme.spacings[3]};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border.defaultNormalBorder};`};
  text-align: left;

  ${({ isForPrint }) => getCellPadding(isForPrint)}
`;

const TableHeadCellWrapper = styled.div<{ isSortable?: boolean }>`
  display: ${({ isSortable }) => (isSortable ? 'flex' : 'grid')};
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1]};
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'initial')};
  user-select: ${({ isSortable }) => (isSortable ? 'none' : 'auto')};
`;

const TableFootCell = styled.th<{ isForPrint?: boolean }>`
  padding: 0;
  min-width: ${({ theme }) => theme.spacings[3]};

  &:first-child > * {
    padding-left: ${({ theme, isForPrint }) => (isForPrint ? '0' : theme.spacings[1])};
  }

  &:last-child > * {
    padding-right: ${({ theme, isForPrint }) => (isForPrint ? '0' : theme.spacings[1])};
  }

  & > :not(:empty) {
    display: flex;
    height: ${({ isForPrint }) => (isForPrint ? '29px' : '46px')};
    border-top: ${({ theme }) => `1px solid ${theme.color.border.defaultNormalBorder};`};
    text-align: left;
    align-items: center;
  }
`;

const OrderIconWrapper = styled.span<{ sortDirection: SortDirection }>`
  height: 8px;
  display: inline-block;
  transform: ${({ sortDirection }) => (sortDirection === 'asc' ? 'rotateX(180deg)' : undefined)};
`;

const TableRow = styled.tr<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};

  &:hover {
    background-color: ${({ theme, isClickable }) => (isClickable ? theme.color.background.altBg : undefined)};
  }

  &:last-child td {
    border-bottom: none;
  }
`;
