import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.87207 2.99609V5.99734C4.87207 6.54986 5.31997 6.99776 5.87249 6.99776H17.8775C18.43 6.99776 18.8779 6.54986 18.8779 5.99734V2.99609"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8779 21.0036V18.0024C18.8779 17.4499 18.43 17.002 17.8775 17.002H5.87249C5.31997 17.002 4.87207 17.4499 4.87207 18.0024V21.0036"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99585 10.5351V13.4643C1.99585 13.8432 2.20994 14.1896 2.54887 14.3591C2.88779 14.5286 3.29337 14.492 3.59652 14.2646L5.54933 12.8C5.80124 12.6111 5.9495 12.3146 5.9495 11.9997C5.9495 11.6848 5.80124 11.3883 5.54933 11.1994L3.59652 9.73476C3.29337 9.5074 2.88779 9.47083 2.54887 9.64029C2.20994 9.80975 1.99585 10.1562 1.99585 10.5351Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0042 13.4643V10.5351C22.0042 10.1562 21.7901 9.80975 21.4512 9.64029C21.1122 9.47083 20.7067 9.5074 20.4035 9.73476L18.4507 11.1994C18.1988 11.3883 18.0505 11.6848 18.0505 11.9997C18.0505 12.3146 18.1988 12.6111 18.4507 12.8L20.4035 14.2646C20.7067 14.492 21.1122 14.5286 21.4512 14.3591C21.7901 14.1896 22.0042 13.8432 22.0042 13.4643Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
