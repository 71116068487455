import {
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  getGenderFactory,
  validateFactory
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetAgeFunc,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFunc,
  GetYearFunc,
  ValidateFunc
} from './interfaces'

const finland: CountryFunc = (personalNumber: string): CountryTools => {
  const format = '^([0-9]{2})([0-9]{2})([0-9]{2})([-+A])([0-9]{3})([0-9A-Y])$'

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  if (!matches) {
    return defaultTools()
  }

  /* tslint:disable:object-literal-sort-keys */
  const parts = {
    day: matches[1],
    month: matches[2],
    year: matches[3],
    centuryIndicator: matches[4],
    individualNumber: matches[5],
    checksum: matches[6],
    gender: matches[5]
  }
  /* tslint:enable:object-literal-sort-keys */

  const getYear: GetYearFunc = (): number => {
    let century = '19'

    /* tslint:disable:switch-default */
    switch (parts.centuryIndicator) {
      case '-':
        century = '19'
        break
      case '+':
        century = '18'
        break
      case 'A':
        century = '20'
        break
    }
    /* tslint:enable:switch-default */

    return parseInt(`${century}${parts.year}`, 10)
  }

  const localValidate: ValidateFunc = (): boolean => {
    const controlCharacter = '0123456789ABCDEFHJKLMNPRSTUVWXY'

    const num: number = parseInt(`${parts.day}${parts.month}${parts.year}${parts.individualNumber}`, 10)

    const remainder: number = num % 31

    return controlCharacter[remainder] === parts.checksum.toUpperCase()
  }

  const validate: ValidateFunc = validateFactory(personalNumber, regex, parts, getYear, localValidate)

  if (!validate()) {
    return defaultTools()
  }

  const getBirthday: GetBirthdayFunc = getBirthdayFactory(parts, getYear)

  const getAge: GetAgeFunc = getAgeFactory(getBirthday)

  const getGender: GetGenderFunc = getGenderFactory(parts)

  const getCensored: GetCensoredFunc = (): string => `${parts.day}${parts.month}${parts.year}${parts.centuryIndicator}****`

  const getCountry: GetCountryFunc = (): string => 'finland'

  return {
    getAge,
    getBirthday,
    getCensored,
    getCountry,
    getGender,
    getYear,
    validate
  }
}

export default finland
