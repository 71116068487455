import { spacings } from '../spacings';

import { _background, _border, _foreground } from './colors';
import baseColors from './colors/base';

const commonTheme = {
  spacings: spacings,
};

export const lightTheme = {
  ...commonTheme,
  name: 'light',
  color: {
    ..._foreground,
    base: baseColors,
    background: _background,
    border: _border,
  },
};

export type LoomisTheme = typeof lightTheme;
