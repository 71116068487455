import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M3.24804 14.5019C2.55769 14.5019 1.99805 13.9423 1.99805 13.2519L1.99806 8.23333C1.99806 7.81912 1.66227 7.48333 1.24806 7.48333C0.833844 7.48333 0.498056 7.81911 0.498056 8.23333L0.498047 13.2519C0.498045 14.7707 1.72925 16.0019 3.24803 16.0019L13.7523 16.002C15.2711 16.002 16.5023 14.7708 16.5023 13.252L16.5023 8.23333C16.5023 7.81912 16.1665 7.48333 15.7523 7.48333C15.3381 7.48333 15.0023 7.81912 15.0023 8.23333L15.0023 13.252C15.0023 13.9423 14.4427 14.502 13.7523 14.502L3.24804 14.5019Z"
      />
      <path
        fill={fill}
        d="M3.43672 5.32337C3.15912 5.01594 3.18331 4.54168 3.49074 4.26409L7.99683 0.195301C8.23645 -0.0210623 8.58085 -0.0558371 8.85415 0.0909782C8.91242 0.122243 8.96609 0.160986 9.01386 0.205924L13.5082 4.26409C13.8156 4.54168 13.8398 5.01594 13.5622 5.32337C13.2846 5.6308 12.8104 5.65499 12.5029 5.37739L9.25009 2.44024L9.25009 11.3312C9.25009 11.7454 8.9143 12.0812 8.50009 12.0812C8.08588 12.0812 7.75009 11.7454 7.75009 11.3312L7.75009 2.43911L4.496 5.37739C4.18857 5.65499 3.71431 5.6308 3.43672 5.32337Z"
      />
    </svg>
  );
}
