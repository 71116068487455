import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9996 5.5C10.9901 5.5 10.1717 6.31839 10.1717 7.32792V7.86758H13.8275V7.32792C13.8275 6.31839 13.0092 5.5 11.9996 5.5ZM8.67171 7.32792V7.86758H3.75006C3.33585 7.86758 3.00006 8.20337 3.00006 8.61758C3.00006 9.0318 3.33585 9.36758 3.75006 9.36758H5.64953V17.2543C5.64953 18.773 6.88075 20.0043 8.39953 20.0043H15.5977C17.1165 20.0043 18.3477 18.773 18.3477 17.2543V9.36758H20.2487C20.663 9.36758 20.9987 9.0318 20.9987 8.61758C20.9987 8.20337 20.663 7.86758 20.2487 7.86758H15.3275V7.32792C15.3275 5.48996 13.8376 4 11.9996 4C10.1617 4 8.67171 5.48996 8.67171 7.32792ZM16.8477 9.36758H7.14953V17.2543C7.14953 17.9446 7.70917 18.5043 8.39953 18.5043H15.5977C16.2881 18.5043 16.8477 17.9446 16.8477 17.2543V9.36758ZM10.4529 11.2519C10.8671 11.2519 11.2029 11.5877 11.2029 12.0019V15.8696C11.2029 16.2838 10.8671 16.6196 10.4529 16.6196C10.0387 16.6196 9.70288 16.2838 9.70288 15.8696V12.0019C9.70288 11.5877 10.0387 11.2519 10.4529 11.2519ZM14.2964 12.0019C14.2964 11.5877 13.9606 11.2519 13.5464 11.2519C13.1322 11.2519 12.7964 11.5877 12.7964 12.0019V15.8696C12.7964 16.2838 13.1322 16.6196 13.5464 16.6196C13.9606 16.6196 14.2964 16.2838 14.2964 15.8696V12.0019Z"
        fill={fill}
      />
    </svg>
  );
}
