import { IconSVGProps } from '../types';

export default function SVGComponentArrowLeft({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9411 4.5L4.5 12L10.9411 19.5M4.50314 12.0008H19.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
