import { i18nVault } from 'i18n';

const userLocale = i18nVault.language;
export const normalizedMinusSign = '-';
export const minusSignAlternatives = '−‒–—―﹣－᠆‐‑⁃˗➖⨪∸÷⁒';
export const minusSignNormalizationExpression = new RegExp(`[${minusSignAlternatives}]`, 'g');

/** Returns locale-based decimal sepator character to be used */
export const getLocaleDecimalSeparator = (locale: string = userLocale) =>
  Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

/** Returns locale-based grouping sepator character to be used */
export const getLocaleGroupingSeparator = (locale: string = userLocale) =>
  Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');

/** Normalize UTF-8 digits to ASCII digits for standarised math operations  */
export const normalizeDigits = (str: string) => {
  // find all characters which are DecimalNumber (property Nd), except for ASCII 0-9
  return str.replace(/(?![0-9])\p{Nd}/gu, g => {
    // all Nd blocks start at 0x...0 or end at 0x...F (and starts at 0x...6)
    // if it starts at 0x...0, the ASCII decimal number is (i & 0xf)
    // if it ends at 0x...F, the ASCII decimal number is (i & 0xf) - 6
    // we recognize the 2 cases by testing if code | 0xf == 0x...F is still a decimal number
    const code = g.charCodeAt(0);
    return `${(code & 0xf) - 6 * +/\p{Nd}/u.test(String.fromCodePoint(code | 0xf))}`;
  });
};

/** Format passed value to be presented with given fractional part length */
export const toFixedFractionalDigits = (
  value: number | string,
  fractionalDigits?: number,
  locale: string = userLocale
) => {
  const strValue = typeof value === 'number' ? toLocaleBasedString(value, locale) : value;
  if (strValue.length && strValue !== normalizedMinusSign && fractionalDigits !== undefined) {
    const decimalSeparator = getLocaleDecimalSeparator(locale);
    const separatorSplitValue = strValue.split(decimalSeparator);
    const localeBasedZero = (0).toLocaleString(locale);
    const fractionalFormattedPart = Array(fractionalDigits)
      .fill(null)
      .map((_, i) => (separatorSplitValue[1] ? separatorSplitValue[1][i] || localeBasedZero : localeBasedZero))
      .join('');
    return [separatorSplitValue[0], fractionalFormattedPart].join(
      fractionalFormattedPart.length ? decimalSeparator : ''
    );
  } else {
    return strValue;
  }
};

/** Format passed value to be presented as locale formatted string value */
export const toLocaleBasedString = (value?: number | string, locale: string = userLocale) => {
  return value === undefined || value === '' || Number.isNaN(+value)
    ? `${value || ''}`
    : (+value).toLocaleString(locale, {
        useGrouping: true,
        // set max possible significant digits amount
        maximumSignificantDigits: 21,
      });
};

/** Get number from locale formatted string value if possible, or same minus sign normalised string */
export const localeBasedStringValueToNumber = (
  value: string,
  fractionalDigits?: number,
  locale: string = userLocale
) => {
  const strValue = `${value}`;
  const parsedNumber = parseFloat(
    toFixedFractionalDigits(normalizeDigits(strValue), fractionalDigits, locale)
      .replace(minusSignNormalizationExpression, normalizedMinusSign)
      .replace(/\s/g, '')
      .replace(new RegExp('\\' + getLocaleDecimalSeparator(locale)), '|')
      .replace(new RegExp('\\' + getLocaleGroupingSeparator(locale), 'g'), '')
      .replace('|', '.')
  );

  return isNaN(parsedNumber) ? strValue.replace(minusSignNormalizationExpression, normalizedMinusSign) : parsedNumber;
};
