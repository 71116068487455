import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 18H6.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.505 15.005L6.5 15L6.505 14.995L6.51 15L6.505 15.005"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9H19C20.1046 9 21 9.89543 21 11V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V11C3 9.89543 3.89543 9 5 9H7"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99793 12C7.44565 12 7 11.5523 7 11V5.33141C7 4.95336 7.2132 4.60763 7.551 4.43789L8.21 4.10674C8.49049 3.96578 8.8209 3.96472 9.10229 4.10387L10.3382 4.71503H10.3334L11.5578 4.10551C11.8396 3.96522 12.171 3.96582 12.4523 4.10713L13.6619 4.71477L14.8978 4.10361C15.1792 3.96446 15.5096 3.96552 15.7901 4.10648L16.4491 4.43763C16.7869 4.60741 17 4.95313 17 5.33115V10.9999C17 11.5522 16.5544 11.9999 16.0021 11.9999C13.8803 12 10.1198 12 7.99793 12Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
