import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99919 4L8.32822 4.28755L13.9984 9.24297L13.3403 9.99594L7.99919 5.32807L2.65805 9.99594L2 9.24297L7.67016 4.28755L7.99919 4Z"
      />
    </svg>
  );
}
