import styled from 'styled-components';
import loadingGif from './assets/loomis-pay_loader_small.gif';
import { Spinner } from '../Spinner/Spinner';
import { Overlay, OverlayProps } from '../Overlay/Overlay';

export const loaderTypes = ['spinner', 'lp-loader', 'custom'] as const;
export type LoaderType = (typeof loaderTypes)[number];

export interface LoaderProps extends OverlayProps {
  type?: LoaderType;
}

export const Loader = ({ type = 'spinner', background, children, opacity, isFullScreen }: LoaderProps) => (
  <Overlay background={background} opacity={opacity} isFullScreen={isFullScreen}>
    {type === 'spinner' && <Spinner />}
    {type === 'lp-loader' && <LPLoaderAnimationImg src={loadingGif} />}
    {/* possibility to define own animation through children and custom type set */}
    {children && <div>{children}</div>}
  </Overlay>
);

const LPLoaderAnimationImg = styled.img`
  width: 100px;
`;
