import { BannerProps, Banner } from '../Banner';
import Icon from '../../Icon/Icon';
import type { MediumIcons } from '../../Icon/types';
import styled from 'styled-components';
import { ForegroundColors } from 'theme/colors';

type IconAlign = 'start' | 'center';

interface IconBannerContentProps {
  readonly iconAlign: IconAlign;
}

const IconBannerContent = styled.div<IconBannerContentProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => theme.spacings[1.5]};
  align-items: ${props => props.iconAlign};
`;

export interface BannerWithIconProps extends BannerProps {
  icon: MediumIcons;
  iconAlign?: IconAlign;
  iconColor?: ForegroundColors;
}

export const BannerWithIcon = ({
  icon,
  children,
  type,
  buttonType,
  roundCornersDiameter,
  autoCloseTimer,
  onClose,
  iconAlign = 'center',
  iconColor,
}: BannerWithIconProps) => (
  <Banner
    type={type}
    buttonType={buttonType}
    onClose={onClose}
    roundCornersDiameter={roundCornersDiameter}
    autoCloseTimer={autoCloseTimer}
  >
    <IconBannerContent iconAlign={iconAlign}>
      <Icon name={icon} size={'m'} fill={iconColor ? iconColor : type === 'negative' ? type : 'primary'} />
      <div>{children}</div>
    </IconBannerContent>
  </Banner>
);
