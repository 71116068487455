import { components, DropdownIndicatorProps, MultiValueProps, ClearIndicatorProps } from 'react-select';
import Icon from '../../Icon/Icon';
import { Text } from '../../../components/Text/Text';
import styled from 'styled-components';
import { SelectOption } from './types';
import { Dispatch, SetStateAction } from 'react';
import { baseToPx } from '../../../spacings';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="chevronDown" size="s" />
    </components.DropdownIndicator>
  );
};

export const MultiValue = (props: MultiValueProps) => {
  return props.selectProps.inputValue === '' ? (
    <Placeholder size="s" color="tertiary">
      {props.selectProps.placeholder}
    </Placeholder>
  ) : (
    <></>
  );
};

const Placeholder = styled(Text)`
  position: absolute;
  background: ${({ theme }) => theme.color.background.primaryBg};
`;

export const MultiSelectSelectedOptions = ({
  selectedValues,
  setSelectedValues,
}: {
  selectedValues: SelectOption[];
  setSelectedValues: Dispatch<SetStateAction<SelectOption[]>>;
}) =>
  selectedValues.length > 0 ? (
    <MultiSelectItemsWrapper>
      {selectedValues?.map((item, index) => (
        <MultiSelectItem key={index}>
          <MultiSelectItemLabel>
            <Text size="xs">{item.label}</Text>
          </MultiSelectItemLabel>
          <MultiSelectItemRemove
            type="button"
            onClick={() => setSelectedValues(selectedValues.filter(i => i.value !== item.value))}
          >
            <Icon name="close" size="s" />
          </MultiSelectItemRemove>
        </MultiSelectItem>
      ))}
    </MultiSelectItemsWrapper>
  ) : (
    <></>
  );

export const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <Icon size="m" name="closeCircled" color="tertiary" />
  </components.ClearIndicator>
);

const MultiSelectItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings['1']};
  margin-top: ${({ theme }) => theme.spacings['3']};
`;

const MultiSelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings['0.5']};
  border-radius: ${({ theme }) => theme.spacings['0.5']};
  background-color: ${({ theme }) => theme.color.background.tertiaryBg};
`;

const MultiSelectItemLabel = styled.div`
  padding: ${({ theme }) => `${baseToPx(0.75)} ${theme.spacings[1]}`};
  padding-right: 0;
`;

const MultiSelectItemRemove = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => `${baseToPx(0.75)} ${theme.spacings[1]} ${baseToPx(0.75)} ${theme.spacings[1]}`};
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.background.altBg};
  }
`;
