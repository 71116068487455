import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2514 1.99921C19.6657 1.99921 20.0014 2.33499 20.0014 2.74921L20.0015 7.38594L20.0015 11.5537C20.0015 14.6261 17.497 17.1091 14.4171 17.1091H6.29748L9.20423 20.7856C9.46113 21.1105 9.40598 21.5822 9.08105 21.8391C8.75613 22.096 8.28447 22.0408 8.02757 21.7159L4.16008 16.8242C3.94302 16.5497 3.9447 16.1615 4.16413 15.8889L8.03162 11.0833C8.29132 10.7606 8.76344 10.7095 9.08613 10.9692C9.40882 11.2289 9.45989 11.701 9.20019 12.0237L6.31472 15.6091H14.4171C16.6771 15.6091 18.5015 13.7892 18.5015 11.5537L18.5015 7.38596L18.5014 2.74921C18.5014 2.33499 18.8372 1.99921 19.2514 1.99921Z"
        fill={fill}
      />
    </svg>
  );
}
