import styled from 'styled-components';
import { Text } from 'components/Text/Text';

export const Indicator = ({ important = false, variant = 'square', value }: IndicatorProps) => {
  switch (variant) {
    case 'square': {
      return (
        <SquareIndicator important={important}>
          <Text size="xs" color={important ? 'invertedPrimary' : 'tertiary'}>
            {value >= 100 ? '99+' : value}
          </Text>
        </SquareIndicator>
      );
    }
    case 'circle': {
      return <CircleIndicator important={important} />;
    }
  }
};

export type IndicatorProps = {
  important?: boolean;
  variant?: 'square' | 'circle';
  value: number;
};

const SquareIndicator = styled.span<{
  important: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  padding: ${({ theme }) => theme.spacings['0.5']};
  min-width: ${({ theme }) => theme.spacings['2']};
  min-height: ${({ theme }) => theme.spacings['2']};
  border-radius: ${({ theme }) => theme.spacings['0.5']};
  background-color: ${({ theme, important }) => (important ? theme.color.negative : theme.color.invertedSecondary)};
`;

const CircleIndicator = styled.span<{
  important: boolean;
}>`
  padding: ${({ theme }) => theme.spacings['0.5']};
  width: ${({ theme }) => theme.spacings['0.5']};
  height: ${({ theme }) => theme.spacings['0.5']};
  border-radius: 50%;
  background-color: ${({ theme, important }) => (important ? theme.color.negative : theme.color.background.tertiaryBg)};
`;
