import baseColors from './base';

export type BackgroundColors = keyof typeof _background;

export const _background = {
  primaryBg: baseColors.grey.white,
  secondaryBg: baseColors.grey[15],
  altBg: baseColors.grey[14],
  tertiaryBg: baseColors.grey[13],
  positiveBg: baseColors.green[14],
  warningBg: baseColors.yellow[14],
  negativeBg: baseColors.red[14],
  infoBg: baseColors.blue[14],

  invertedPrimaryBg: baseColors.grey[1],
  invertedSecondaryBg: baseColors.grey[2],
  invertedAltBg: baseColors.grey[3],
  invertedTertiaryBg: baseColors.grey[4],
  invertedPositiveBg: baseColors.green[4],
  invertedWarningBg: baseColors.yellow[4],
  invertedNegativeBg: baseColors.red[4],
  invertedInfoBg: baseColors.blue[4],
};
