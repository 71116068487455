import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.106 5.86612C15.5942 5.37796 16.3856 5.37796 16.8738 5.86612L18.1326 7.12494C18.6207 7.61309 18.6207 8.40455 18.1326 8.8927L17.5226 9.50273L14.496 6.47614L15.106 5.86612ZM13.4353 7.5368L6.46266 14.5095L5.70602 18.2927L9.48925 17.536L16.4619 10.5634L13.4353 7.5368ZM18.0487 11.0979L10.3893 18.7573C10.2846 18.862 10.1513 18.9333 10.0061 18.9624L4.89704 19.9842C4.65114 20.0334 4.39694 19.9564 4.21962 19.7791C4.0423 19.6018 3.96534 19.3476 4.01452 19.1017L5.03633 13.9926C5.06537 13.8474 5.13673 13.7141 5.24143 13.6094L14.0453 4.80546C15.1193 3.73152 16.8605 3.73151 17.9344 4.80546L19.1932 6.06428C20.2672 7.13822 20.2672 8.87942 19.1932 9.95336L18.0571 11.0896C18.0557 11.091 18.0543 11.0923 18.0529 11.0937C18.0515 11.0951 18.0501 11.0965 18.0487 11.0979Z"
        fill={fill}
      />
    </svg>
  );
}
