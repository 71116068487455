export type devices = 'mobile' | 'desktop' | 'tablet' | 'wideScreen';

export const minSupportedWidth = 320;

export const breakpoints = {
  mobile: 480,
  tablet: 768,
  desktop: 1024,
  wideScreen: 1366,
};

export const mediaQueries = {
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  tablet: `(min-width: ${breakpoints.mobile + 1}px) and (max-width: ${breakpoints.desktop}px)`,
  desktop: `(min-width: ${breakpoints.desktop + 1}px)`,
  wideScreen: `(min-width: ${breakpoints.wideScreen + 1}px)`,
};
