import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 9.83945H7.50001L7.49999 9.83392L7.49996 9.81818C7.49996 7.47649 9.47084 5.5 12 5.5C14.5291 5.5 16.5 7.47648 16.5 9.81817L16.5 9.82882V13.2564C16.5 13.5835 16.5642 13.9074 16.6889 14.2097L17.2586 15.5909H6.74136L7.3111 14.2097C7.43582 13.9074 7.5 13.5835 7.5 13.2564V9.83945ZM18 9.81818C17.9999 6.60488 15.3137 4 12 4C8.68625 4 5.99996 6.60489 5.99996 9.81818L6 9.83945V13.2564C6 13.3872 5.97433 13.5168 5.92444 13.6377L5.0698 15.7096C4.79827 16.3678 5.28218 17.0909 5.99424 17.0909H9.00001V17.0989C9.00444 18.6942 10.3331 19.9876 11.9763 19.9999H12.0237C13.6696 19.9876 15 18.6899 15 17.0909L18.0058 17.0909C18.7178 17.0909 19.2017 16.3678 18.9302 15.7096L18.0756 13.6377C18.0257 13.5168 18 13.3872 18 13.2564V9.8182L18 9.81818ZM13.5 17.0909H10.5C10.5 17.826 11.1277 18.5 12 18.5C12.8723 18.5 13.5 17.826 13.5 17.0909Z"
        fill={fill}
      />
    </svg>
  );
}
