import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88582 2.17274C9.20464 2.43717 9.24873 2.91 8.9843 3.22882L5.63976 7.26126C5.29094 7.68184 5.29094 8.32145 5.63976 8.74202L8.9843 12.7745C9.24873 13.0933 9.20464 13.5661 8.88582 13.8305C8.567 14.095 8.09417 14.0509 7.82974 13.7321L4.4852 9.69962C3.67579 8.72372 3.67579 7.27956 4.4852 6.30367L7.82974 2.27122C8.09417 1.9524 8.567 1.90831 8.88582 2.17274Z"
        fill={fill}
      />
    </svg>
  );
}
