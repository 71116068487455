import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2777 5.00025H17.1466C17.5428 4.99325 17.9277 5.13195 18.2284 5.39002C18.5291 5.64809 18.7245 6.00758 18.7776 6.40023V11.3001C18.7245 11.6928 18.5291 12.0523 18.2284 12.3103C17.9277 12.5684 17.5428 12.7071 17.1466 12.7001H15.2777M10.3778 14.1001V16.9C10.3778 17.457 10.599 17.9911 10.9928 18.3849C11.3866 18.7788 11.9208 19 12.4777 19L15.2777 12.7001V5.00025H7.38181C7.04419 4.99644 6.71656 5.11477 6.45929 5.33344C6.20203 5.55212 6.03246 5.8564 5.98184 6.19023L5.01585 12.4901C4.9854 12.6908 4.99893 12.8956 5.05551 13.0905C5.1121 13.2854 5.21038 13.4657 5.34355 13.6188C5.47671 13.772 5.64159 13.8943 5.82674 13.9774C6.01189 14.0605 6.2129 14.1024 6.41583 14.1001H10.3778Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
