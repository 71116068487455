import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7285 5.54391C11.0128 5.84508 11.4873 5.8588 11.7885 5.57456C12.0897 5.29033 12.1034 4.81576 11.8192 4.51459L8.72408 1.23517C8.58242 1.08507 8.38516 1 8.17877 1C7.97238 1 7.77512 1.08507 7.63346 1.23517L4.53837 4.51459C4.25413 4.81576 4.26785 5.29032 4.56902 5.57456C4.87019 5.8588 5.34475 5.84508 5.62899 5.54391L8.17877 2.84227L10.7285 5.54391ZM5.62872 10.4564C5.34448 10.1552 4.86992 10.1415 4.56875 10.4257C4.26758 10.71 4.25386 11.1846 4.5381 11.4857L7.63318 14.7651C7.77484 14.9152 7.97211 15.0003 8.1785 15.0003C8.38489 15.0003 8.58215 14.9152 8.72381 14.7651L11.8189 11.4857C12.1031 11.1846 12.0894 10.71 11.7882 10.4258C11.4871 10.1415 11.0125 10.1552 10.7283 10.4564L8.1785 13.158L5.62872 10.4564Z"
      />
    </svg>
  );
}
