import { ReactNode, MouseEvent, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Paragraph } from '../Text/Text';
import { spacing } from '../../spacings';
import { LoomisTheme } from '../../theme';
import { useTheme } from '../../hooks/useTheme';
import Button from '../Button/Button';
import { ButtonVariants } from '../Button/types';

export const messageTypeArray = ['positive', 'neutral', 'warning', 'negative'] as const;
export type MessageType = (typeof messageTypeArray)[number];

enum BannerLayoutType {
  info = 'info',
  closeable = 'closeable',
}

export interface BannerProps {
  children: ReactNode;
  type?: MessageType;
  buttonType?: ButtonVariants;
  roundCornersDiameter?: number;
  autoCloseTimer?: number;
  onClose?: (event?: MouseEvent) => void;
}

const getBgColor = (type: MessageType, theme: LoomisTheme) => {
  switch (type) {
    case 'positive':
      return theme.color.background.positiveBg;
    case 'neutral':
      return theme.color.background.infoBg;
    case 'warning':
      return theme.color.background.warningBg;
    case 'negative':
      return theme.color.background.negativeBg;
  }
};

const getSecondaryColor = (type: MessageType, theme: LoomisTheme) => {
  switch (type) {
    case 'positive':
      return theme.color.base.green[6];
    case 'neutral':
      return theme.color.base.blue[6];
    case 'warning':
      return theme.color.base.yellow[6];
    case 'negative':
      return theme.color.base.red[6];
  }
};

export const Banner = ({
  children,
  type = 'neutral',
  buttonType,
  roundCornersDiameter,
  autoCloseTimer,
  onClose,
}: BannerProps) => {
  const theme = useTheme();
  const [render, setRender] = useState(true);
  const [countdown, setCountdown] = useState(false);

  const closeBanner = (event?: MouseEvent) => {
    if (onClose) onClose(event);
    setRender(false);
  };

  const startCountdown = () => {
    setTimeout(() => {
      setCountdown(true);
    }, 0);
  };

  useEffect(() => {
    if (autoCloseTimer && autoCloseTimer > 0) {
      startCountdown();
      const closeTimer = setTimeout(() => {
        if (render) closeBanner();
      }, autoCloseTimer);

      return () => {
        clearInterval(closeTimer);
      };
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [autoCloseTimer]);

  return render ? (
    <BannerContainer color={getBgColor(type, theme)} roundCornersDiameter={roundCornersDiameter}>
      <BannerLayout itemType={onClose ? BannerLayoutType.closeable : BannerLayoutType.info}>
        <BannerContent contentColor={type === 'negative' ? getSecondaryColor(type, theme) : undefined}>
          {typeof children === 'string' ? (
            <Paragraph size={'s'} noGutter>
              {children}
            </Paragraph>
          ) : (
            <>{children}</>
          )}
        </BannerContent>
        {onClose ? (
          <ButtonContainer>
            <Button
              variant={buttonType}
              icon="close"
              size="s"
              onClick={closeBanner}
              interactionFontColor={`${theme.color.base.grey.white}`}
              interactionBgColor={getSecondaryColor(type, theme)}
            />
          </ButtonContainer>
        ) : null}
      </BannerLayout>
      {autoCloseTimer && (
        <BannerTimebar
          color={getSecondaryColor(type, theme)}
          closeTimer={autoCloseTimer}
          className={countdown ? 'countdown' : ''}
        />
      )}
    </BannerContainer>
  ) : (
    <></>
  );
};

const BannerContainer = styled.div<{
  roundCornersDiameter?: number;
}>`
  width: 100%;
  background-color: ${props => props.color};
  ${({ roundCornersDiameter }) =>
    roundCornersDiameter && `border-radius: ${roundCornersDiameter}px; overflow: hidden;`};
`;

const BannerLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props =>
    ({ theme }) =>
      props.itemType === BannerLayoutType.info ? theme.spacings[3] : `${theme.spacings[2]} ${theme.spacings[3]}`};
`;

const BannerContent = styled.div<{
  contentColor?: string;
}>`
  display: block;
  ${({ contentColor }) => contentColor && `color: ${contentColor};`};
`;

const BannerTimebar = styled.div<{
  closeTimer: number;
}>`
  background-color: ${props => props.color};
  height: ${spacing.s0};
  width: 100%;
  transition: width ${({ closeTimer }) => closeTimer}ms linear;

  &.countdown {
    width: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-left: ${spacing.s2};
`;
