import { useFileInput } from '@rpldy/uploady';
import React, { ChangeEvent, ForwardedRef, RefObject } from 'react';
import styled from 'styled-components';

export type FileInputProps = {
  /**
   * string[] with list af the accepted file formats
   * ie: ['.jpg', '.csv' 'image/*', '.pdf']
   */
  acceptedTypes?: string[];
  name: string;
  testId?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
};
export const FileInput = React.forwardRef(
  ({ acceptedTypes, name, testId, onChange, onBlur }: FileInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const accept = acceptedTypes?.join(',');

    useFileInput(ref as RefObject<HTMLInputElement>);

    return (
      <Input
        type="file"
        accept={accept}
        name={name}
        ref={ref}
        aria-label="file-input"
        data-testid={testId}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
);

const Input = styled.input`
  display: none;
`;
