import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7V11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 9L12 7L10 9" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 21.0002H19C20.1046 21.0002 21 20.1048 21 19.0002V12.503C21 11.9321 20.676 11.4107 20.1641 11.158C19.6522 10.9052 19.0412 10.9649 18.588 11.312L13.2159 15.4257C12.4984 15.9751 11.5015 15.9751 10.784 15.4257L5.412 11.312C4.95876 10.9649 4.34779 10.9052 3.83591 11.158C3.32403 11.4107 2.99999 11.9321 3 12.503V19.0002C3 20.1048 3.89543 21.0002 5 21.0002Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11.0862V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V11.0862"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
