import {
  defaultIsDNumber,
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  getGenderFactory,
  validateFactory
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetAgeFunc,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFunc,
  GetYearFunc,
  IsDNumberFunc,
  ValidateFunc
} from './interfaces'
import {
  validateBitCheckSum
} from './utilities'

const norway: CountryFunc = (personalNumber: string): CountryTools => {
  const isDNumber: IsDNumberFunc = (): boolean => /^[4-7][0-9]{10}$/.test(personalNumber)

  const format = '^([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{3})([0-9])([0-9])$'

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  if (!matches) {
    return defaultTools({ isDNumber: defaultIsDNumber })
  }

  const getDay = (): string => {
    if (isDNumber()) {
      const newDay = parseInt(matches[1], 10) - 40

      // TODO padStart when TS can transpile it to es5
      return (newDay < 10 ? '0' : '') + newDay.toString()
    }

    return matches[1]
  }

  /* tslint:disable:object-literal-sort-keys */
  const parts = {
    day: getDay(),
    month: matches[2],
    year: matches[3],
    individualNumber: matches[4],
    gender: matches[4]
  }
  /* tslint:enable:object-literal-sort-keys */

  const getYear: GetYearFunc = (): number => {
    const individualNumber: number = parseInt(parts.individualNumber, 10)
    const year: number = parseInt(parts.year, 10)

    let century = 19

    if (individualNumber > 499) {
      if (individualNumber < 750 && year >= 54) {
        century = 18
      } else if (year < 40) {
        century = 20
      } else if (individualNumber >= 900 && year >= 40) {
        // Special cases
        century = 19
      }
    }

    return (century * 100) + year
  }

  const localValidate: ValidateFunc = (): boolean => (
    // Validates first checksum number
    validateBitCheckSum(personalNumber, [3, 7, 6, 1, 8, 9, 4, 5, 2, 1]) &&
    // Validates second checksum number
    validateBitCheckSum(personalNumber, [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1])
  )

  const validate: ValidateFunc = validateFactory(personalNumber, regex, parts, getYear, localValidate)

  if (!validate()) {
    return defaultTools({ isDNumber: defaultIsDNumber })
  }

  const getBirthday: GetBirthdayFunc = getBirthdayFactory(parts, getYear)

  const getAge: GetAgeFunc = getAgeFactory(getBirthday)

  const getGender: GetGenderFunc = getGenderFactory(parts)

  const getCensored: GetCensoredFunc = (): string => `${matches[1]}${parts.month}${parts.year}*****`

  const getCountry: GetCountryFunc = (): string => 'norway'

  return {
    getAge,
    getBirthday,
    getCensored,
    getCountry,
    getGender,
    getYear,
    isDNumber,
    validate
  }
}

export default norway
