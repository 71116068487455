import { ReactNode } from 'react';
import { Paragraph } from '../../../Text/Text';
import { Label } from '../../../Text/Label';
import { InputContainer, Container, Hint, LabelsWrapper } from './InputWrapper.styles';
import { VisuallyHidden } from '../../../VisuallyHidden/VisuallyHidden';
import { InputInfoLabel } from '../InputBase/InputBase.styles';
import Icon from '../../../Icon/Icon';

export type InputWrapperSharedProps = {
  errorText?: string;
  hintText?: string;
  warningText?: string;
  infoText?: string;
  required?: string;
  label?: string;
};

type InputWrapperProps = InputWrapperSharedProps & {
  children: ReactNode;
  active?: boolean;
  disabled?: boolean;

  focus?: boolean;
  inputId: string;
  visuallyHidden?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
};

export const InputWrapper = ({
  active = false,
  disabled = false,
  errorText,
  hintText,
  warningText,
  infoText,
  required,
  focus,
  inputId,
  label,
  children,
  visuallyHidden = false,
  onClick,
}: InputWrapperProps) => {
  const getClasses = () => {
    const focusClass = focus ? 'is-focus' : '';
    const activeClass = active ? 'is-active' : '';
    const disabledClass = disabled ? 'is-disabled' : '';
    const errorClass = !!errorText ? 'has-error' : '';
    const warningClass = !!warningText ? 'has-warning' : '';

    return [activeClass, disabledClass, errorClass, warningClass, focusClass].join(' ');
  };

  const LabelWrapper = (
    <LabelsWrapper>
      <label htmlFor={inputId}>
        <Label size="s">{label}</Label>
      </label>
      {(required || infoText) && <InputInfoLabel>{required || infoText}</InputInfoLabel>}
    </LabelsWrapper>
  );

  const HintWrapper = (
    <Hint>
      {errorText && <Icon name="negative" size="s" color="negative" />}
      {warningText && <Icon name="negative" size="s" color="warning" />}
      <Paragraph data-testid={errorText ? `${inputId}.error` : ''} size="s">
        {errorText || warningText || hintText}
      </Paragraph>
    </Hint>
  );

  return (
    <Container className={getClasses()}>
      {label && label.length > 0 && (
        <>{visuallyHidden ? <VisuallyHidden>{LabelWrapper}</VisuallyHidden> : LabelWrapper}</>
      )}
      <InputContainer onClick={onClick}>{children}</InputContainer>
      {(hintText || errorText || warningText) && (
        <>{visuallyHidden ? <VisuallyHidden>{HintWrapper}</VisuallyHidden> : HintWrapper}</>
      )}
    </Container>
  );
};
