import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C6.89543 3 6 3.89543 6 5V6H10V5C10 3.89543 9.10457 3 8 3ZM5 5V6H4H2.5H2V7H2.5H4V11.5C4 12.8807 5.11929 14 6.5 14H9.5C10.8807 14 12 12.8807 12 11.5V7H13.4986H13.9986V6H13.4986H12H11V5C11 3.34315 9.65685 2 8 2C6.34315 2 5 3.34315 5 5ZM11 7H10H6H5V11.5C5 12.3284 5.67157 13 6.5 13H9.5C10.3284 13 11 12.3284 11 11.5V7Z"
      />
    </svg>
  );
}
