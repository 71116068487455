import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M39.6791 30.8571C40.1509 30.6414 40.6299 30.4064 41.109 30.1513C47.1122 26.9513 50.2857 22.0553 50.2857 16C50.2857 9.4336 47.0857 4.57143 41.0258 1.95109C36.5733 0.0219439 32.1847 0 32 0H30.8571V24.32C30.6414 23.8473 30.4064 23.3691 30.1513 22.8891C26.9513 16.8859 22.0553 13.7134 16 13.7134C9.4336 13.7134 4.57143 16.9134 1.95109 22.9733C0.0219439 27.4249 0 31.8144 0 31.9991V33.1419H24.32C23.8473 33.3577 23.3691 33.5918 22.8891 33.8478C16.8859 37.0478 13.7134 41.9429 13.7134 47.9991C13.7134 54.5646 16.9134 59.4222 22.9733 62.0471C27.4249 63.9762 31.8144 63.9991 31.9991 63.9991H33.1419V39.6791C33.3577 40.1509 33.5918 40.6299 33.8478 41.109C37.0478 47.1122 41.9429 50.2857 47.9991 50.2857C54.5646 50.2857 59.4222 47.0857 62.0471 41.0258C63.9762 36.5733 63.9991 32.1847 63.9991 32V30.8571H39.6791ZM33.1429 2.3488C34.6505 2.47954 37.4354 2.87452 40.1755 4.07406C45.3678 6.34606 48 10.3579 48 16C48 21.2114 45.3376 25.2864 40.0869 28.1051C37.4071 29.5442 34.6926 30.2848 33.1429 30.6231V2.3488ZM4.07406 23.8235C6.34606 18.6322 10.3579 16 16 16C21.2114 16 25.2864 18.6615 28.1051 23.9122C29.5442 26.592 30.2848 29.3065 30.6231 30.8608H2.3488C2.47954 29.3486 2.87452 26.5646 4.07406 23.8235ZM30.8571 61.6521C29.3486 61.5214 26.5646 61.1264 23.8235 59.9269C18.6322 57.6549 16 53.643 16 48.0018C16 42.7904 18.6615 38.7145 23.9122 35.8958C26.592 34.4567 29.3065 33.7161 30.8608 33.3778L30.8571 61.6521ZM59.925 40.1755C57.653 45.3678 53.6411 48 48 48C42.7886 48 38.7127 45.3376 35.8939 40.0869C34.4549 37.4071 33.7143 34.6926 33.376 33.1429H61.6521C61.5195 34.6505 61.1246 37.4354 59.925 40.1755Z"
      />
    </svg>
  );
}
