import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00018 6.75C5.00018 5.23122 6.2314 4 7.75018 4H14.3276C15.8464 4 17.0776 5.23122 17.0776 6.75V10.6399V14.0681H18.2508C18.665 14.0681 19.0008 14.4039 19.0008 14.8181V16.2481C19.0008 18.3192 17.3218 19.9981 15.2508 19.9981H8.2503C6.45531 19.9981 5.00018 18.543 5.00018 16.748V6.75ZM10.9895 18.4981H15.2508C16.4934 18.4981 17.5008 17.4908 17.5008 16.2481V15.5681H16.3276H11.5004V16.748C11.5004 17.3924 11.3129 17.993 10.9895 18.4981ZM15.5776 14.0681H10.7504C10.3362 14.0681 10.0004 14.4039 10.0004 14.8181V16.748C10.0004 17.7146 9.21687 18.4981 8.2503 18.4981C7.28374 18.4981 6.50018 17.7146 6.50018 16.748V6.75C6.50018 6.05964 7.05983 5.5 7.75018 5.5H14.3276C15.018 5.5 15.5776 6.05964 15.5776 6.75V10.6399V14.0681ZM8.25018 8.6171C8.25018 8.20288 8.58597 7.8671 9.00018 7.8671H13.0002C13.4144 7.8671 13.7502 8.20288 13.7502 8.6171C13.7502 9.03131 13.4144 9.3671 13.0002 9.3671H9.00018C8.58597 9.3671 8.25018 9.03131 8.25018 8.6171ZM9.00018 10.7667C8.58597 10.7667 8.25018 11.1025 8.25018 11.5167C8.25018 11.9309 8.58597 12.2667 9.00018 12.2667H13.0002C13.4144 12.2667 13.7502 11.9309 13.7502 11.5167C13.7502 11.1025 13.4144 10.7667 13.0002 10.7667H9.00018Z"
        fill={stroke}
      />
    </svg>
  );
}
