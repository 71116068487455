import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.74804 18.5022C6.05769 18.5022 5.49805 17.9425 5.49805 17.2522L5.49806 12.2336C5.49806 11.8194 5.16227 11.4836 4.74806 11.4836C4.33384 11.4836 3.99806 11.8194 3.99806 12.2336L3.99805 17.2522C3.99804 18.7709 5.22925 20.0022 6.74803 20.0022L17.2523 20.0022C18.7711 20.0022 20.0023 18.771 20.0023 17.2522L20.0023 12.2336C20.0023 11.8194 19.6665 11.4836 19.2523 11.4836C18.8381 11.4836 18.5023 11.8194 18.5023 12.2336L18.5023 17.2522C18.5023 17.9426 17.9427 18.5022 17.2523 18.5022L6.74804 18.5022Z"
        fill={fill}
      />
      <path
        d="M6.93672 9.32362C6.65912 9.01619 6.68331 8.54193 6.99074 8.26433L11.4968 4.19554C11.7365 3.97918 12.0808 3.94441 12.3541 4.09122C12.4124 4.12249 12.4661 4.16123 12.5139 4.20617L17.0082 8.26433C17.3156 8.54193 17.3398 9.01619 17.0622 9.32362C16.7846 9.63105 16.3104 9.65523 16.0029 9.37764L12.7501 6.44048L12.7501 15.3314C12.7501 15.7456 12.4143 16.0814 12.0001 16.0814C11.5859 16.0814 11.2501 15.7456 11.2501 15.3314L11.2501 6.43935L7.996 9.37764C7.68857 9.65523 7.21431 9.63105 6.93672 9.32362Z"
        fill={fill}
      />
    </svg>
  );
}
