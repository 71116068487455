import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.998 5C13.5166 5 15.0118 5.4242 16.3968 6.24986L15.0706 7.23582C14.0636 6.74073 13.0225 6.5 11.998 6.5C9.36691 6.5 6.62602 8.08765 4.63742 11.4938C4.45444 11.8072 4.45444 12.1983 4.63742 12.5117C4.99046 13.1164 5.36722 13.6638 5.76286 14.1552L4.55893 15.0502C4.12681 14.5093 3.71967 13.9148 3.34203 13.268C2.88623 12.4873 2.88623 11.5182 3.34203 10.7375C5.53025 6.98946 8.70918 5 11.998 5ZM12.0637 8.1549C12.5684 8.1549 13.0515 8.24369 13.4967 8.40583L8.06411 12.4444C8.04678 12.2995 8.03788 12.1521 8.03788 12.0028C8.03788 9.87765 9.84028 8.1549 12.0637 8.1549ZM16.0539 11.489L10.56 15.5732C11.0245 15.7521 11.5321 15.8506 12.0637 15.8506C14.287 15.8506 16.0894 14.1279 16.0894 12.0028C16.0894 11.8286 16.0773 11.6571 16.0539 11.489ZM11.998 17.5055C10.9786 17.5055 9.94271 17.2672 8.9405 16.7771L7.61303 17.764C8.99417 18.5842 10.4844 19.0055 11.998 19.0055C15.2867 19.0055 18.4657 17.0161 20.6539 13.268C21.1097 12.4873 21.1097 11.5182 20.6539 10.7375C20.279 10.0954 19.875 9.50483 19.4463 8.96707L18.2425 9.86204C18.6347 10.3504 19.0082 10.8939 19.3585 11.4938C19.5415 11.8072 19.5415 12.1983 19.3585 12.5117C17.3699 15.9179 14.629 17.5055 11.998 17.5055ZM20.4969 6.84057C20.8336 6.59924 20.9109 6.1307 20.6695 5.79405C20.4282 5.4574 19.9597 5.38012 19.623 5.62145L3.51986 17.165C3.18321 17.4063 3.10594 17.8749 3.34726 18.2115C3.58859 18.5482 4.05714 18.6255 4.39379 18.3841L20.4969 6.84057Z"
        fill={fill}
      />
    </svg>
  );
}
