import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4003 5.93048C11.7733 5.72711 12.224 5.72711 12.597 5.93048L18.9481 9.39327V10.9966H5.04921V9.39327L11.4003 5.93048ZM13.3151 4.61351C12.4945 4.1661 11.5028 4.1661 10.6822 4.61351L3.94019 8.28948C3.69918 8.42088 3.54921 8.67346 3.54921 8.94796V11.7466C3.54921 12.092 3.78267 12.3828 4.1004 12.47V18.4928H3.75018C3.33597 18.4928 3.00018 18.8286 3.00018 19.2428C3.00018 19.657 3.33597 19.9928 3.75018 19.9928H4.7434C4.77835 19.9978 4.81407 20.0004 4.8504 20.0004C4.88672 20.0004 4.92245 19.9978 4.95739 19.9928H9.1431C9.17791 19.9977 9.21349 20.0003 9.24966 20.0003C9.28584 20.0003 9.32142 19.9977 9.35623 19.9928H14.6419C14.6767 19.9977 14.7123 20.0003 14.7485 20.0003C14.7847 20.0003 14.8203 19.9977 14.8551 19.9928H19.0427C19.0775 19.9977 19.1131 20.0003 19.1492 20.0003C19.1854 20.0003 19.221 19.9977 19.2558 19.9928H20.2489C20.6631 19.9928 20.9989 19.657 20.9989 19.2428C20.9989 18.8286 20.6631 18.4928 20.2489 18.4928H19.8992V12.4693C20.2158 12.3814 20.4481 12.0912 20.4481 11.7466V8.94796C20.4481 8.67346 20.2981 8.42088 20.0571 8.28948L13.3151 4.61351ZM18.3992 12.4966H15.4985V18.4928H18.3992V12.4966ZM13.9985 12.4966H9.99966V18.4928H13.9985V12.4966ZM8.49966 12.4966H5.6004V18.4928H8.49966V12.4966Z"
        fill={fill}
      />
    </svg>
  );
}
