import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { SelectAsyncProps, SelectPropsWithoutTheme } from './types';
import { SelectCreatableProps } from './types';
import { SelectProps } from './types';
import { SelectWrapper } from './SelectWrapper';
import React from 'react';

export const Select = React.forwardRef((props: SelectProps, ref) => {
  return <SelectWrapper SelectComponent={ReactSelect} selectProps={props} ref={ref} />;
});

export const SelectAsync = React.forwardRef((props: SelectPropsWithoutTheme<SelectAsyncProps>, ref) => {
  return <SelectWrapper SelectComponent={AsyncSelect} selectProps={props} ref={ref} />;
});

export const SelectCreatable = React.forwardRef((props: SelectPropsWithoutTheme<SelectCreatableProps>, ref) => {
  return <SelectWrapper SelectComponent={CreatableSelect} selectProps={props} ref={ref} />;
});
