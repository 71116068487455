import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00061 6.74927C3.00061 5.23048 4.23183 3.99927 5.75061 3.99927H18.2493C19.7681 3.99927 20.9993 5.23048 20.9993 6.74927V11.9998V18.2504C20.9993 19.2169 20.2158 20.0004 19.2493 20.0004H5.75061C4.23183 20.0004 3.00061 18.7692 3.00061 17.2504V14.5426V6.74927ZM5.75061 5.49927C5.06025 5.49927 4.50061 6.05891 4.50061 6.74927V12.5508L7.99747 8.56576C8.14199 8.40107 8.3513 8.30781 8.5704 8.31049C8.7895 8.31318 8.99646 8.41154 9.1369 8.57973L11.0374 10.8558L14.8767 6.59854C15.0151 6.44501 15.2108 6.35544 15.4175 6.351C15.6242 6.34655 15.8236 6.42763 15.9685 6.57506L19.4993 10.167V6.74927C19.4993 6.05891 18.9397 5.49927 18.2493 5.49927H5.75061ZM19.4993 12.3067L15.4571 8.19463L11.9998 12.0284L13.5644 13.9803C13.8235 14.3035 13.7715 14.7755 13.4483 15.0346C13.1251 15.2936 12.6531 15.2417 12.394 14.9185L10.4351 12.4748L8.54707 10.2137L4.50061 14.825V17.2504C4.50061 17.9407 5.06025 18.5004 5.75061 18.5004H19.2493C19.3874 18.5004 19.4993 18.3885 19.4993 18.2504V12.3067Z"
        fill={fill}
      />
    </svg>
  );
}
