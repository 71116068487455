import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9485 5.625C13.0825 4.125 10.9175 4.125 10.0514 5.625L3.98925 16.125C3.12322 17.625 4.20576 19.5 5.93781 19.5H18.0622C19.7942 19.5 20.8767 17.625 20.0107 16.125L13.9485 5.625ZM11.3505 6.375C11.6391 5.875 12.3608 5.875 12.6495 6.375L18.7117 16.875C19.0004 17.375 18.6395 18 18.0622 18H5.93781C5.36045 18 4.99961 17.375 5.28829 16.875L11.3505 6.375ZM12 9.28503C12.4142 9.28503 12.75 9.62082 12.75 10.035V12.84C12.75 13.2542 12.4142 13.59 12 13.59C11.5858 13.59 11.25 13.2542 11.25 12.84V10.035C11.25 9.62082 11.5858 9.28503 12 9.28503ZM13 15.25C13 15.8023 12.5523 16.25 12 16.25C11.4477 16.25 11 15.8023 11 15.25C11 14.6977 11.4477 14.25 12 14.25C12.5523 14.25 13 14.6977 13 15.25Z"
        fill={fill}
      />
    </svg>
  );
}
