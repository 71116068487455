import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M32 2.28571C39.3408 2.29608 46.4161 5.03253 51.8534 9.96426C57.2908 14.896 60.7027 21.6715 61.4273 28.9764C62.1519 36.2813 60.1375 43.595 55.7748 49.4988C51.4122 55.4026 45.0124 59.4756 37.8167 60.9278C30.6209 62.3801 23.1423 61.108 16.8315 57.3583C10.5206 53.6086 5.82735 47.6486 3.66206 40.6345C1.49677 33.6203 2.0138 26.0519 5.11287 19.3974C8.21193 12.7428 13.6721 7.47648 20.4343 4.61989C24.0932 3.07159 28.027 2.27768 32 2.28571ZM32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0Z"
      />
      <path fill={fill} d="M41.1429 16H38.8572V27.4286H41.1429V16Z" />
      <path fill={fill} d="M28.5714 20.5714H17.1428V22.8571H28.5714V20.5714Z" />
      <path
        fill={fill}
        d="M32 46.8571C29.087 46.8571 24.5028 46.2363 19.2182 43.2831C16.0676 41.5222 14.1595 39.7686 14.08 39.6955L15.6342 38.0187C15.6516 38.0342 17.4628 39.69 20.3885 41.3183C23.0573 42.8022 27.2384 44.5714 32 44.5714C36.7616 44.5714 40.9408 42.8022 43.6114 41.3183C46.5371 39.69 48.3501 38.0342 48.3657 38.0178L49.92 39.6955C49.8404 39.7686 47.9323 41.524 44.7817 43.2831C41.8624 44.9133 37.2763 46.8571 32 46.8571Z"
      />
    </svg>
  );
}
