import React, { ForwardedRef, ReactNode, useEffect, useState } from 'react';
import { InputWrapper } from '../Input/InputWrapper';
import { VisuallyHidden } from '../../VisuallyHidden/VisuallyHidden';
import styled from 'styled-components';
import { baseToPx } from '../../../spacings';
import Icon from '../../Icon/Icon';

export interface ToggleProps {
  label: string;
  testId?: string;
  children?: ReactNode;
  disabled?: boolean;
  hideLabel?: boolean;
  hintText?: string;
  errorText?: string;
  infoText?: string;
  warningText?: string;
  onChange?: (checked: boolean) => void;
  inputProps?: {
    onBlur?: () => void;
    name?: string;
    value: string | number | readonly string[] | undefined;
  };
}

export const Checkbox = React.forwardRef(
  (
    {
      label,
      testId,
      children,
      onChange,
      disabled,
      hideLabel,
      errorText,
      hintText,
      warningText,
      infoText,
      inputProps,
    }: ToggleProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [isChecked, setIsChecked] = useState(inputProps?.value === 'true');

    const onClick = () => {
      onChange && onChange(!isChecked);
      setIsChecked(!isChecked);
    };

    useEffect(() => {
      setIsChecked(inputProps?.value === 'true');
    }, [inputProps?.value]);

    return (
      <div onClick={() => !disabled && onClick()}>
        <InputWrapper
          label={label}
          inputId={inputProps?.name || ''}
          hintText={hintText}
          errorText={errorText}
          infoText={infoText}
          warningText={warningText}
          visuallyHidden={hideLabel}
        >
          <VisuallyHidden>
            <input
              ref={ref}
              disabled={disabled}
              id={inputProps?.name || ''}
              data-testid={testId}
              type="checkbox"
              defaultChecked={isChecked}
              value={isChecked.toString()}
              {...inputProps}
            />
          </VisuallyHidden>
          <ElementWithAdditionalText>
            <CheckboxWrapper disabled={disabled}>{isChecked && <Icon size="s" name="checkmark" />}</CheckboxWrapper>
            {children && <div>{children}</div>}
          </ElementWithAdditionalText>
        </InputWrapper>
      </div>
    );
  }
);

const CheckboxWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.color.border.defaultNormalBorder}`};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.4' : 1)};
`;

const ElementWithAdditionalText = styled.div`
  display: flex;
  align-items: center;
  gap: ${baseToPx(1.5)};
`;
