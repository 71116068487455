import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50001 9.83945H7.50002L7.5 9.83392L7.49997 9.81818C7.49997 7.47649 9.47085 5.5 12 5.5C14.5291 5.5 16.5 7.47648 16.5 9.81817L16.5 9.82882V13.2564C16.5 13.5835 16.5642 13.9074 16.6889 14.2097L17.2587 15.5909H6.74137L7.31111 14.2097C7.43583 13.9074 7.50001 13.5835 7.50001 13.2564V9.83945ZM5.99997 9.81818L6.00001 9.83945V13.2564C6.00001 13.3872 5.97434 13.5168 5.92445 13.6377L5.06981 15.7096C4.79828 16.3678 5.2822 17.0909 5.99425 17.0909H9.00003V17.0989C9.00445 18.6942 10.3331 19.9876 11.9763 19.9999H12.0237C13.6697 19.9876 15 18.6899 15 17.0909L18.0058 17.0909C18.7178 17.0909 19.2017 16.3678 18.9302 15.7096L18.0756 13.6377C18.0257 13.5168 18 13.3872 18 13.2564V9.8182L18 9.81818C18 6.60488 15.3137 4 12 4C8.68627 4 5.99997 6.60489 5.99997 9.81818ZM13.5 17.0909H10.5C10.5 17.826 11.1277 18.5 12 18.5C12.8723 18.5 13.5 17.826 13.5 17.0909Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4C18.6572 4 20 5.3428 20 7C20 8.6572 18.6572 10 17 10C15.3428 10 14 8.6572 14 7C14 5.3428 15.344 4 17 4Z"
        fill="#EB5C48"
      />
    </svg>
  );
}
