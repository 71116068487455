import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29158 7.99869L3 12.2903L3.70711 12.9974L7.99869 8.70579L12.2903 12.9974L12.9974 12.2903L8.70579 7.99869L12.9974 3.70711L12.2903 3L7.99869 7.29158L3.70711 3L3 3.70711L7.29158 7.99869Z"
      />
    </svg>
  );
}
