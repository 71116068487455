import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.93395 4.75C5.93395 4.33579 6.26974 4 6.68395 4H19.254C19.6682 4 20.004 4.33579 20.004 4.75V19.2543C20.004 19.6685 19.6682 20.0043 19.254 20.0043H6.68395C6.26974 20.0043 5.93395 19.6685 5.93395 19.2543V17.1785H4.75C4.33579 17.1785 4 16.8427 4 16.4285C4 16.0143 4.33579 15.6785 4.75 15.6785H5.93395V12.6647H4.75C4.33579 12.6647 4 12.3289 4 11.9147C4 11.5004 4.33579 11.1647 4.75 11.1647H5.93395V8.15085H4.75C4.33579 8.15085 4 7.81506 4 7.40085C4 6.98664 4.33579 6.65085 4.75 6.65085H5.93395V4.75ZM7.43395 5.5V18.5043H18.504V5.5H7.43395ZM12.969 9.36771C12.315 9.36771 11.7852 9.89751 11.7852 10.5516C11.7852 11.2056 12.315 11.7354 12.969 11.7354C13.6231 11.7354 14.1529 11.2056 14.1529 10.5516C14.1529 9.89751 13.6231 9.36771 12.969 9.36771ZM10.2852 10.5516C10.2852 9.06908 11.4865 7.86771 12.969 7.86771C14.4515 7.86771 15.6529 9.06908 15.6529 10.5516C15.6529 11.2101 15.4158 11.8133 15.0222 12.2802C16.2658 12.9929 17.1034 14.3333 17.1034 15.8696C17.1034 16.2839 16.7676 16.6196 16.3534 16.6196C15.9392 16.6196 15.6034 16.2839 15.6034 15.8696C15.6034 14.4144 14.4244 13.2354 12.9692 13.2354C11.514 13.2354 10.335 14.4144 10.335 15.8696C10.335 16.2839 9.99917 16.6196 9.58496 16.6196C9.17075 16.6196 8.83496 16.2839 8.83496 15.8696C8.83496 14.3334 9.67246 12.9931 10.9159 12.2804C10.5223 11.8134 10.2852 11.2102 10.2852 10.5516Z"
        fill={fill}
      />
    </svg>
  );
}
