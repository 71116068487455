import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 9.5V14.5L3 9.5V9.504V14.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 12H13" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.5 9.50391H10V14.4999H13.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9.5L16.5 14.5H16.714L18.5 11.5L20.286 14.5H20.5L21 9.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.112 6.49976C18.882 6.20076 18.638 5.90976 18.364 5.63576C14.849 2.12076 9.151 2.12076 5.636 5.63576C5.362 5.90976 5.119 6.20076 4.888 6.49976"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.888 17.5C5.118 17.799 5.362 18.09 5.636 18.364C9.151 21.879 14.849 21.879 18.364 18.364C18.638 18.09 18.881 17.799 19.112 17.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
