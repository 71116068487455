import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM10.25 8.5C10.6642 8.5 11 8.83579 11 9.25V14.7545C11 15.1687 10.6642 15.5045 10.25 15.5045C9.83579 15.5045 9.5 15.1687 9.5 14.7545V9.25C9.5 8.83579 9.83579 8.5 10.25 8.5ZM13.7536 8.5C14.1678 8.5 14.5036 8.83579 14.5036 9.25V14.7545C14.5036 15.1687 14.1678 15.5045 13.7536 15.5045C13.3394 15.5045 13.0036 15.1687 13.0036 14.7545V9.25C13.0036 8.83579 13.3394 8.5 13.7536 8.5Z"
        fill={fill}
      />
    </svg>
  );
}
