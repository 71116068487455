import {
  ForwardedRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  ClipboardEvent,
} from 'react';
import { Input, ParsedNumberInput } from '../InputBase/InputBase.styles';
import { TextInputProps } from './TextInput';
import { i18nVault } from 'i18n';
import {
  getLocaleDecimalSeparator,
  getLocaleGroupingSeparator,
  localeBasedStringValueToNumber,
  minusSignAlternatives,
  normalizedMinusSign,
  toFixedFractionalDigits,
  toLocaleBasedString,
} from 'utils/localeBasedFormatter';

export const LocaleFormattedInput = forwardRef(
  (
    {
      // defines which input is visible (testing purposes only)
      showUnformattedValue = false,
      id,
      name,
      value,
      defaultValue,
      onBlur,
      onChange,
      locale = i18nVault.language,
      max,
      min,
      step,
      testId,
      ...restProps
    }: Omit<TextInputProps, 'required'> & { locale?: Intl.LocalesArgument },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const inputValue = value || (defaultValue as string | number);
    const shouldAllowFractionalDigits = useMemo(() => !step || !Number.isInteger(step), [step]);
    const allowedFractionalDigitsLength = step
      ? shouldAllowFractionalDigits
        ? `${step}`.split('.')[1].length
        : 0
      : undefined;
    // check current locale-based grouping sepator character to be used
    const groupingSeparator = useMemo(() => getLocaleGroupingSeparator(locale), [locale]);
    // check current locale-based decimal sepator character to be used
    const decimalSeparator = useMemo(() => getLocaleDecimalSeparator(locale), [locale]);
    const groupingSeparatorRegExp = useMemo(() => new RegExp('\\' + groupingSeparator, 'g'), [groupingSeparator]);

    /** get number value from formatted string number with separators and truncuate if fractional
     * digits are not allowed, since max and min values might have fractional part */
    const unformatStringNumber = useCallback(
      (stringNumber: string | number) => {
        return localeBasedStringValueToNumber(`${stringNumber}`, allowedFractionalDigitsLength, locale);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [locale]
    );

    const formatNumber = useCallback((value: number | string) => toLocaleBasedString(value, locale), [locale]);

    const getGroupingSeparatorsQuantity = useCallback(
      (value: string) => (value.match(groupingSeparatorRegExp) || []).length,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [locale]
    );

    const getInitialFormattedInputValue = useCallback(
      () => (inputValue !== undefined ? formatNumber(inputValue) : ''),
      [formatNumber, inputValue]
    );

    const getInitialParsedInputValue = useCallback(
      () => (`${inputValue}` !== 'undefined' ? unformatStringNumber(formatNumber(inputValue)) : ''),
      [inputValue, unformatStringNumber, formatNumber]
    );

    const [formattedInputValue, setFormattedInputValue] = useState(getInitialFormattedInputValue);
    const [parsedInputValue, setParsedInputValue] = useState(getInitialParsedInputValue);

    const adaptValueToRestrictions = (value: number) => {
      if (max !== undefined && value > +max) return +max;
      if (min !== undefined && value < +min) return +min;
      return value;
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      let parsedValue = +unformatStringNumber(inputValue === normalizedMinusSign ? '0' : inputValue);

      if (inputValue && !isNaN(parsedValue)) {
        // adapt value to inputProps restrictions
        parsedValue = adaptValueToRestrictions(parsedValue);

        // updates both inputs with new value
        setFormattedInputValue(
          toFixedFractionalDigits(formatNumber(parsedValue), allowedFractionalDigitsLength, locale)
        );
        setParsedInputValue(parsedValue);

        // replace value in outgoing event with new parsed value
        e.target.value = parsedValue.toString();
      }

      // propagate onBlur and onChange events
      onBlur && onBlur(e);
      onChange && onChange(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { target, nativeEvent } = e;
      const originalString = target.value;
      const originalCursorPosition = target.selectionStart || 0;
      const isPasteEvent = (nativeEvent as InputEvent).inputType === 'insertFromPaste';
      const splitOriginalString = originalString.split(decimalSeparator);
      const truncOriginalString =
        shouldAllowFractionalDigits && splitOriginalString[1]?.length
          ? `${splitOriginalString[0]}${decimalSeparator}${splitOriginalString[1]
              .replace(groupingSeparator, '')
              .slice(0, allowedFractionalDigitsLength || 99)}`
          : originalString;
      const unformattedNumber = unformatStringNumber(truncOriginalString);
      // data passed in change event, return unformatted originalString if change event triggered by paste event
      const inputData = isPasteEvent ? unformattedNumber.toString() : (nativeEvent as InputEvent).data;
      // check if locale-specific decimal separator was added with key stroke, and don't format in such situation
      const isDecimalSeparatorAllowed =
        originalString.length === 1 || formattedInputValue === normalizedMinusSign || !shouldAllowFractionalDigits;
      const wasDecimalSeparatorAdded =
        !formattedInputValue.includes(decimalSeparator) && inputData === decimalSeparator;
      const wasGroupingSeparatorAdded = inputData === groupingSeparator;
      // build a regexp expression to allow only number-type characters and separators
      const allowedCharsRegexp = new RegExp(
        `^[${normalizedMinusSign}${minusSignAlternatives}]?(\\d+[${groupingSeparator}]?[${
          shouldAllowFractionalDigits ? decimalSeparator : ''
        }]?[${groupingSeparator}]?)*$`
      );
      const trailingFractionalZeroes = new RegExp(`[${decimalSeparator}][0]+$`);
      const shouldUpdateInputs =
        isPasteEvent ||
        ((!inputData || allowedCharsRegexp.test(truncOriginalString)) &&
          !wasGroupingSeparatorAdded &&
          !(isDecimalSeparatorAllowed && wasDecimalSeparatorAdded));
      const formattedString = formatNumber(unformattedNumber);
      // difference in number of grouping separator characters is needed to maintain stable cursor position
      const groupingSeparatorsDiff =
        getGroupingSeparatorsQuantity(formattedString) - getGroupingSeparatorsQuantity(formattedInputValue);
      // new cursor position is based on old one and possible difference in grouping separators number
      const newCursorPosition = originalCursorPosition + (shouldUpdateInputs ? groupingSeparatorsDiff : -1);

      if (shouldUpdateInputs) {
        // defines whether original string should be passed to input instead of formatted one
        const shouldUseOriginalString =
          !isPasteEvent &&
          ((newCursorPosition === originalString.length && trailingFractionalZeroes.test(truncOriginalString)) ||
            (shouldAllowFractionalDigits &&
              wasDecimalSeparatorAdded &&
              originalString.indexOf(decimalSeparator) === originalString.length - 1));

        // set new locale formatted number string to visible input field
        // if decimals allowed and decimal separator was just added, pass unformatted string
        setFormattedInputValue(
          shouldUseOriginalString
            ? truncOriginalString
            : isPasteEvent
            ? toFixedFractionalDigits(formattedString, allowedFractionalDigitsLength, locale)
            : formattedString
        );

        // set simple, unformatted number value based to named hidden input field
        setParsedInputValue(unformattedNumber.toString());

        // replace value in outgoing event with new parsed value
        e.target.value = unformattedNumber.toString();
        onChange && onChange(e);
      }

      // revert previous cursor position after setting new value, setTimeout for making it async
      setTimeout(() => {
        target.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    };

    // we handle clipboard events copy and cut so that data copied to clipboard is unformatted
    const handleClipboardEvent = (e: ClipboardEvent) => {
      const selection = document.getSelection();
      const input = e.target as HTMLInputElement;

      // if clipboard event is tied to a selection
      if (selection) {
        e.clipboardData?.setData('text/plain', unformatStringNumber(selection?.toString()).toString());
        // if cut event, we need to remove cut part and reformat leftovers
        if (e.type === 'cut') {
          const selectionStart = input.selectionStart || 0;
          const selectionEnd = input.selectionEnd || 0;
          const valueAfterCut = unformatStringNumber(
            `${formattedInputValue.slice(0, selectionStart || 0)}${formattedInputValue.slice(selectionEnd || 0)}`
          ).toString();

          setFormattedInputValue(formatNumber(valueAfterCut));
          setParsedInputValue(valueAfterCut);

          // revert previous cursor position after setting new value, setTimeout for making it async
          setTimeout(() => {
            input.setSelectionRange(selectionStart, selectionStart);
          }, 0);
        }
        // prevent original event, so that new one with changed value can be propagated
        e.preventDefault();
      }
    };

    useEffect(() => {
      setFormattedInputValue(
        inputValue !== undefined && parsedInputValue !== ''
          ? toFixedFractionalDigits(
              formatNumber(adaptValueToRestrictions(+parsedInputValue)),
              allowedFractionalDigitsLength,
              locale
            )
          : ''
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return (
      <>
        <Input
          {...restProps}
          type="text"
          // makes formatted input value not show on form submit by associating it to dummy form
          form="intlDummyForm"
          id={`${id || 'numeric'}.formatted`}
          name={`${name || 'numeric'}.formatted`}
          value={formattedInputValue}
          ref={ref}
          onBlur={handleBlur}
          onChange={handleChange}
          onCopy={handleClipboardEvent}
          onCut={handleClipboardEvent}
          $visible={!showUnformattedValue}
        />
        <ParsedNumberInput
          type="number"
          id={id}
          name={name}
          value={parsedInputValue}
          // makes this input unfocusable by any means (keyboard navigation, mouse click)
          tabIndex={-1}
          readOnly
          onChange={onChange}
          data-testid={`${testId || name}.parsed`}
          $visible={showUnformattedValue}
        />
      </>
    );
  }
);
