import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3334 16.6667C19.3334 17.0203 19.1929 17.3594 18.9428 17.6095C18.6928 17.8595 18.3536 18 18 18H6.00002C5.6464 18 5.30726 17.8595 5.05721 17.6095C4.80716 17.3594 4.66669 17.0203 4.66669 16.6667V9.33333C4.66669 8.97971 4.80716 8.64057 5.05721 8.39052C5.30726 8.14048 5.6464 8 6.00002 8H8.66669L10 6H14L15.3334 8H18C18.3536 8 18.6928 8.14048 18.9428 8.39052C19.1929 8.64057 19.3334 8.97971 19.3334 9.33333V16.6667Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.3333C13.4727 15.3333 14.6666 14.1394 14.6666 12.6667C14.6666 11.1939 13.4727 10 12 10C10.5272 10 9.33331 11.1939 9.33331 12.6667C9.33331 14.1394 10.5272 15.3333 12 15.3333Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
