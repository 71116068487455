import { ReactNode, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import { Heading, HeadingProps } from '../Text/Heading';
import { AccordionGroupContext } from './AccordionGroup';

const ACCORDION_ANIMATION_DURATION = 500;
interface AccordionBasicProps extends HeadingProps {
  children: ReactNode;
  isCollapsed?: boolean;
  hasBorder?: boolean;
}

interface AccordionWithDefaultHeadingProps extends AccordionBasicProps {
  title: string;
  customHeading?: never;
}

interface AccordionWithCustomHeadingProps extends AccordionBasicProps {
  title?: never;
  customHeading: ReactNode;
}

export type AccordionProps = AccordionWithCustomHeadingProps | AccordionWithDefaultHeadingProps;

export default function Accordion({
  title,
  children,
  customHeading,
  isCollapsed = true,
  hasBorder = true,
  level = 'h6',
  size = 'xxs',
  align,
  color,
  noBold = true,
  noGutter = true,
  sansSerif = true,
  ...props
}: AccordionProps) {
  const context = useContext(AccordionGroupContext);
  const { updateToggle, isGroupCollapsed } = context;
  const isInitiallyExpanded = isGroupCollapsed !== undefined ? !isGroupCollapsed : !isCollapsed;

  // describes accordion toggle state (on/off)
  const [isToggled, setIsToggled] = useState(isInitiallyExpanded);
  // describes acccordion animation state (expand/collapse)
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  useEffect(() => {
    // update state of toggle to accordion group component if function is defined and click triggered
    updateToggle && updateToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  useEffect(() => {
    // update this accordion if accordion group toggle state was updated to defined state
    if (isGroupCollapsed !== undefined) {
      toggleAccordion(isGroupCollapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupCollapsed]);

  useEffect(() => {
    toggleAccordion(isCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  const toggleAccordion = (explicitlyCollapsed?: boolean) => {
    // guard to allow state change if not animating currently -> both variables have same value
    if (isToggled === isExpanded) {
      // we delay setting 'isToggled' to animation duration when sliding up to remove content only after animation ended
      setTimeout(
        () => {
          setIsToggled(explicitlyCollapsed !== undefined ? !explicitlyCollapsed : !isToggled);
        },
        isExpanded ? ACCORDION_ANIMATION_DURATION : 0
      );
      // we delay setting 'isExpanded' to at least 50ms diff between adding content and animating height to register change
      setTimeout(() => {
        setIsExpanded(explicitlyCollapsed !== undefined ? !explicitlyCollapsed : !isExpanded);
      }, 50);
    }
  };

  return (
    <AccordionWrapper hasBorder={hasBorder} {...props}>
      <AccordionHeader onClick={() => toggleAccordion()}>
        {customHeading ?? (
          <AccordionHeading
            level={level}
            size={size}
            align={align}
            color={color}
            noBold={noBold}
            noGutter={noGutter}
            sansSerif={sansSerif}
          >
            {title}
          </AccordionHeading>
        )}
        <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} size="m" />
      </AccordionHeader>
      <AccordionContent isExpanded={isExpanded} className={`accordion ${isExpanded ? 'expanded' : ''}`}>
        {isToggled && children}
      </AccordionContent>
    </AccordionWrapper>
  );
}

const AccordionWrapper = styled.section<{ hasBorder: boolean }>`
  border-top: 1px solid ${({ hasBorder, theme }) => (hasBorder ? theme.color.border.disabledBorder : 'transparent')};
`;

const AccordionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1]};
  padding: ${({ theme }) => `${theme.spacings[2]} ${theme.spacings[1]} ${theme.spacings[2]} 0`};
  cursor: pointer;
`;

const AccordionHeading = styled(Heading)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
`;

const AccordionContent = styled.article<{ isExpanded: boolean }>`
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height
      ${({ isExpanded }) =>
        isExpanded
          ? `${2 * ACCORDION_ANIMATION_DURATION}ms ease-in`
          : `${ACCORDION_ANIMATION_DURATION}ms cubic-bezier(0, 1, 0, 1)`},
    padding ${({ isExpanded }) => (isExpanded ? 0.5 * ACCORDION_ANIMATION_DURATION : ACCORDION_ANIMATION_DURATION)}ms
      ease-in-out;

  &.expanded {
    padding-bottom: ${({ theme }) => theme.spacings[3]};
    /* animating max-height requires setting defined value, we're setting to a safe one for longer content */
    max-height: 10000px;
  }
`;
