import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.332 6.793C17.701 4.5 15.028 3 12 3C7.029 3 3 7.029 3 12C3 12.539 3.056 13.064 3.147 13.576"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42401 19.594V17.207H6.81301"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66803 17.207C6.29903 19.5 8.97203 21 12 21C16.971 21 21 16.971 21 12C21 11.461 20.944 10.936 20.853 10.424"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.576 4.40601V6.79301H17.187"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
