import styled from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';
import { CONTAINER_DEFAULT_WIDTH, CONTAINER_MINIMUM_PAGE_PADDINGS } from '../Container/Container';

const SIDEBAR_WIDTH = 240;

const ColumnSidebarStyled = styled.section`
  position: fixed;
  left: calc(((100vw - ${CONTAINER_DEFAULT_WIDTH}px) / 2) - ${SIDEBAR_WIDTH}px - ${CONTAINER_MINIMUM_PAGE_PADDINGS}px);
  width: ${SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings['8']};
`;

const Sidebar = ({ children }: { children: ReactNode }) => {
  const [isColumn, setIsColumn] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const spaceInColumn = (window.innerWidth - CONTAINER_DEFAULT_WIDTH) / 2;
      const sidebarCanFit = spaceInColumn >= SIDEBAR_WIDTH + CONTAINER_MINIMUM_PAGE_PADDINGS;
      setIsColumn(sidebarCanFit);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const Wrapper = isColumn ? ColumnSidebarStyled : `section`;

  return <Wrapper>{children}</Wrapper>;
};

export default Sidebar;
