import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9003 21.0567C30.7057 22.4518 30.2277 24.2358 28.8326 25.0412C27.4375 25.8467 25.6536 25.3687 24.8481 23.9736C24.0426 22.5785 24.5206 20.7945 25.9158 19.9891C27.3109 19.1836 29.0948 19.6616 29.9003 21.0567Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.737779 20.6598C-0.781004 18.0291 0.120309 14.6654 2.75092 13.1466L15.5833 5.73782C18.2139 4.21904 21.5777 5.12035 23.0965 7.75096L26.8386 14.2326C27.6506 13.9461 28.4839 13.7127 29.3336 13.5349L32.4598 12.8811C33.0941 12.7485 33.7419 13.0381 34.0659 13.5994L39.9006 23.7055C40.1295 24.1019 40.1649 24.5812 39.9966 25.0069C39.8283 25.4326 39.4748 25.7582 39.0367 25.891L36.37 26.6992C35.7123 26.8986 35.0645 27.1252 34.4282 27.378L39.3787 35.9527C40.8975 38.5833 39.9962 41.9471 37.3656 43.4659L24.5332 50.8746C21.9026 52.3934 18.5389 51.4921 17.0201 48.8615L0.737779 20.6598ZM31.704 28.6597C30.033 29.5753 28.4694 30.6855 27.0474 31.971L24.4483 34.3204C24.1122 34.6242 23.6568 34.7598 23.2092 34.6894C22.7617 34.6189 22.3699 34.35 22.1434 33.9576L16.3086 23.8515C15.9797 23.2819 16.0603 22.5644 16.5074 22.082L19.0226 19.3678C20.49 17.7842 22.2009 16.4651 24.0794 15.4535L20.4984 9.25096C19.808 8.05523 18.279 7.64554 17.0833 8.3359L14.6832 9.72159C15.3829 11.6653 14.6142 13.8904 12.7622 14.9597C10.9101 16.029 8.59881 15.5822 7.26532 14.0043L4.25092 15.7447C3.05519 16.435 2.6455 17.964 3.33585 19.1598L19.6181 47.3615C20.3085 48.5572 21.8375 48.9669 23.0332 48.2766L35.8656 40.8678C37.0613 40.1774 37.471 38.6484 36.7807 37.4527L31.704 28.6597ZM10.4509 12.536C10.7234 12.5637 11.0068 12.509 11.2622 12.3616C11.5021 12.2231 11.6834 12.0222 11.7976 11.7912L10.4509 12.536ZM21.223 21.4069C23.5491 18.8968 26.5981 17.172 29.9478 16.4714L32.0108 16.0399L36.3573 23.5683L35.4998 23.8282C31.6181 25.0048 28.0446 27.0255 25.0356 29.7454L23.8134 30.8502L19.4599 23.3096L21.223 21.4069Z"
        fill={fill}
      />
      <path
        d="M52.0897 8.17453C51.6338 7.48283 50.7035 7.29167 50.0118 7.74757C49.3201 8.20346 49.1289 9.13378 49.5848 9.82548C53.4718 15.7228 53.4718 23.2772 49.5848 29.1745C49.1289 29.8662 49.3201 30.7965 50.0118 31.2524C50.7035 31.7083 51.6338 31.5172 52.0897 30.8255C56.6368 23.9265 56.6368 15.0735 52.0897 8.17453Z"
        fill={fill}
      />
      <path
        d="M47.2714 12.6257C46.8318 11.9235 45.9062 11.7107 45.204 12.1503C44.5019 12.5899 44.289 13.5155 44.7286 14.2177C46.5486 17.1246 46.5488 20.7717 44.7287 23.6782C44.289 24.3804 44.5018 25.306 45.2039 25.7456C45.9061 26.1853 46.8317 25.9725 47.2713 25.2704C49.7013 21.3899 49.7009 16.5062 47.2714 12.6257Z"
        fill={fill}
      />
    </svg>
  );
}
