import { ReactNode } from 'react';
import {
  fontFaces,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  HeadingSize,
  textMarginSize,
  TextAlign,
} from '../../typography';
import styled from 'styled-components';
import { ForegroundColors } from '../../theme/colors';

export const headingLevelOptionsArray = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
export type LevelHeading = (typeof headingLevelOptionsArray)[number];

type HeadingStyleProps = {
  size: HeadingSize;
  noBold: boolean;
  noGutter: boolean;
  color?: ForegroundColors;
  align?: TextAlign;
  sansSerif?: boolean;
};

const Styled = styled.h2<HeadingStyleProps>`
  font-family: ${({ sansSerif }) => (sansSerif ? fontFaces.Roobert : fontFaces.loomiHeadline)};
  font-weight: ${({ sansSerif, noBold }) => (sansSerif && !noBold ? fontWeight.semiBold : fontWeight.normal)};
  text-align: ${({ align }) => align || 'inherit'};
  margin: 0;

  color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.primary)};

  ${({ size, noGutter }) => {
    const lSpacing = letterSpacing.heading[size] ? `letter-spacing: ${letterSpacing.heading[size]};` : '';
    return `
      font-size: ${fontSize.heading[size]};
      line-height: ${lineHeight.heading[size]};
      ${lSpacing}
      margin-bottom: ${noGutter ? 0 : textMarginSize.heading[size]}
    `;
  }}
`;

export interface HeadingProps {
  /**
   * Define size of the Heading. You can use only predefined values.
   */
  size?: HeadingSize;
  /**
   * Set the correct html tag, it could be very useful to provide correct headings hierarchy on the page.
   */
  level?: LevelHeading;
  /**
   * Content of the header.
   */
  children: ReactNode;
  /**
   * Set html id of the element
   */
  id?: string;
  /**
   * No bold text option
   */
  noBold?: boolean;
  /**
   * No spacing option
   */
  noGutter?: boolean;
  /**
   * Text color
   */
  color?: ForegroundColors;
  /**
   * Text align
   */
  align?: TextAlign;
  /**
   * Font family
   */
  sansSerif?: boolean;
}

export const Heading = ({
  size = 'l',
  noBold = false,
  noGutter = false,
  color,
  children,
  level = 'h2',
  ...props
}: HeadingProps) => (
  <Styled as={level} size={size} noBold={noBold} color={color} noGutter={noGutter} {...props}>
    {children}
  </Styled>
);
