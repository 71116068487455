import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28ZM55 28C55 42.9117 42.9117 55 28 55C13.0883 55 1 42.9117 1 28C1 13.0883 13.0883 1 28 1C42.9117 1 55 13.0883 55 28ZM39.2387 19.8461C39.706 19.1621 39.5303 18.2287 38.8463 17.7614C38.1622 17.2941 37.2289 17.4698 36.7616 18.1539L25.4726 34.6794L19.0473 28.4147C18.4542 27.8364 17.5045 27.8484 16.9262 28.4416C16.3478 29.0347 16.3598 29.9844 16.953 30.5627L24.6568 38.074C24.974 38.3833 25.4118 38.5366 25.8527 38.4926C26.2936 38.4486 26.6926 38.212 26.9425 37.8461L39.2387 19.8461Z"
        fill={fill}
      />
    </svg>
  );
}
