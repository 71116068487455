import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 4.91602V6.58589C6 6.8511 5.89464 7.10545 5.70711 7.29299L5.17157 7.82853C4.42143 8.57867 4 9.59608 4 10.6569V18.0001C4 19.6569 5.34315 21.0001 7 21.0001H17C18.6569 21.0001 20 19.6569 20 18.0001V10.6569C20 9.59608 19.5786 8.57867 18.8284 7.82853L18.2929 7.29299C18.1054 7.10545 18 6.8511 18 6.58589V4.91602"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="5"
        y="2"
        width="14"
        height="3"
        rx="1.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 9.875V9.125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16.125V16.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.264 15.2831C10.5227 15.7069 10.9653 16 11.5 16H12H12.596C13.372 16 14 15.3711 14 14.5969C14 13.9534 13.5613 13.3924 12.9373 13.2352L11.0627 12.7648C10.4387 12.6076 10 12.0466 10 11.4031C10 10.6276 10.6293 10 11.404 10H12H12.5C13.0333 10 13.4747 10.2918 13.7333 10.7142"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
