import { IconSVGProps } from '../types';

export default function SVGComponentArrowDown({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 13.0589L12 19.5L19.5 13.0589M12.0008 19.4969L12.0008 4.50001"
        stroke={stroke}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
