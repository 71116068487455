import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.91938 7H6.08062C5.24212 7 4.77595 7.96993 5.29976 8.6247L7.21913 11.0239C7.61946 11.5243 8.38054 11.5243 8.78087 11.0239L10.7002 8.62469C11.2241 7.96993 10.7579 7 9.91938 7Z"
        fill={fill}
      />
    </svg>
  );
}
