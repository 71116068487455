import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke, fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="16.25"
        y="4.75"
        width="14.5"
        height="8.5"
        rx="1.25"
        transform="rotate(90 16.25 4.75)"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <circle cx="12" cy="17" r="1" fill={fill} />
    </svg>
  );
}
