import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49998 18.9999H6.39999C6.02869 18.9999 5.6726 18.8524 5.41005 18.5899C5.1475 18.3273 5 17.9712 5 17.5999V12.7C5 12.3286 5.1475 11.9726 5.41005 11.71C5.6726 11.4475 6.02869 11.3 6.39999 11.3H8.49998M13.3999 9.89997V7.09999C13.3999 6.54304 13.1787 6.0089 12.7849 5.61507C12.3911 5.22125 11.8569 5 11.3 5L8.49998 11.3V18.9999H16.3959C16.7336 19.0037 17.0612 18.8854 17.3185 18.6667C17.5757 18.448 17.7453 18.1437 17.7959 17.8099L18.7619 11.51C18.7924 11.3093 18.7788 11.1044 18.7223 10.9095C18.6657 10.7146 18.5674 10.5344 18.4342 10.3812C18.301 10.2281 18.1362 10.1057 17.951 10.0226C17.7659 9.93952 17.5649 9.89767 17.3619 9.89997H13.3999Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
