import {
  defaultIsCoordinationNo,
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  getGenderFactory,
  validateFactory
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetAgeFunc,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFunc,
  GetYearFunc,
  IsCoordinationNoFunc,
  ValidateFunc
} from './interfaces'
import { isLuhnValid } from './luhnAlgorithm'

const sweden: CountryFunc = (personalNumber: string, strict: boolean = true): CountryTools => {
  const dayLocation = strict ? 3 : 4

  let format = '^([0-9]{2})([0-9]{2})([0-9]{2})([-+])([0-9]{3})([0-9])$'

  if (!strict) {
    format = '^(18|19|20)?([0-9]{2})([0-9]{2})([0-9]{2})([-+])?([0-9]{3})([0-9])$'
  }

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  if (!matches) {
    return defaultTools({ isCoordinationNo: defaultIsCoordinationNo })
  }

  const getDay = (): string => {
    const day = parseInt(matches[dayLocation], 10)

    if (day >= 61) {
      const newDay = day - 60

      // TODO padStart when TS can transpile it to es5
      return (newDay <= 9 ? '0' : '') + newDay.toString()
    }

    return matches[dayLocation]
  }

  /* tslint:disable:object-literal-sort-keys */
  let parts = {
    centuryIndicator: '',
    day: getDay(),
    month: matches[2],
    year: matches[1],
    separator: matches[4],
    individualNumber: matches[5],
    gender: matches[5],
    checksum: matches[6]
  }

  if (!strict) {
    parts = {
      centuryIndicator: matches[1] || '',
      day: getDay(),
      month: matches[3],
      year: matches[2],
      separator: matches[5] || '',
      individualNumber: matches[6],
      gender: matches[6],
      checksum: matches[7]
    }
  }
  /* tslint:enable:object-literal-sort-keys */

  const isCoordinationNo: IsCoordinationNoFunc = (): boolean => ['6', '7', '8', '9'].includes(matches[dayLocation][0])

  const getYear: GetYearFunc = (): number => {
    if (!strict && parts.centuryIndicator) {
      return parseInt(`${parts.centuryIndicator}${parts.year}`, 10)
    }

    const birthday = `${parts.year}-${parts.month}-${parts.day}`
    const todayObj = new Date()

    let century = '19'

    if (todayObj < new Date(`20${birthday}`)) {
      century = '19'
    } else if (todayObj >= new Date(`20${birthday}`) && parts.separator === '-') {
      century = '20'
    } else if (todayObj >= new Date(`19${birthday}`) && parts.separator === '+') {
      century = '19'
    }

    return parseInt(`${century}${parts.year}`, 10)
  }

  let localValidate: ValidateFunc = (): boolean => isLuhnValid(personalNumber)

  if (!strict && parts.centuryIndicator) {
    localValidate = () => isLuhnValid(personalNumber.replace(new RegExp(`^${parts.centuryIndicator}`), ''))
  }

  const validate: ValidateFunc = validateFactory(personalNumber, regex, parts, getYear, localValidate)

  if (!validate()) {
    return defaultTools({ isCoordinationNo: defaultIsCoordinationNo })
  }

  const getBirthday: GetBirthdayFunc = getBirthdayFactory(parts, getYear)

  const getAge: GetAgeFunc = getAgeFactory(getBirthday)

  const getGender: GetGenderFunc = getGenderFactory(parts)

  const getCensored: GetCensoredFunc = (): string => {
    if (!strict) {
      return `${parts.centuryIndicator}${parts.year}${parts.month}${matches[4]}${parts.separator}****`
    }

    return `${parts.year}${parts.month}${matches[3]}${parts.separator}****`
  }

  const getCountry: GetCountryFunc = (): string => 'sweden'

  return {
    getAge,
    getBirthday,
    getCensored,
    getCountry,
    getGender,
    getYear,
    isCoordinationNo,
    validate
  }
}

export default sweden
