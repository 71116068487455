import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54413 5.5C7.30813 5.5 5.5 7.30557 5.5 9.52731C5.5 11.7491 7.30813 13.5546 9.54413 13.5546C10.6512 13.5546 11.6527 13.1127 12.3834 12.3952C13.1284 11.6637 13.5883 10.6495 13.5883 9.52731C13.5883 7.30557 11.7801 5.5 9.54413 5.5ZM4 9.52731C4 6.47218 6.48468 4 9.54413 4C12.6036 4 15.0883 6.47218 15.0883 9.52731C15.0883 10.796 14.6589 11.9653 13.9386 12.8977L19.7794 18.7188C20.0728 19.0112 20.0736 19.486 19.7812 19.7794C19.4888 20.0728 19.014 20.0736 18.7206 19.7812L12.8697 13.9502C11.9433 14.6434 10.7912 15.0546 9.54413 15.0546C6.48468 15.0546 4 12.5824 4 9.52731Z"
        fill={fill}
      />
    </svg>
  );
}
