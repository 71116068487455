import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 21H6C5.46952 21.0001 4.96073 20.7895 4.58563 20.4144C4.21052 20.0393 3.99985 19.5305 4 19V8.5C4.0003 7.6717 4.6717 7.0003 5.5 7H18.5C19.3283 7.0003 19.9997 7.6717 20 8.5V11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16.9998V12.5C14 11.6716 14.6716 11 15.5 11V11C16.3285 11 17 11.6716 17 12.5V14.7507L19.3217 15.1293C20.3832 15.3024 21.1177 16.2839 20.9844 17.3511L20.6224 20.248C20.4973 21.2489 19.6466 22 18.6379 22H14.8418C14.1731 22 13.5485 21.6657 13.1776 21.1093L11.2376 18.1988C10.8637 17.6379 10.9376 16.891 11.4143 16.4142H11.4144C11.6796 16.149 12.0393 16 12.4145 16C12.7896 16 13.1493 16.149 13.4145 16.4142L14 16.9998Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7V6.75V6.75C8.00005 4.67895 9.67895 3.00005 11.75 3H12.25C14.321 3.00005 16 4.67895 16 6.75V6.75V7"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
