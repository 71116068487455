import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.58331 5.58325L18.4166 18.4166"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 17.25H6.74998C6.44056 17.25 6.14381 17.1271 5.92502 16.9083C5.70623 16.6895 5.58331 16.3928 5.58331 16.0833V9.66667C5.58331 9.35725 5.70623 9.0605 5.92502 8.84171C6.14381 8.62292 6.44056 8.5 6.74998 8.5H8.49998M10.25 6.75H13.75L14.9166 8.5H17.25C17.5594 8.5 17.8561 8.62292 18.0749 8.84171C18.2937 9.0605 18.4166 9.35725 18.4166 9.66667V15.115M13.9133 13.9133C13.7197 14.1963 13.4661 14.4331 13.1706 14.6069C12.8751 14.7807 12.5449 14.8873 12.2035 14.919C11.8622 14.9507 11.518 14.9068 11.1955 14.7904C10.873 14.674 10.5802 14.488 10.3377 14.2456C10.0953 14.0032 9.90927 13.7103 9.79288 13.3878C9.67649 13.0653 9.63259 12.7212 9.66432 12.3798C9.69604 12.0384 9.8026 11.7082 9.97641 11.4127C10.1502 11.1172 10.387 10.8636 10.67 10.67"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
