import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6614 7.25C13.0756 7.25 13.4114 7.58579 13.4114 8C13.4114 8.41421 13.0756 8.75 12.6614 8.75H5.16417C4.74996 8.75 4.41417 8.41421 4.41417 8C4.41417 7.58579 4.74996 7.25 5.16417 7.25H12.6614ZM12.6614 11.2496C13.0756 11.2496 13.4114 11.5854 13.4114 11.9996C13.4114 12.4138 13.0756 12.7496 12.6614 12.7496H5.16417C4.74996 12.7496 4.41417 12.4138 4.41417 11.9996C4.41417 11.5854 4.74996 11.2496 5.16417 11.2496H12.6614ZM13.4114 16C13.4114 15.5858 13.0756 15.25 12.6614 15.25H5.16417C4.74996 15.25 4.41417 15.5858 4.41417 16C4.41417 16.4142 4.74996 16.75 5.16417 16.75H12.6614C13.0756 16.75 13.4114 16.4142 13.4114 16ZM15.4114 9.41421L15.4114 14.5858C15.4114 15.4767 16.4885 15.9229 17.1185 15.2929L19.7043 12.7071C20.0948 12.3166 20.0948 11.6834 19.7043 11.2929L17.1185 8.7071C16.4885 8.07714 15.4114 8.52331 15.4114 9.41421Z"
        fill={fill}
      />
    </svg>
  );
}
