import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5214 4.28133C14.2625 3.95796 13.7906 3.90566 13.4672 4.16452C13.1438 4.42337 13.0915 4.89535 13.3504 5.21872L15.2757 7.62387L6.75006 7.62387C5.23128 7.62387 4.00006 8.85508 4.00006 10.3739V11.48C4.00006 11.8942 4.33585 12.23 4.75006 12.23C5.16427 12.23 5.50006 11.8942 5.50006 11.48V10.3739C5.50006 9.68351 6.0597 9.12387 6.75006 9.12387L15.2757 9.12387L13.3504 11.529C13.0915 11.8524 13.1438 12.3244 13.4672 12.5832C13.7906 12.8421 14.2625 12.7898 14.5214 12.4664L17.4123 8.85494C17.5213 8.72473 17.5869 8.55696 17.5869 8.37387C17.5869 8.19053 17.5211 8.02255 17.4119 7.89225L14.5214 4.28133ZM10.0536 11.4122C10.3769 11.671 10.4292 12.143 10.1704 12.4664L8.24508 14.8716L17.2544 14.8716C17.9447 14.8716 18.5044 14.3119 18.5044 13.6216V12.5154C18.5044 12.1012 18.8402 11.7654 19.2544 11.7654C19.6686 11.7654 20.0044 12.1012 20.0044 12.5154V13.6216C20.0044 15.1404 18.7732 16.3716 17.2544 16.3716H8.24509L10.1704 18.7767C10.4292 19.1001 10.3769 19.5721 10.0536 19.8309C9.7302 20.0898 9.25821 20.0375 8.99936 19.7141L6.09851 16.0903C5.87919 15.8163 5.87919 15.4268 6.09851 15.1529L8.99936 11.529C9.25821 11.2056 9.7302 11.1533 10.0536 11.4122Z"
        fill={fill}
      />
    </svg>
  );
}
