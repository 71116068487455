import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6667 6H6C4.89543 6 4 6.89543 4 8V15.9H5.12672C5.43411 15.3819 6.00864 15.0333 6.66667 15.0333M14.6667 6V15.0333M14.6667 6V8.1M14.6667 8.1H17.3333C18.5 8.1 20 9 20 10.7V12V15.0333C20 15.512 19.602 15.9 19.1111 15.9H16.2066M14.6667 8.1V15.0333M14.6667 15.0333C14.0086 15.0333 13.4341 15.3819 13.1267 15.9M14.6667 15.0333C14.9905 15.0333 15.2941 15.1177 15.5556 15.2652C15.8254 15.4174 16.0505 15.6369 16.2066 15.9M13.1267 15.9C12.9755 16.1549 12.8889 16.451 12.8889 16.7667C12.8889 17.724 13.6848 18.5 14.6667 18.5C15.6485 18.5 16.4444 17.724 16.4444 16.7667C16.4444 16.451 16.3579 16.1549 16.2066 15.9M13.1267 15.9H10.6667H8.20661C7.89922 15.3819 7.3247 15.0333 6.66667 15.0333M6.66667 15.0333C5.68483 15.0333 4.88889 15.8094 4.88889 16.7667C4.88889 17.724 5.68483 18.5 6.66667 18.5C7.64851 18.5 8.44444 17.724 8.44444 16.7667C8.44444 15.8094 7.64851 15.0333 6.66667 15.0333Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
