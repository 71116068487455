import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00055 6.75C4.00055 5.23122 5.23177 4 6.75055 4H17.2543C18.7731 4 20.0043 5.23122 20.0043 6.75V17.2542C20.0043 18.773 18.7731 20.0042 17.2543 20.0042H6.75055C5.23176 20.0042 4.00055 18.773 4.00055 17.2542V8.64531C4.00022 8.63625 4.00006 8.62714 4.00006 8.61799C4.00006 8.60885 4.00022 8.59974 4.00055 8.59068V6.75ZM5.50055 9.36799V17.2542C5.50055 17.9446 6.06019 18.5042 6.75055 18.5042H17.2543C17.9447 18.5042 18.5043 17.9446 18.5043 17.2542V9.36799H5.50055ZM18.5043 7.86799H5.50055V6.75C5.50055 6.05964 6.06019 5.5 6.75055 5.5H17.2543C17.9447 5.5 18.5043 6.05964 18.5043 6.75V7.86799ZM9.80231 11.519C9.80231 11.1048 10.1381 10.769 10.5523 10.769H12.0028C12.417 10.769 12.7528 11.1048 12.7528 11.519V15.6038H13.4532C13.8674 15.6038 14.2032 15.9395 14.2032 16.3538C14.2032 16.768 13.8674 17.1038 13.4532 17.1038H12.0028H10.5523C10.1381 17.1038 9.80231 16.768 9.80231 16.3538C9.80231 15.9395 10.1381 15.6038 10.5523 15.6038H11.2528V12.269H10.5523C10.1381 12.269 9.80231 11.9332 9.80231 11.519Z"
        fill={fill}
      />
    </svg>
  );
}
