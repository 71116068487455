import { ReactNode } from 'react';
import styled from 'styled-components';
import { fontFaces, fontSize, lineHeight, TextSize, textMarginSize, fontWeight, TextAlign } from '../../typography';
import { ForegroundColors } from '../../theme/colors';

type TextStyleProps = {
  size: TextSize;
  noGutter?: boolean;
  color?: ForegroundColors;
  weight?: keyof typeof fontWeight;
  align?: TextAlign;
};
export const TextStyled = styled.span<TextStyleProps>`
  font-family: ${fontFaces.Roobert};
  margin: 0;

  color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.primary)};

  ${({ size }) => {
    return `
      font-size: ${fontSize.paragraph[size]};
      line-height: ${lineHeight.paragraph[size]};
    `;
  }}

  ${({ weight }) => {
    if (!weight) {
      return '';
    }
    return `
      font-weight: ${fontWeight[weight]};
    `;
  }}

  ${({ align }) => {
    if (!align) {
      return '';
    }
    return `
      text-align: ${align};
    `;
  }}
`;

const ParagraphStyled = styled(TextStyled)`
  ${({ size, noGutter }) => {
    return `
    margin-bottom: ${noGutter ? '0' : textMarginSize.paragraph[size]}`;
  }}
`;

const StrikeThroughStyled = styled(TextStyled)`
  text-decoration: line-through;
`;

const ItalicStyled = styled(TextStyled)`
  font-style: italic;
`;

const BoldStyled = styled(TextStyled)`
  font-weight: ${({ weight }) => fontWeight[weight || 'bold']};
`;

export interface ParagraphProps {
  /**
   * Define size of the Paragraph. You can use only predefined values.
   */
  size?: TextSize;
  /**
   * Content of the paragraph.
   */
  children: ReactNode;
  /**
   * No spacing option
   */
  noGutter?: boolean;
  /**
   * Text color
   */
  color?: ForegroundColors;
  /**
   * Text weight
   */
  weight?: keyof typeof fontWeight;
  /**
   * Text align
   */
  align?: TextAlign;
}

export const Text = ({ size = 'm', color, weight, align, children, ...props }: Omit<ParagraphProps, 'noGutter'>) => (
  <TextStyled size={size} color={color} weight={weight} align={align} {...props}>
    {children}
  </TextStyled>
);

export const Paragraph = ({ size = 'm', noGutter = false, color, align, children, ...props }: ParagraphProps) => (
  <ParagraphStyled as="p" noGutter={noGutter} color={color} size={size} align={align} {...props}>
    {children}
  </ParagraphStyled>
);

export const Strong = ({ size = 'm', noGutter = false, color, align, children, ...props }: ParagraphProps) => (
  <BoldStyled noGutter={noGutter} color={color} size={size} align={align} {...props}>
    {children}
  </BoldStyled>
);

export const Italic = ({ size = 'm', noGutter = false, color, align, children, ...props }: ParagraphProps) => (
  <ItalicStyled noGutter={noGutter} color={color} size={size} align={align} {...props}>
    {children}
  </ItalicStyled>
);

export const StrikeThrough = ({ size = 'm', noGutter = false, color, align, children, ...props }: ParagraphProps) => (
  <StrikeThroughStyled noGutter={noGutter} color={color} size={size} align={align} {...props}>
    {children}
  </StrikeThroughStyled>
);
