import styled from 'styled-components';

export const DialogHeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacings['1']};
`;

export const DialogSubtitleStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings['1']};
  align-items: center;
`;

export const DialogBodyStyled = styled.div`
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings['2']};
`;

export const DialogFooterStyled = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings[2]};
  margin-top: ${({ theme }) => theme.spacings[3]};
`;
