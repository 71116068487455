import { spacing } from './spacings';

export const textSizeOptionsArray = ['xxs', 'xs', 's', 'm', 'l', 'xl'] as const;
export const alignOptionsArray = ['left', 'right', 'center'] as const;
export const displaySizeOptionsArray = ['s', 'm', 'l'] as const;

export type TextAlign = (typeof alignOptionsArray)[number];
export type TextSize = (typeof textSizeOptionsArray)[number];
export type HeadingSize = (typeof textSizeOptionsArray)[number];
export type LabelSize = (typeof textSizeOptionsArray)[number];
export type DisplaySize = (typeof displaySizeOptionsArray)[number];

export const fontFaceNames = {
  loomiDisplay: '"Loomi Display"',
  loomiHeadline: '"Loomi Headline"',
  loomiText: '"Loomi Text"',
  Roobert: '"Roobert"',
};

type VariantMap = {
  display: Record<DisplaySize, string>;
  heading: Record<HeadingSize, string>;
  label: Record<LabelSize, string>;
  paragraph: Record<TextSize, string>;
};

type FontFace = typeof fontFaceNames;

export const fontFaces: FontFace = {
  ...fontFaceNames,
};

export const fontSize: VariantMap = {
  display: {
    l: '120px',
    m: '80px',
    s: '58px',
  },
  heading: {
    xl: '47px',
    l: '39px',
    m: '29px',
    s: '24px',
    xs: '20px',
    xxs: '16px',
  },
  label: {
    xl: '22px',
    l: '18px',
    m: '16px',
    s: '14px',
    xs: '12px',
    xxs: '10px',
  },
  paragraph: {
    xl: '24px',
    l: '18px',
    m: '16px',
    s: '14px',
    xs: '12px',
    xxs: '10px',
  },
};

export const lineHeight: VariantMap = {
  display: {
    l: '124px',
    m: '88px',
    s: '64px',
  },
  heading: {
    xl: '56px',
    l: '44px',
    m: '36px',
    s: '32px',
    xs: '32px',
    xxs: '24px',
  },
  label: {
    xl: '32px',
    l: '28px',
    m: '24px',
    s: '20px',
    xs: '20px',
    xxs: '16px',
  },
  paragraph: {
    xl: '32px',
    l: '24px',
    m: '24px',
    s: '20px',
    xs: '20px',
    xxs: '16px',
  },
};

export const letterSpacing: Pick<VariantMap, 'display' | 'heading' | 'label'> = {
  display: {
    l: '-0.0095em',
    m: '-0.0095em',
    s: '-0.0095em',
  },
  heading: {
    xl: '-0.0095em',
    l: '-0.0095em',
    m: '-0.0095em',
    s: '-0.0095em',
    xs: '-0.0095em',
    xxs: '-0.0095em',
  },
  label: {
    xs: '0.002em',
    xxs: '0.002em',
    s: '0.002em',
    m: '0.002em',
    l: '0.002em',
    xl: '0.002em',
  },
};

export const textMarginSize: VariantMap = {
  display: {
    l: spacing.s8,
    m: spacing.s6,
    s: spacing.s4,
  },
  heading: {
    xl: spacing.s3,
    l: spacing.s3,
    m: spacing.s3,
    s: spacing.s2,
    xs: spacing.s2,
    xxs: spacing.s2,
  },
  label: {
    xl: '0',
    l: '0',
    m: '0',
    s: '0',
    xs: '0',
    xxs: '0',
  },
  paragraph: {
    xl: spacing.s4,
    l: spacing.s3,
    m: spacing.s2,
    s: spacing.s2,
    xs: spacing.s2,
    xxs: spacing.s1,
  },
};

export const fontWeight = {
  bold: 700,
  semiBold: 600,
  medium: 500,
  normal: 400,
  light: 300,
};
