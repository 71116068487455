/* tslint:disable: arrow-return-shorthand */

import {
  CountryTools,
  DefaultToolsFunc,
  GetAgeFactoryFunc,
  GetAgeFunc,
  GetBirthdayFactoryFunc,
  GetBirthdayFactoryInput,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFactoryFunc,
  GetGenderFactoryInput,
  GetGenderFunc,
  GetYearFunc,
  IsCoordinationNoFunc,
  IsDNumberFunc,
  ValidateFactoryFunc,
  ValidateFunc
} from './interfaces'
import {
  calculateAge,
  validateDate
} from './utilities'

const defaultGetAge: GetAgeFunc = (): number => 0
const defaultGetBirthday: GetBirthdayFunc = (): string => ''
const defaultGetCensored: GetCensoredFunc = (): string => ''
const defaultGetCountry: GetCountryFunc = (): string => ''
const defaultGetGender: GetGenderFunc = (): string => ''
const defaultGetYear: GetYearFunc = (): number => 0
const defaultIsCoordinationNo: IsCoordinationNoFunc = (): boolean => false
const defaultIsDNumber: IsDNumberFunc = (): boolean => false
const defaultValidate: ValidateFunc = (): boolean => false

const getGenderFactory: GetGenderFactoryFunc = (parts: GetGenderFactoryInput): GetGenderFunc => {
  return (): string => {
    return (parseInt(parts.gender, 10) % 2) === 0 ? 'f' : 'm'
  }
}

const getBirthdayFactory: GetBirthdayFactoryFunc = (
  parts: GetBirthdayFactoryInput,
  getYear: GetYearFunc
): GetBirthdayFunc => {
  return (): string => {
    return `${getYear()}-${parts.month}-${parts.day}`
  }
}

const getAgeFactory: GetAgeFactoryFunc = (getBirthday: GetBirthdayFunc): GetAgeFunc => {
  return (today: string = ''): number => {
    return calculateAge(getBirthday(), today)
  }
}

const validateFactory: ValidateFactoryFunc = (
  personalNumber: string,
  regex: RegExp,
  parts: GetBirthdayFactoryInput,
  getYear: GetYearFunc,
  localValidate: ValidateFunc
): ValidateFunc => {
  return (): boolean => {
    return (
      // Not empty
      personalNumber.length > 0 &&
      // Has valid format
      regex.test(personalNumber) &&
      // Birthday is valid
      validateDate(parseInt(parts.day, 10), parseInt(parts.month, 10), getYear()) &&
      // Country specific validation
      localValidate()
    )
  }
}

const defaultTools: DefaultToolsFunc = (extraTools = { }): CountryTools => {
  return {
    getAge: defaultGetAge,
    getBirthday: defaultGetBirthday,
    getCensored: defaultGetCensored,
    getCountry: defaultGetCountry,
    getGender: defaultGetGender,
    getYear: defaultGetYear,
    ...extraTools,
    validate: defaultValidate
  }
}

export {
  defaultIsCoordinationNo,
  defaultIsDNumber,
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  getGenderFactory,
  validateFactory
}
