import type { MediumIcons } from '../Icon/types';
import { Card, IconBox } from './AppExtensionCard.styles';
import Icon from '../Icon/Icon';
import { Heading } from '../Text/Heading';
import { Text } from '../Text/Text';
import styled from 'styled-components';

export interface AppExtensionCardProps {
  onClick: VoidFunction;
  icon: MediumIcons;
  title: string;
  description: string;
}

export const AppExtensionCard = ({ onClick, icon, title, description }: AppExtensionCardProps) => {
  return (
    <Card onClick={onClick}>
      <IconBox>
        <Icon size="m" name={icon} fill="primary" />
      </IconBox>
      <Content>
        <Heading size="xs" noGutter>
          {title}
        </Heading>
        <Text size="s" color="tertiary">
          {description}
        </Text>
      </Content>
    </Card>
  );
};

const Content = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings[1]};
`;
