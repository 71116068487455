import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7024 3.15175C14.0328 3.40155 14.0981 3.87191 13.8483 4.20232L7.98003 11.9642C7.47517 12.632 6.74355 12.9984 5.98064 13C5.21768 13.0016 4.4846 12.6383 3.97737 11.9723L2.1534 9.5775C1.90243 9.24797 1.9661 8.77739 2.29562 8.52641C2.62514 8.27544 3.09573 8.33911 3.3467 8.66863L5.17068 11.0634C5.40876 11.376 5.70844 11.5006 5.97747 11.5C6.24655 11.4994 6.54615 11.3736 6.7835 11.0596L12.6518 3.2977C12.9016 2.96729 13.3719 2.90195 13.7024 3.15175Z"
        fill={fill}
      />
    </svg>
  );
}
