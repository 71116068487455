import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M43.5789 13.7143V11.4286C43.5789 8.39753 42.359 5.49062 40.1876 3.34735C38.0161 1.20408 35.0709 0 32 0C28.9291 0 25.9839 1.20408 23.8124 3.34735C21.641 5.49062 20.4211 8.39753 20.4211 11.4286V13.7143H10V64H54V13.7143H43.5789ZM22.7368 11.4286C22.7368 10.2279 22.9764 9.03901 23.442 7.92975C23.9075 6.82049 24.5898 5.81259 25.45 4.96359C26.3101 4.1146 27.3313 3.44114 28.4551 2.98167C29.579 2.5222 30.7835 2.28571 32 2.28571C33.2165 2.28571 34.421 2.5222 35.5449 2.98167C36.6687 3.44114 37.6899 4.1146 38.55 4.96359C39.4102 5.81259 40.0925 6.82049 40.558 7.92975C41.0236 9.03901 41.2632 10.2279 41.2632 11.4286V13.7143H22.7368V11.4286ZM51.6842 61.7143H12.3158V16H20.4211V27.4286H22.7368V16H41.2632V27.4286H43.5789V16H51.6842V61.7143Z"
      />
    </svg>
  );
}
