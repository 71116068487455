const grey = {
  black: 'hsl(0, 0%, 0%)',
  1: 'hsl(0, 0%, 6%)',
  2: 'hsl(0, 0%, 10%)',
  3: 'hsl(0, 0%, 13%)',
  4: 'hsl(0, 0%, 16%)',
  5: 'hsl(0, 0%, 21%)',
  6: 'hsl(0, 0%, 25%)',
  7: 'hsl(0, 0%, 35%)',
  8: 'hsl(0, 0%, 55%)',
  9: 'hsl(0, 0%, 70%)',
  10: 'hsl(0, 0%, 80%)',
  11: 'hsl(0, 0%, 84%)',
  12: 'hsl(0, 0%, 88%)',
  13: 'hsl(0, 0%, 91%)',
  14: 'hsl(0, 0%, 94%)',
  15: 'hsl(0, 0%, 97%)',
  white: 'hsl(0, 0%, 100%)',
};

const opacityBlack = {
  '1': 'hsla(0, 0%, 0%, 0.05)',
  '2': 'hsla(0, 0%, 0%, 0.1)',
  '3': 'hsla(0, 0%, 0%, 0.15)',
  '4': 'hsla(0, 0%, 0%, 0.20)',
  '5': 'hsla(0, 0%, 0%, 0.25)',
  '6': 'hsla(0, 0%, 0%, 0.30)',
  '7': 'hsla(0, 0%, 0%, 0.40)',
  '8': 'hsla(0, 0%, 0%, 0.50)',
  '9': 'hsla(0, 0%, 0%, 0.60)',
  '10': 'hsla(0, 0%, 0%, 0.70)',
  '11': 'hsla(0, 0%, 0%, 0.75)',
  '12': 'hsla(0, 0%, 0%, 0.80)',
  '13': 'hsla(0, 0%, 0%, 0.85)',
  '14': 'hsla(0, 0%, 0%, 0.90)',
  '15': 'hsla(0, 0%, 0%, 0.95)',
};

const opacityWhite = {
  '1': 'hsla(0, 0%, 100%, .05)',
  '2': 'hsla(0, 0%, 100%, .10)',
  '3': 'hsla(0, 0%, 100%, .15)',
  '4': 'hsla(0, 0%, 100%, .20)',
  '5': 'hsla(0, 0%, 100%, .25)',
  '6': 'hsla(0, 0%, 100%, .30)',
  '7': 'hsla(0, 0%, 100%, .40)',
  '8': 'hsla(0, 0%, 100%, .50)',
  '9': 'hsla(0, 0%, 100%, .60)',
  '10': 'hsla(0, 0%, 100%, .70)',
  '11': 'hsla(0, 0%, 100%, .75)',
  '12': 'hsla(0, 0%, 100%, .80)',
  '13': 'hsla(0, 0%, 100%, .85)',
  '14': 'hsla(0, 0%, 100%, .90)',
  '15': 'hsla(0, 0%, 100%, .95)',
};

const red = {
  '1': 'hsl(7, 80%, 2%)',
  '2': 'hsl(7, 80%, 6%)',
  '3': 'hsl(7, 80%, 10%)',
  '4': 'hsl(7, 80%, 17%)',
  '5': 'hsl(7, 80%, 24%)',
  '6': 'hsl(7, 80%, 32%)',
  '7': 'hsl(7, 80%, 43%)',
  '8': 'hsl(7, 80%, 54%)',
  '9': 'hsl(7, 80%, 60%)',
  '10': 'hsl(7, 80%, 72%)',
  '11': 'hsl(7, 80%, 79%)',
  '12': 'hsl(7, 80%, 85%)',
  '13': 'hsl(7, 80%, 91%)',
  '14': 'hsl(7, 80%, 95%)',
  '15': 'hsl(7, 80%, 99%)',
};

const yellow = {
  '1': 'hsl(44, 92%, 2%)',
  '2': 'hsl(44, 92%, 6%)',
  '3': 'hsl(44, 92%, 10%)',
  '4': 'hsl(44, 92%, 17%)',
  '5': 'hsl(44, 92%, 24%)',
  '6': 'hsl(44, 92%, 32%)',
  '7': 'hsl(44, 92%, 43%)',
  '8': 'hsl(44, 92%, 54%)',
  '9': 'hsl(44, 92%, 60%)',
  '10': 'hsl(44, 92%, 72%)',
  '11': 'hsl(44, 92%, 79%)',
  '12': 'hsl(44, 92%, 85%)',
  '13': 'hsl(44, 92%, 91%)',
  '14': 'hsl(44, 92%, 95%)',
  '15': 'hsl(44, 92%, 99%)',
};

const green = {
  '1': 'hsl(148, 61%, 2%)',
  '2': 'hsl(148, 61%, 6%)',
  '3': 'hsl(148, 61%, 10%)',
  '4': 'hsl(148, 61%, 17%)',
  '5': 'hsl(148, 61%, 24%)',
  '6': 'hsl(148, 61%, 32%)',
  '7': 'hsl(148, 61%, 43%)',
  '8': 'hsl(148, 61%, 54%)',
  '9': 'hsl(148, 61%, 60%)',
  '10': 'hsl(148, 61%, 72%)',
  '11': 'hsl(148, 61%, 79%)',
  '12': 'hsl(148, 61%, 85%)',
  '13': 'hsl(148, 61%, 91%)',
  '14': 'hsl(148, 61%, 95%)',
  '15': 'hsl(148, 61%, 99%)',
};

const blue = {
  '1': 'hsl(222, 62%, 2%)',
  '2': 'hsl(222, 62%, 6%)',
  '3': 'hsl(222, 62%, 10%)',
  '4': 'hsl(222, 62%, 17%)',
  '5': 'hsl(222, 62%, 24%)',
  '6': 'hsl(222, 62%, 32%)',
  '7': 'hsl(222, 62%, 43%)',
  '8': 'hsl(222, 62%, 54%)',
  '9': 'hsl(222, 62%, 60%)',
  '10': 'hsl(222, 62%, 72%)',
  '11': 'hsl(222, 62%, 79%)',
  '12': 'hsl(222, 62%, 85%)',
  '13': 'hsl(222, 62%, 91%)',
  '14': 'hsl(222, 62%, 95%)',
  '15': 'hsl(222, 62%, 99%)',
};

const purple = {
  '1': 'hsl(275, 90%, 2%)',
  '2': 'hsl(275, 90%, 6%)',
  '3': 'hsl(275, 90%, 10%)',
  '4': 'hsl(275, 90%, 17%)',
  '5': 'hsl(275, 90%, 24%)',
  '6': 'hsl(275, 90%, 32%)',
  '7': 'hsl(275, 90%, 43%)',
  '8': 'hsl(275, 90%, 54%)',
  '9': 'hsl(275, 90%, 60%)',
  '10': 'hsl(275, 90%, 72%)',
  '11': 'hsl(275, 90%, 79%)',
  '12': 'hsl(275, 90%, 85%)',
  '13': 'hsl(275, 90%, 91%)',
  '14': 'hsl(275, 90%, 95%)',
  '15': 'hsl(275, 90%, 99%)',
};

const cyan = {
  '1': 'hsl(171, 70%, 2%)',
  '2': 'hsl(171, 70%, 6%)',
  '3': 'hsl(171, 70%, 10%)',
  '4': 'hsl(171, 70%, 17%)',
  '5': 'hsl(171, 70%, 24%)',
  '6': 'hsl(171, 70%, 32%)',
  '7': 'hsl(171, 70%, 43%)',
  '8': 'hsl(171, 70%, 54%)',
  '9': 'hsl(171, 70%, 60%)',
  '10': 'hsl(171, 70%, 72%)',
  '11': 'hsl(171, 70%, 79%)',
  '12': 'hsl(171, 70%, 85%)',
  '13': 'hsl(171, 70%, 91%)',
  '14': 'hsl(171, 70%, 95%)',
  '15': 'hsl(171, 70%, 99%)',
};

const baseColors = {
  grey,
  opacityBlack,
  opacityWhite,
  green,
  purple,
  red,
  cyan,
  blue,
  yellow,
};

export default baseColors;
