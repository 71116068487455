import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/common.json';
import da from './locales/da/common.json';
import es from './locales/es/common.json';
import sv from './locales/sv/common.json';

const i18nVault = i18next.createInstance();

i18nVault
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
      },
      da: {
        translation: da,
      },
      es: {
        translation: es,
      },
      sv: {
        translation: sv,
      },
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  });

export { i18nVault };
