import { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../Text/Text';
import { Checkbox } from '../Inputs/Checkbox/Checkbox';
import Icon from '../Icon/Icon';

export interface MobileListItem {
  id: string;
  title?: string;
  details?: string[];
  value?: string;
  additionalInfo?: string;
  thumbnail?: string;
}

export interface MobileListProps {
  items: MobileListItem[];
  showThumbnail?: boolean;
  showIndex?: boolean;
  onClick?: (id: string) => void;
  onSelectionChange?: (ids: string[]) => void;
}

export default function MobileList({ items, showThumbnail, showIndex, onClick, onSelectionChange }: MobileListProps) {
  const [selectedElements, setSelectedElements] = useState<string[]>([]);

  const updateSelectedIdsAndEmit = (id: string) => {
    const index = selectedElements.indexOf(id);
    let selectedElementsCopy = [...selectedElements];

    if (index < 0) {
      selectedElementsCopy = [...selectedElementsCopy, id];
      setSelectedElements(selectedElementsCopy);
    } else {
      setSelectedElements(selectedElementsCopy.splice(index, 1));
    }

    onSelectionChange && onSelectionChange(selectedElementsCopy);
  };

  return (
    <List as={showIndex ? 'ol' : 'ul'}>
      {items.map(({ id, title, details, value, additionalInfo, thumbnail }, index) => (
        <ListItem key={id}>
          <BasicInfo>
            {onSelectionChange && (
              <Checkbox
                testId={`mobile-list-item-checkbox-${id}`}
                label={`${title} checkbox`}
                inputProps={{ value: 'false', name: `mobile-list-item-checkbox-${id}` }}
                onChange={() => updateSelectedIdsAndEmit(id)}
                hideLabel
              />
            )}
            {showIndex && <Text size="s">{index}</Text>}
            {showThumbnail && <Thumbnail>{thumbnail && <img src={thumbnail} alt="" />}</Thumbnail>}
            <TextInfo>
              <Text>{title}</Text>
              <Text size="xs" color="tertiary">
                {details?.join(' · ')}
              </Text>
            </TextInfo>
          </BasicInfo>

          <AdditionalInfo>
            <TextInfo>
              <Text size="s">{value}</Text>
              <Text size="xs">{additionalInfo}</Text>
            </TextInfo>
            {onClick && (
              <GoToButton
                data-testid={`mobile-list-item-action-btn-${id}`}
                onClick={() => onClick && onClick(id)}
                aria-label={`Click on element with id: ${id}`}
              >
                <Icon size="s" name="chevronRight" />
              </GoToButton>
            )}
          </AdditionalInfo>
        </ListItem>
      ))}
    </List>
  );
}

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: ${({ theme }) => theme.spacings[1]} 0;
  box-sizing: border-box;
`;

const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1]};
  width: 50%;
`;

const TextInfo = styled.div`
  overflow: hidden;
  display: grid;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[2]};
  text-align: right;
`;

const Thumbnail = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.background.tertiaryBg};
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const GoToButton = styled.button`
  border: none;
  background: none;
  padding: 0;
`;

const List = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style-type: none;

  ${ListItem} {
    border-bottom: ${({ theme }) => `1px solid ${theme.color.border.disabledBorder}`};
  }

  ${ListItem}:last-of-type {
    border-bottom: none;
  }
`;
