import { IconSVGProps } from '../types';

export default function SVGComponentAddImage({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 4C5.23122 4 4 5.23122 4 6.75V13.5954V15.8475C4 17.3663 5.23122 18.5975 6.75 18.5975H10.5C10.9142 18.5975 11.25 18.2617 11.25 17.8475C11.25 17.4333 10.9142 17.0975 10.5 17.0975H6.75C6.05964 17.0975 5.5 16.5378 5.5 15.8475V13.8652L8.82718 9.83879L10.3352 11.7565L12.0008 13.963C12.2504 14.2936 12.7207 14.3593 13.0513 14.1098C13.3819 13.8602 13.4475 13.3899 13.198 13.0593L11.8898 11.3263L14.7112 8.00411L18.2326 11.8082C18.4423 12.0347 18.7693 12.1096 19.0566 11.9971C19.344 11.8845 19.533 11.6074 19.533 11.2987V6.75C19.533 5.23122 18.3018 4 16.783 4H6.75ZM8.26352 8.16612L5.5 11.5104V6.75C5.5 6.05964 6.05964 5.5 6.75 5.5H16.783C17.4734 5.5 18.033 6.05964 18.033 6.75V9.38447L15.2374 6.36445C15.0917 6.20701 14.8855 6.11952 14.671 6.12411C14.4565 6.1287 14.2542 6.22492 14.1153 6.38845L10.9516 10.1138L9.43123 8.18027C9.291 8.00195 9.0776 7.89667 8.85076 7.89392C8.62392 7.89117 8.40802 7.99124 8.26352 8.16612ZM16.4431 12.8872C16.8573 12.8872 17.1931 13.223 17.1931 13.6372L17.1931 15.6939H19.2501C19.6644 15.6939 20.0001 16.0297 20.0001 16.4439C20.0001 16.8581 19.6644 17.1939 19.2501 17.1939H17.1931V19.2504C17.1931 19.6646 16.8573 20.0004 16.4431 20.0004C16.0289 20.0004 15.6931 19.6646 15.6931 19.2504V17.1939H13.6369C13.2227 17.1939 12.8869 16.8581 12.8869 16.4439C12.8869 16.0297 13.2227 15.6939 13.6369 15.6939H15.6931L15.6931 13.6372C15.6931 13.223 16.0289 12.8872 16.4431 12.8872Z"
        fill={fill}
      />
    </svg>
  );
}
