import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="13"
        y="13"
        width="9"
        height="8"
        rx="2"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 13V15.5C19 15.7761 18.7761 16 18.5 16H16.5C16.2239 16 16 15.7761 16 15.5V13"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51385 14.9999H5.99998C7.10455 14.9999 7.99999 14.1045 7.99999 12.9999V10.9999C7.99999 9.89535 8.89542 8.99992 9.99999 8.99992H12C13.1046 8.99992 14 8.10449 14 6.99992V3.22461"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7693 10C19.7811 5.65875 15.7662 2.6932 11.3263 3.02506C6.88635 3.35691 3.35691 6.88636 3.02505 11.3263C2.6932 15.7662 5.65876 19.7811 10 20.7693"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
