import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M50.4615 0H2V64H62V0H50.4615ZM48.1538 2.28571V11.4286C48.1538 13.7353 45.8674 16 43.5385 16C41.2095 16 38.9231 13.7353 38.9231 11.4286V2.28571H48.1538ZM36.6154 2.28571V11.4286C36.6154 13.7353 34.3289 16 32 16C29.6711 16 27.3846 13.7353 27.3846 11.4286V2.28571H36.6154ZM25.0769 2.28571V11.4286C25.0769 13.7353 22.7905 16 20.4615 16C18.1326 16 15.8462 13.7353 15.8462 11.4286V2.28571H25.0769ZM4.30769 2.28571H13.5385V11.4286C13.5385 13.7353 11.252 16 8.92308 16C6.59415 16 4.30769 13.7353 4.30769 11.4286V2.28571ZM35.4615 61.7143V32H50.5077V61.7143H35.4615ZM52.8154 61.7143V29.7143H33.1538V61.7143H4.30769V16.395C5.56215 17.5488 7.20615 18.2839 8.92308 18.2839C11.2363 18.2839 13.4175 16.9499 14.6923 15.051C15.9671 16.9499 18.1474 18.2839 20.4615 18.2839C22.7757 18.2839 24.956 16.9499 26.2308 15.051C27.5055 16.9499 29.6858 18.2839 32 18.2839C34.3142 18.2839 36.4945 16.9499 37.7692 15.051C39.044 16.9499 41.2243 18.2839 43.5385 18.2839C45.8526 18.2839 48.0329 16.9499 49.3077 15.051C50.5825 16.9499 52.7628 18.2839 55.0769 18.2839C56.7938 18.2839 58.4369 17.5525 59.6923 16.395V61.7143H52.8154ZM55.0732 16C52.7434 16 50.4578 13.7353 50.4578 11.4286V2.28571H59.6886V11.4286C59.6923 13.7353 57.4058 16 55.0769 16H55.0732Z"
      />
      <path fill={fill} d="M10.0769 45.7143H26.2308V29.7143H10.0769V45.7143ZM12.3846 32H23.9231V43.4286H12.3846V32Z" />
    </svg>
  );
}
