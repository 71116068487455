import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32492 6.88641H14.2665C15.7853 6.88641 17.0165 8.11763 17.0165 9.63641V16.1136H17.1698C17.8602 16.1136 18.4198 15.554 18.4198 14.8636V7C18.4198 6.30964 17.8602 5.75 17.1698 5.75H9.56982C8.91776 5.75 8.3823 6.24929 8.32492 6.88641ZM17.0142 17.6136C16.9546 19.0797 15.7472 20.25 14.2665 20.25H6.6665C5.14772 20.25 3.9165 19.0188 3.9165 17.5V9.63641C3.9165 8.11763 5.14772 6.88641 6.6665 6.88641H6.82213C6.88169 5.4203 8.08909 4.25 9.56982 4.25H17.1698C18.6886 4.25 19.9198 5.48122 19.9198 7V14.8636C19.9198 16.3824 18.6886 17.6136 17.1698 17.6136H17.0142ZM5.4165 9.63641C5.4165 8.94606 5.97615 8.38641 6.6665 8.38641H14.2665C14.9569 8.38641 15.5165 8.94606 15.5165 9.63641V17.5001C15.5165 18.1904 14.9569 18.75 14.2665 18.75H6.6665C5.97615 18.75 5.4165 18.1904 5.4165 17.5V9.63641ZM7 10.9318C7 10.5176 7.33579 10.1818 7.75 10.1818H13.25C13.6642 10.1818 14 10.5176 14 10.9318C14 11.346 13.6642 11.6818 13.25 11.6818H7.75C7.33579 11.6818 7 11.346 7 10.9318ZM7.75 13.4545C7.33579 13.4545 7 13.7903 7 14.2045C7 14.6187 7.33579 14.9545 7.75 14.9545H10.65C11.0642 14.9545 11.4 14.6187 11.4 14.2045C11.4 13.7903 11.0642 13.4545 10.65 13.4545H7.75Z"
        fill={fill}
      />
    </svg>
  );
}
