import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24935 19.8084C7.94094 19.5319 7.91507 19.0578 8.19158 18.7493L14.2427 12L8.19158 5.25066C7.91507 4.94225 7.94094 4.46808 8.24935 4.19157C8.55776 3.91507 9.03193 3.94093 9.30843 4.24934L15.8084 11.4993C16.0639 11.7842 16.0639 12.2158 15.8084 12.5007L9.30843 19.7507C9.03193 20.0591 8.55776 20.0849 8.24935 19.8084Z"
        fill={fill}
      />
    </svg>
  );
}
