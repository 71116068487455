import { Link } from '../Link/Link';
import { Text } from '../Text/Text';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { HamburgerMenuItem, MENU_ANIMATION_DURATION } from './HamburgerMenu';
import Icon from '../Icon/Icon';

export interface HamburgerSubMenuProps {
  menuItem: ReactNode;
  children: ReactNode;
  setIsSubmenuVisible?: (isVisible: boolean) => boolean;
  backLabel?: string;
}

export const HamburgerSubMenu = ({ children, menuItem, setIsSubmenuVisible, backLabel }: HamburgerSubMenuProps) => {
  const [isActive, setIsActive] = useState(false);
  const transitionSubmenu = (isVisible: boolean) => {
    setTimeout(() => setIsActive(isVisible), isVisible ? 0 : MENU_ANIMATION_DURATION);
    setIsSubmenuVisible && setIsSubmenuVisible(isVisible);
  };

  return (
    <>
      <HamburgerSubMenuItem onClick={() => transitionSubmenu(true)}>
        {menuItem}
        <Icon name="chevronRight" size="s" />
      </HamburgerSubMenuItem>
      <HamburgerSubMenuContent isActive={isActive}>
        {isActive && (
          <>
            <HamburgerMenuItem>
              <Link onClick={() => transitionSubmenu(false)}>
                <Icon name="chevronLeft" size="s" />
                {backLabel && <Text>{backLabel}</Text>}
              </Link>
            </HamburgerMenuItem>
            {children}
          </>
        )}
      </HamburgerSubMenuContent>
    </>
  );
};

const HamburgerSubMenuItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings['1']};

  > :first-child {
    flex: 1;
  }
`;

const HamburgerSubMenuContent = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  padding: ${({ theme, isActive }) => (isActive ? theme.spacings['2'] : '0')};
  background: ${({ theme }) => theme.color.background.primaryBg};
`;
