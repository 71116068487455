import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0926 5.42517H16.2523C18.3233 5.42517 20.0023 7.1041 20.0023 9.17517V11.8765C20.0023 13.8608 18.4611 15.4851 16.5105 15.6178L16.8975 17.9398C17.0753 19.0065 16.2527 19.9775 15.1713 19.9775H8.83091C7.74951 19.9775 6.92694 19.0065 7.10472 17.9398L7.49172 15.6178C5.54113 15.4851 4 13.8608 4 11.8765V9.17517C4 7.1041 5.67893 5.42517 7.75 5.42517H9.90964C10.1566 4.60078 10.921 4 11.8258 4H12.1765C13.0812 4 13.8457 4.60078 14.0926 5.42517ZM7.75 6.92517C6.50736 6.92517 5.5 7.93253 5.5 9.17517V11.8765C5.5 13.1192 6.50736 14.1265 7.75 14.1265H16.2523C17.4949 14.1265 18.5023 13.1192 18.5023 11.8765V9.17517C18.5023 7.93253 17.4949 6.92517 16.2523 6.92517H7.75ZM9.01091 15.6268L8.58431 18.1864C8.55891 18.3388 8.67642 18.4775 8.83091 18.4775H15.1713C15.3258 18.4775 15.4433 18.3388 15.4179 18.1864L14.9914 15.6268H9.01091Z"
        fill={fill}
      />
    </svg>
  );
}
