import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM14.7866 9.22449C15.0795 9.51739 15.0795 9.99226 14.7866 10.2852L13.0662 12.0055L14.7866 13.7258C15.0795 14.0187 15.0795 14.4936 14.7866 14.7865C14.4937 15.0794 14.0188 15.0794 13.7259 14.7865L12.0056 13.0662L10.2852 14.7865C9.99232 15.0794 9.51745 15.0794 9.22455 14.7865C8.93166 14.4936 8.93166 14.0187 9.22455 13.7258L10.9449 12.0055L9.22455 10.2852C8.93166 9.99226 8.93166 9.51739 9.22455 9.22449C9.51745 8.9316 9.99232 8.9316 10.2852 9.22449L12.0056 10.9448L13.7259 9.22449C14.0188 8.9316 14.4937 8.9316 14.7866 9.22449Z"
        fill={fill}
      />
    </svg>
  );
}
