import { MouseEvent, ReactNode, useCallback } from 'react';
import { TextSize } from 'typography';
import { TextStyled } from '../Text/Text';
import styled from 'styled-components';
import { ForegroundColors } from 'theme/colors';
import { Text } from '../Text/Text';

const BaseLinkStyled = styled(TextStyled)<{ color: ForegroundColors }>`
  color: ${({ theme, color }) => theme.color[color]};
  :hover {
    text-decoration: none;
  }
`;

const ButtonLinkStyled = styled(BaseLinkStyled)`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;

type HrefOrTo =
  | { to?: never; href?: string; onClick?: never }
  | { href?: never; to?: never; onClick?: (event: MouseEvent) => void };

export type LinkProps = HrefOrTo & {
  /**
   * Passed down to anchor tag. Defines relationship to linked page.
   */
  rel?: string;
  /**
   * Passed down to anchor tag. Defines how link is opened.
   */
  target?: '_self' | '_blank' | '_parent' | '_top';
  /**
   * Define size of the Paragraph. You can use only predefined values.
   */
  size?: TextSize;
  /**
   * Content of the header.
   */
  children: ReactNode;
  color?: ForegroundColors;
};

export const Link = ({ size = 'm', href, to, onClick, color = 'linkNormal', rel, children, ...props }: LinkProps) => {
  const onLinkClick = useCallback(
    (event: MouseEvent) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  const isButton = href === undefined && to === undefined;
  if (isButton) {
    return (
      <ButtonLinkStyled as="button" type="button" onClick={onClick} size={size} {...props} color={color}>
        {children}
      </ButtonLinkStyled>
    );
  }

  const isExternalLink =
    href !== undefined && to === undefined && (href.startsWith('http:') || href.startsWith('https:'));

  return (
    <BaseLinkStyled
      href={href || '#'}
      as="a"
      onClick={onLinkClick}
      size={size}
      color={color}
      rel={isExternalLink ? rel || 'noopener noreferrer' : rel}
      {...props}
    >
      {children}
      {isExternalLink && (
        <>
          &nbsp;
          <Text size={size} color={color}>
            &#8599;
          </Text>
        </>
      )}
    </BaseLinkStyled>
  );
};
