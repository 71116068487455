import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82786 4.50714C10.2874 4.04759 11.0325 4.04759 11.492 4.50714C11.9516 4.96668 11.9516 5.71176 11.492 6.17131L10.862 6.80136L9.19781 5.13719L9.82786 4.50714ZM8.13715 6.19785L4.53877 9.79623L4.12272 11.8764L6.20294 11.4604L9.80132 7.86202L8.13715 6.19785ZM12.5527 3.44648C11.5074 2.40114 9.81253 2.40114 8.7672 3.44648L3.31753 8.89614C3.21283 9.00084 3.14147 9.13419 3.11243 9.27938L2.43122 12.6854C2.38204 12.9313 2.45901 13.1855 2.63633 13.3628C2.81364 13.5402 3.06785 13.6171 3.31374 13.5679L6.71978 12.8867C6.86498 12.8577 6.99833 12.7863 7.10303 12.6816L12.5527 7.23197C13.598 6.18663 13.598 4.49181 12.5527 3.44648Z"
        fill={fill}
      />
    </svg>
  );
}
