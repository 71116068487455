import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19158 8.99935C4.46809 8.69094 4.94225 8.66507 5.25067 8.94158L12 14.9927L18.7493 8.94158C19.0578 8.66507 19.5319 8.69094 19.8084 8.99935C20.0849 9.30776 20.0591 9.78193 19.7507 10.0584L12.5007 16.5584C12.2158 16.8139 11.7843 16.8139 11.4993 16.5584L4.24935 10.0584C3.94094 9.78193 3.91507 9.30776 4.19158 8.99935Z"
        fill={fill}
      />
    </svg>
  );
}
