import React, { ForwardedRef, RefObject, useRef, useState } from 'react';
import {
  ClearButton,
  Currency,
  Suffix,
  Input,
  InputButton,
  InputIndicatorsContainer,
  TextArea,
  MaskedInput,
  InputWrapperContainer,
} from '../InputBase/InputBase.styles';
import { InputWrapper } from '../InputWrapper';
import { InputProps } from '../InputBase/InputBaseTypes';
import Icon from '../../../Icon/Icon';
import { LocaleFormattedInput } from './LocaleFormattedInput';

export interface MaskConfig {
  mask: string | (string | RegExp)[];
  maskChar?: string;
  alwaysShowMask?: boolean;
}

export interface TextInputProps extends InputProps {
  type?: 'text' | 'email' | 'url' | 'number' | 'password' | 'date';
  locale?: string;
  localeFormatted?: boolean;
  showUnformattedValue?: boolean;
  maskConfig?: MaskConfig;
}

export const TextInput = React.forwardRef(
  (
    {
      name,
      active = false,
      disabled = false,
      required,
      infoText,
      errorText,
      hintText,
      warningText,
      label,
      testId,
      textArea,
      currency,
      suffix,
      type = 'text',
      onBlur,
      onFocus,
      onClear,
      setValue,
      normalizeValue,
      onChange,
      trimOnBlur,
      placeholder,
      displayClearButton,
      leftIndicatorIcon,
      rightIndicatorIcon,
      labelVisuallyHidden = false,
      inputProps,
      maskConfig,
      locale,
      localeFormatted,
      showUnformattedValue,
      ...restOfProps
    }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const inputRef = useRef(ref) as RefObject<HTMLInputElement>;
    const [isSecret, setIsSecret] = useState(() => type === 'password');
    const [isFocus, setIsFocus] = useState(false);
    const iconColor = disabled ? 'disabled' : 'primary';
    const isLocaleFormatted = (type === 'number' && localeFormatted !== false) || localeFormatted;

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocus(true);
      onFocus && onFocus(event);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (normalizeValue) {
        event.target.value = normalizeValue(event.target.value || '');
      }

      if (onChange) {
        onChange(event);
      }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (trimOnBlur && setValue) {
        setValue((event.target.value || '').trim());
      }
      setIsFocus(false);
      onBlur && onBlur(event);
    };

    const handleClick = (_event: React.MouseEvent<HTMLInputElement>) => {
      setIsFocus(true);
      // temporary fix to exclude masked inputs focus on click because of different type of ref object being passed in this case
      if (!maskConfig) inputRef.current?.focus();
    };

    const elementProps = {
      ...restOfProps,
      required: Boolean(required),
      disabled,
      id: name,
      name,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange: handleChange,
      placeholder,
      type: (() => {
        if (type === 'password' && !isSecret) return 'text';
        else return type;
      })(),
      'data-testid': testId,
      ...inputProps,
    };

    return (
      <InputWrapper
        active={active}
        disabled={disabled}
        errorText={errorText}
        hintText={hintText}
        warningText={warningText}
        infoText={infoText}
        focus={isFocus}
        inputId={`${name}${isLocaleFormatted ? '.formatted' : ''}`}
        label={label}
        required={required}
        visuallyHidden={labelVisuallyHidden}
        onClick={handleClick}
      >
        <InputWrapperContainer>
          <InputIndicatorsContainer location="left" onClick={handleClick}>
            {leftIndicatorIcon && <Icon name={leftIndicatorIcon} size="m" color={iconColor} />}
          </InputIndicatorsContainer>

          {!textArea ? (
            maskConfig ? (
              <MaskedInput {...elementProps} mask={maskConfig.mask} inputRef={inputRef} />
            ) : isLocaleFormatted ? (
              <LocaleFormattedInput
                {...elementProps}
                ref={inputRef}
                locale={locale}
                showUnformattedValue={showUnformattedValue}
              />
            ) : (
              <Input {...elementProps} ref={inputRef} />
            )
          ) : (
            <TextArea {...elementProps} ref={inputRef} />
          )}

          <InputIndicatorsContainer location="right">
            {displayClearButton && (
              <ClearButton onClick={onClear} data-testid={`${testId ? testId : name}-clear`}>
                <Icon name="close" size="m" color={iconColor} />
              </ClearButton>
            )}
            {type === 'password' && (
              <InputButton
                onClick={() => {
                  setIsSecret(!isSecret);
                }}
                data-testid={`${testId}-show-password`}
              >
                <Icon name={isSecret ? 'show' : 'hide'} size="m" color={iconColor} />
              </InputButton>
            )}
            {rightIndicatorIcon && <Icon name={rightIndicatorIcon} size="s" color={iconColor} />}
            {suffix && <Suffix>{suffix}</Suffix>}
            {currency && <Currency>{currency}</Currency>}
          </InputIndicatorsContainer>
        </InputWrapperContainer>
      </InputWrapper>
    );
  }
);
