import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.63269" y="2.52829" width="61.7668" height="2.5" transform="rotate(45 2.63269 2.52829)" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5886 11.9102C11.2153 12.5184 11 13.2341 11 14V43C11 45.2091 12.7909 47 15 47H33C35.2091 47 37 45.2091 37 43V37.3216L34.5 34.8216V43C34.5 43.8284 33.8284 44.5 33 44.5H15C14.1716 44.5 13.5 43.8284 13.5 43L13.5 14C13.5 13.9429 13.5032 13.8865 13.5094 13.831L11.5886 11.9102ZM16.0884 16.41C16.031 16.5965 16 16.7946 16 17V21C16 22.1046 16.8954 23 18 23H22.6784L16.0884 16.41ZM27 23L19 15H30C31.1046 15 32 15.8954 32 17V21C32 22.1046 31.1046 23 30 23H27ZM29.6475 29.969C28.8255 29.8228 28.1772 29.1745 28.031 28.3526L29.6475 29.969ZM30 26L32 28C32 26.8954 31.1046 26 30 26ZM34.5 30.5L34.5 14C34.5 13.1716 33.8284 12.5 33 12.5L16.5 12.5L14.1013 10.1013C14.3902 10.035 14.691 10 15 10H33C35.2091 10 37 11.7909 37 14L37 33L34.5 30.5ZM24 26C22.8954 26 22 26.8954 22 28C22 29.1046 22.8954 30 24 30C25.1046 30 26 29.1046 26 28C26 26.8954 25.1046 26 24 26ZM30 32C28.8954 32 28 32.8954 28 34V40C28 41.1046 28.8954 42 30 42C31.1046 42 32 41.1046 32 40V34C32 32.8954 31.1046 32 30 32ZM22 40C22 38.8954 22.8954 38 24 38C25.1046 38 26 38.8954 26 40C26 41.1046 25.1046 42 24 42C22.8954 42 22 41.1046 22 40ZM16 40C16 38.8954 16.8954 38 18 38C19.1046 38 20 38.8954 20 40C20 41.1046 19.1046 42 18 42C16.8954 42 16 41.1046 16 40ZM22 34C22 32.8954 22.8954 32 24 32C25.1046 32 26 32.8954 26 34C26 35.1046 25.1046 36 24 36C22.8954 36 22 35.1046 22 34ZM18 32C16.8954 32 16 32.8954 16 34C16 35.1046 16.8954 36 18 36C19.1046 36 20 35.1046 20 34C20 32.8954 19.1046 32 18 32ZM18 26C16.8954 26 16 26.8954 16 28C16 29.1046 16.8954 30 18 30C19.1046 30 20 29.1046 20 28C20 26.8954 19.1046 26 18 26Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6566 1C30.4515 1 31.9066 2.45508 31.9066 4.25L31.9066 10H29.4066L29.4066 4.25C29.4066 3.83579 29.0708 3.5 28.6566 3.5L27.0031 3.5V10H24.0031L24.0031 3.5L19.256 3.5C18.8418 3.5 18.506 3.83579 18.506 4.25L18.506 10H16.006V4.25C16.006 2.45508 17.4611 1 19.256 1H28.6566Z"
        fill={fill}
      />
    </svg>
  );
}
