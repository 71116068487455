import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19182 12.2506C7.93639 11.9657 7.93639 11.5342 8.19182 11.2493L14.6918 3.99933C14.9683 3.69092 15.4425 3.66505 15.7509 3.94156C16.0593 4.21806 16.0852 4.69223 15.8087 5.00064L9.75754 11.75L15.8087 18.4993C16.0852 18.8077 16.0593 19.2819 15.7509 19.5584C15.4425 19.8349 14.9683 19.8091 14.6918 19.5006L8.19182 12.2506Z"
        fill={fill}
      />
    </svg>
  );
}
