import styled from 'styled-components';
import { baseToPx } from '../../spacings';

export const Card = styled.a`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings['2']};

  border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  border-radius: ${({ theme }) => theme.spacings['1']};
  padding: ${({ theme }) => theme.spacings['3']};
  max-width: ${baseToPx(31)};

  text-decoration: none;
  color: ${({ theme }) => theme.color.primary};

  &:hover,
  &:focus {
    box-shadow: ${({ theme }) =>
      `0 ${theme.spacings['0.5']} ${theme.spacings['1']} ${theme.color.base.opacityBlack['4']}`};
    cursor: pointer;
  }
`;

export const IconBox = styled.div`
  background-color: ${({ theme }) => theme.color.background.altBg};
  border-radius: ${({ theme }) => theme.spacings['1']};
  width: ${({ theme }) => theme.spacings['6']};
  height: ${({ theme }) => theme.spacings['6']};
  display: flex;
  align-items: center;
  justify-content: center;
`;
