import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9805 11.21C14.9805 12.708 15.4382 14.098 16.2213 15.2498H8.49994C5.46237 15.2498 2.99994 17.7122 2.99994 20.7498V46.5005C2.99994 49.538 5.46237 52.0005 8.49993 52.0005H47.5003C50.5378 52.0005 53.0003 49.538 53.0003 46.5005V20.7498C53.0003 17.7122 50.5378 15.2498 47.5003 15.2498H39.7786C40.5617 14.098 41.0195 12.708 41.0195 11.21C41.0195 7.22245 37.7758 4 33.787 4C31.4228 4 29.3204 5.13201 28 6.88439C26.6795 5.13201 24.5771 4 22.213 4C18.2241 4 14.9805 7.22245 14.9805 11.21ZM22.213 7C19.8698 7 17.9805 8.89043 17.9805 11.21C17.9805 13.1182 19.2592 14.736 21.0173 15.2498H23.4093C24.0963 15.049 24.7102 14.6793 25.2043 14.1883C25.9734 13.424 26.4455 12.3728 26.4455 11.21C26.4455 8.89043 24.5561 7 22.213 7ZM34.9826 15.2498H32.5906C31.9036 15.049 31.2897 14.6793 30.7956 14.1883C30.0265 13.424 29.5544 12.3728 29.5544 11.21C29.5544 8.89043 31.4438 7 33.787 7C36.1301 7 38.0195 8.89043 38.0195 11.21C38.0195 13.1182 36.7407 14.736 34.9826 15.2498ZM24.2085 18.2498H23.7811C23.276 18.3612 22.7512 18.4199 22.213 18.4199C21.6744 18.4199 21.1494 18.3612 20.6442 18.2498H8.49994C7.11923 18.2498 5.99994 19.369 5.99994 20.7498V35.2503H50.0003V20.7498C50.0003 19.369 48.881 18.2498 47.5003 18.2498H35.3557C34.8505 18.3612 34.3255 18.4199 33.787 18.4199C33.2487 18.4199 32.7239 18.3612 32.2188 18.2498H31.7915L37.7638 23.6759C38.3769 24.233 38.4224 25.1816 37.8653 25.7948C37.3082 26.408 36.3596 26.4534 35.7464 25.8963L28 18.8583L20.2535 25.8963C19.6403 26.4534 18.6917 26.408 18.1346 25.7948C17.5775 25.1816 17.623 24.233 18.2361 23.6759L24.2085 18.2498ZM5.99994 43.2503V46.5005C5.99994 47.8812 7.11923 49.0005 8.49993 49.0005H47.5003C48.881 49.0005 50.0003 47.8812 50.0003 46.5005V43.2503H5.99994Z"
        fill={fill}
      />
    </svg>
  );
}
