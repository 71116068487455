import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5002 8.50003V13H8.5002V8.50003H13V7.50003H8.5002V3H7.5002V7.50003H3V8.50003H7.5002Z"
      />
    </svg>
  );
}
