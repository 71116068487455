import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M5.31928 0H1.48053C0.642021 0 0.175848 0.969932 0.699659 1.6247L2.61903 4.02391C3.01936 4.52432 3.78045 4.52432 4.18077 4.02391L6.10015 1.62469C6.62396 0.969932 6.15778 0 5.31928 0Z"
      />
    </svg>
  );
}
