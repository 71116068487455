import styled from 'styled-components';
import { TransposedCell as Cell } from './TransposedCell';
import { guidGenerator } from 'utils/idGenerator';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const TBodyWrapper = styled.tbody`
  & tr:last-child td {
    border-bottom: none;
  }
`;

type RowType = string[][];

export type TableTransposedProps = {
  rows: RowType;
  labelMaxWidth?: number;
  testId?: string;
};

/**
 * @deprecated Start using Ul instead
 */
export const TableTransposed = ({
  rows = [],
  testId,
  labelMaxWidth = 160,
  ...restComponentProps
}: TableTransposedProps) => {
  const tableId = guidGenerator();
  const tableUniqueKey = testId || tableId;

  return (
    <TableWrapper data-testid={testId} {...restComponentProps}>
      <TBodyWrapper>
        {rows.map(([highlightedCell, ...cells], rowIndex) => (
          <tr key={`${tableUniqueKey}-${rowIndex}`}>
            <Cell
              label={highlightedCell}
              key={`${tableUniqueKey}-${rowIndex}-0`}
              labelMaxWidth={labelMaxWidth}
              highlighted
            />
            {cells.map((cell, cellIndex) => (
              <Cell label={cell} key={`${tableUniqueKey}-${rowIndex}-${cellIndex}`} />
            ))}
          </tr>
        ))}
      </TBodyWrapper>
    </TableWrapper>
  );
};
