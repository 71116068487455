import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66669 19L13.5556 15.8726"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.66666 15.8889V19" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.66667 15.8889H8.88889C6.74144 15.8889 5 14.1474 5 12V8.88889C5 6.74144 6.74144 5 8.88889 5H15.1111C17.2586 5 19 6.74144 19 8.88889V12C19 14.1474 17.2586 15.8889 15.1111 15.8889H13.5556"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
