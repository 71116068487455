import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00006 6.75C4.00006 6.33579 4.33585 6 4.75006 6H19.2543C19.6685 6 20.0043 6.33579 20.0043 6.75C20.0043 7.16421 19.6685 7.5 19.2543 7.5H4.75006C4.33585 7.5 4.00006 7.16421 4.00006 6.75ZM6.00006 11.9996C6.00006 11.5854 6.33585 11.2496 6.75006 11.2496H17.2543C17.6685 11.2496 18.0043 11.5854 18.0043 11.9996C18.0043 12.4138 17.6685 12.7496 17.2543 12.7496H6.75006C6.33585 12.7496 6.00006 12.4138 6.00006 11.9996ZM7.75006 16.4992C7.33585 16.4992 7.00006 16.835 7.00006 17.2492C7.00006 17.6634 7.33585 17.9992 7.75006 17.9992H16.2543C16.6685 17.9992 17.0043 17.6634 17.0043 17.2492C17.0043 16.835 16.6685 16.4992 16.2543 16.4992H7.75006Z"
        fill={fill}
      />
    </svg>
  );
}
