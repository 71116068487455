import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00012 3.25C7.03362 3.25 6.25012 4.0335 6.25012 5V5.75H9.75012V5C9.75012 4.0335 8.96662 3.25 8.00012 3.25ZM4.50012 5.75H4.75012V5C4.75012 3.20507 6.20519 1.75 8.00012 1.75C9.79504 1.75 11.2501 3.20507 11.2501 5V5.75H11.5001H13.4987C13.9129 5.75 14.2487 6.08579 14.2487 6.5C14.2487 6.91421 13.9129 7.25 13.4987 7.25H12.2501V11.5C12.2501 13.0188 11.0189 14.25 9.50012 14.25H6.50012C4.98133 14.25 3.75012 13.0188 3.75012 11.5V7.25H2.50012C2.0859 7.25 1.75012 6.91421 1.75012 6.5C1.75012 6.08579 2.0859 5.75 2.50012 5.75H4.50012ZM5.25012 7.25V11.5C5.25012 12.1904 5.80976 12.75 6.50012 12.75H9.50012C10.1905 12.75 10.7501 12.1904 10.7501 11.5V7.25H10.5001H5.50012H5.25012Z"
        fill={fill}
      />
    </svg>
  );
}
