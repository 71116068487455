import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4999 17.2543C18.4999 17.9446 17.9403 18.5043 17.2499 18.5043L12.2313 18.5042C11.8171 18.5042 11.4813 18.84 11.4813 19.2542C11.4813 19.6685 11.8171 20.0042 12.2313 20.0042L17.2499 20.0043C18.7687 20.0043 19.9999 18.773 19.9999 17.2543L20 6.75002C20 5.23123 18.7688 4 17.25 4L12.2313 4C11.8171 4 11.4813 4.33579 11.4813 4.75C11.4813 5.16422 11.8171 5.5 12.2313 5.5L17.25 5.50001C17.9404 5.50001 18.5 6.05965 18.5 6.75001L18.4999 17.2543ZM10.7577 6.94057C11.0652 6.66298 11.5394 6.68716 11.817 6.9946L15.8858 11.5007C16.1022 11.7403 16.1369 12.0847 15.9901 12.358C15.9589 12.4163 15.9201 12.4699 15.8752 12.5177L11.817 17.012C11.5394 17.3195 11.0652 17.3437 10.7577 17.0661C10.4503 16.7885 10.4261 16.3142 10.7037 16.0068L13.6409 12.7539L4.74994 12.7539C4.33573 12.7539 3.99994 12.4182 3.99994 12.0039C3.99994 11.5897 4.33573 11.2539 4.74994 11.2539L13.642 11.2539L10.7037 7.99986C10.4261 7.69243 10.4503 7.21817 10.7577 6.94057Z"
        fill={fill}
      />
    </svg>
  );
}
