import baseColors from './base';

export type BorderColors = keyof typeof _border;

export const _border = {
  focusBorder: baseColors.grey[1],
  disabledBorder: baseColors.opacityBlack[2],
  defaultNormalBorder: baseColors.opacityBlack[7],
  positiveNormalBorder: baseColors.green[6],
  negativeNormalBorder: baseColors.red[6],

  invertedFocusBorder: baseColors.grey[1],
  invertedDisabledBorder: baseColors.opacityWhite[4],
  invertedDefaultNormalBorder: baseColors.opacityWhite[7],
  invertedPositiveNormalBorder: baseColors.green[11],
  invertedNegativeNormalBorder: baseColors.red[11],
};
