import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7879 15L18.5 12L15.7879 9M18.4989 11.9996H5.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.25"
        y="21.25"
        width="18.5"
        height="8.5"
        rx="1.25"
        transform="rotate(-90 2.25 21.25)"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <rect
        x="13.25"
        y="21.25"
        width="18.5"
        height="8.5"
        rx="1.25"
        transform="rotate(-90 13.25 21.25)"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
}
