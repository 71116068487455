import React, { ReactNode } from 'react';
import {
  fontFaces,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  textMarginSize,
  DisplaySize,
  TextAlign,
} from '../../typography';
import styled from 'styled-components';
import { ForegroundColors } from '../../theme/colors';

type StyleProps = {
  size: DisplaySize;
  noGutter: boolean;
  color: ForegroundColors;
  align?: TextAlign;
};

const Styled = styled.h1<StyleProps>`
  font-family: ${fontFaces.loomiDisplay};
  font-weight: ${fontWeight.normal};
  text-align: ${({ align }) => align || 'inherit'};
  margin: 0;

  color: ${({ theme, color }) => theme.color[color]};

  ${({ size, noGutter }) => {
    const lSpacing = letterSpacing.display[size] ? `letter-spacing: ${letterSpacing.display[size]};` : '';
    return `
      font-size: ${fontSize.display[size]};
      line-height: ${lineHeight.display[size]};
      ${lSpacing}
      margin-bottom: ${noGutter ? '0' : textMarginSize.display[size]};
    `;
  }}
`;

export interface DisplayProps {
  /**
   * Define size of the Display. You can use only predefined values.
   */
  size?: DisplaySize;
  /**
   * Set the correct html tag, it could be very useful to provide correct headings hierarchy on the page.
   */
  level?: 'h1' | 'h2';
  /**
   * Content of the component.
   */
  children: ReactNode;
  /**
   * No spacing option
   */
  noGutter?: boolean;
  /**
   * Text color
   */
  color?: ForegroundColors;
  /**
   * Text align
   */
  align?: TextAlign;
}

export const Display = ({
  size = 'l',
  color = 'primary',
  noGutter = false,
  children,
  level = 'h1',
  ...props
}: DisplayProps) => (
  <Styled as={level} size={size} color={color} noGutter={noGutter} {...props}>
    {children}
  </Styled>
);
