import { IconSVGProps } from '../types';

export default function SVGComponent24hour({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M57.3846 38.5865V22.8608H22.7692V16.0036H36.6154V0.00363159H6.61538V16.0036H20.4615V22.8608H6.61538V38.5865L2 47.7293V64.0036H62V47.7293L57.3846 38.5865ZM8.92308 2.28935H34.3077V13.7179H8.92308V2.28935ZM8.92308 39.1351V25.1428H55.0769V39.1314L58.9834 46.8571H5.02031L8.92308 39.1351ZM4.30769 61.7179V49.1428H59.6923V61.7143L4.30769 61.7179Z"
      />
    </svg>
  );
}
