import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43522 4.53561C8.03394 3.95518 6.421 4.27601 5.3485 5.3485C4.27601 6.421 3.95518 8.03394 4.53561 9.43522C5.11603 10.8365 6.48342 11.7502 8.00015 11.7502C10.0712 11.7502 11.7502 10.0712 11.7502 8.00015C11.7502 6.48342 10.8365 5.11603 9.43522 4.53561ZM4.28784 4.28784C5.78933 2.78635 8.04745 2.33719 10.0092 3.14979C11.971 3.96239 13.2502 5.87673 13.2502 8.00015C13.2502 10.8996 10.8996 13.2502 8.00015 13.2502C5.87673 13.2502 3.96239 11.971 3.14979 10.0092C2.33719 8.04745 2.78635 5.78933 4.28784 4.28784ZM7.99039 7.7001C8.40461 7.7001 8.74039 8.03588 8.74039 8.4501V9.7001C8.74039 10.1143 8.40461 10.4501 7.99039 10.4501C7.57618 10.4501 7.24039 10.1143 7.24039 9.7001V8.4501C7.24039 8.03588 7.57618 7.7001 7.99039 7.7001ZM8.62314 5.74824C8.42013 5.49626 8.08532 5.39139 7.77485 5.48253C7.46437 5.57367 7.23936 5.84286 7.20476 6.16459C7.20189 6.19123 7.20046 6.218 7.20046 6.2448V6.2555C7.20046 6.28232 7.20189 6.30912 7.20477 6.33579C7.24504 6.70983 7.54048 7.00527 7.91452 7.04555C8.23624 7.08019 8.54714 6.91762 8.70226 6.63364C8.85737 6.34967 8.82614 6.00021 8.62314 5.74824ZM7.9986 6.2448H8.00074H8.00681L8.07511 5.55417L7.95046 6.2448H7.99038H7.9986Z"
        fill={fill}
      />
    </svg>
  );
}
