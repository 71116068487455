import {
  CalculateLuhnChecksumFunc,
  ValidateLuhnNumber
} from './interfaces'
import {
  clean,
  reverseString
} from './utilities'

const luhnChecksum: CalculateLuhnChecksumFunc = (input: string): number => {
  // Clean and reverse the string
  const num: string = reverseString(clean(input))

  let total = 0

  // From the rightmost digit, which is the check digit, and moving left
  for (let i = 0; i <= num.length - 1; i += 1) {
    let val: number = parseInt(num[i], 10)

    // Double the value of every second digit
    val = (i % 2 === 1) ? val * 2 : val

    // If the result of this doubling operation is greater than 9 then subtract 9 from the product
    if (val > 9) {
      val -= 9
    }

    // Take the sum of all the digits
    total += val
  }

  return total % 10
}

const isLuhnValid: ValidateLuhnNumber = (input: string): boolean => {
  const num: string = clean(input)

  // If the total modulo 10 is equal to 0, then the number is valid according to the Luhn formula
  return luhnChecksum(num) === 0
}

export {
  isLuhnValid,
  luhnChecksum
}
