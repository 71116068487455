import {
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  validateFactory
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetAgeFunc,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFunc,
  GetYearFunc,
  ValidateFunc
} from './interfaces'
import {
  clean
} from './utilities'

const czechSlovakia: CountryFunc = (personalNumber: string): CountryTools => {
  const format = '^([0-9]{2})([0-9]{2})([0-9]{2})/([0-9]{3})([0-9])?$'

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  if (!matches) {
    return defaultTools()
  }

  const getMonth = (): string => {
    const month = parseInt(matches[2], 10)

    if (month >= 51 && month <= 62) {
      const newMonth = month - 50

      // TODO padStart when TS can transpile it to es5
      return (newMonth < 10 ? '0' : '') + newMonth.toString()
    }

    return matches[2]
  }

  /* tslint:disable:object-literal-sort-keys */
  const parts = {
    day: matches[3],
    month: getMonth(),
    year: matches[1],
    individualNumber: matches[4]
  }
  /* tslint:enable:object-literal-sort-keys */

  const getYear: GetYearFunc = (): number => {
    let century = '19'
    const year = parseInt(parts.year, 10)

    if (personalNumber.length === 10 && year >= 54) {
      century = '18'
    } else if (personalNumber.length === 11 && year < 54) {
      century = '20'
    }

    return parseInt(century + parts.year, 10)
  }

  const localValidate: ValidateFunc = (): boolean => {
    if (personalNumber.length === 10) {
      return true
    }

    const cleanedPersonalNumber = clean(personalNumber)

    let oddNumbers = 0
    let evenNumbers = 0

    for (let i = 0; i < cleanedPersonalNumber.length; i++) {
      const digit = parseInt(cleanedPersonalNumber[i], 10)

      if ((i + 1) % 2 === 0) {
        evenNumbers += digit
      } else {
        oddNumbers += digit
      }
    }

    return (oddNumbers - evenNumbers) % 11 === 0
  }

  const validate: ValidateFunc = validateFactory(personalNumber, regex, parts, getYear, localValidate)

  if (!validate()) {
    return defaultTools()
  }

  const getBirthday: GetBirthdayFunc = getBirthdayFactory(parts, getYear)

  const getAge: GetAgeFunc = getAgeFactory(getBirthday)

  const getGender: GetGenderFunc = (): string => {
    const month = parseInt(matches[2], 10)

    if (month >= 51 && month <= 62) {
      return 'f'
    }

    return 'm'
  }

  const getCensored: GetCensoredFunc = (): string => `${parts.year}${matches[2]}${parts.day}/****`

  const getCountry: GetCountryFunc = (): string => 'czech_slovakia'

  return {
    getAge,
    getBirthday,
    getCensored,
    getCountry,
    getGender,
    getYear,
    validate
  }
}

export default czechSlovakia
