import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4996 7.94156C11.7845 7.68613 12.216 7.68613 12.5009 7.94156L19.7509 14.4416C20.0593 14.7181 20.0852 15.1922 19.8087 15.5006C19.5322 15.8091 19.058 15.8349 18.7496 15.5584L12.0003 9.50728L5.25091 15.5584C4.9425 15.8349 4.46833 15.8091 4.19182 15.5006C3.91532 15.1922 3.94118 14.7181 4.24959 14.4416L11.4996 7.94156Z"
        fill={fill}
      />
    </svg>
  );
}
