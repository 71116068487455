import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49995 10.5431C6.49995 7.41171 9.26174 5.5 12 5.5C14.7382 5.5 17.5 7.41171 17.5 10.5431C17.5 10.7293 17.508 10.9265 17.5152 11.1038V11.1038C17.5185 11.1841 17.5216 11.2602 17.5237 11.3295C17.5311 11.5702 17.5301 11.7883 17.5048 12.01C17.4565 12.4332 17.3135 12.9276 16.8759 13.584C16.8702 13.5925 16.8647 13.6011 16.8594 13.6098C16.3557 14.4368 15.6069 15.2862 14.8206 16.0917C14.4303 16.4915 14.0393 16.8721 13.6722 17.2292L13.6615 17.2396C13.3023 17.5891 12.9605 17.9216 12.6797 18.2158C12.3179 18.5947 11.682 18.5947 11.3202 18.2158C11.0394 17.9216 10.6976 17.5891 10.3384 17.2396L10.3277 17.2292C9.96059 16.8721 9.56959 16.4915 9.1793 16.0917C8.39299 15.2862 7.64418 14.4368 7.14048 13.6098C7.13516 13.6011 7.12966 13.5925 7.12399 13.584C6.68638 12.9276 6.54339 12.4332 6.49513 12.01C6.46984 11.7883 6.46879 11.5702 6.47616 11.3294C6.47828 11.2602 6.48139 11.1841 6.48467 11.1038L6.48467 11.1038C6.4919 10.9265 6.49995 10.7293 6.49995 10.5431ZM12 4C8.69709 4 4.99995 6.34209 4.99995 10.5431C4.99995 10.6999 4.99406 10.842 4.98751 10.9999C4.98387 11.0878 4.98002 11.1806 4.97687 11.2835C4.96862 11.553 4.96757 11.8536 5.00479 12.18C5.08114 12.8495 5.31119 13.5652 5.86738 14.4032C6.46162 15.3749 7.3036 16.3176 8.10593 17.1395C8.51176 17.5553 8.91625 17.9488 9.28166 18.3043C9.65107 18.6638 9.9748 18.9788 10.2353 19.2517C11.188 20.2494 12.8119 20.2494 13.7646 19.2517C14.0251 18.9788 14.3488 18.6638 14.7182 18.3043C15.0837 17.9488 15.4881 17.5553 15.894 17.1395C16.6963 16.3176 17.5383 15.3749 18.1325 14.4032C18.6887 13.5652 18.9188 12.8495 18.9951 12.18C19.0323 11.8536 19.0313 11.553 19.023 11.2835C19.0199 11.1806 19.016 11.0879 19.0124 11.0001L19.0124 11V11C19.0058 10.842 19 10.6999 19 10.5431C19 6.34209 15.3028 4 12 4ZM14 11C14 12.1046 13.1045 13 12 13C10.8954 13 9.99995 12.1046 9.99995 11C9.99995 9.89543 10.8954 9 12 9C13.1045 9 14 9.89543 14 11Z"
        fill={fill}
      />
    </svg>
  );
}
