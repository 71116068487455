import styled from 'styled-components';

type ContainerProps = {
  hasFile: boolean;
  isMobile: boolean;
  isErrorState: boolean;
};

export const FileDropzoneContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  gap: ${({ theme }) => theme.spacings[2]};
  align-items: center;
  padding: ${props => (props.isMobile ? props.theme.spacings[2] : props.theme.spacings[4])};
  border: ${({ theme, hasFile, isErrorState }) =>
    `1px ${hasFile ? 'solid' : 'dashed'} ${
      hasFile
        ? theme.color.base.grey.black
        : isErrorState
        ? theme.color.border.negativeNormalBorder
        : theme.color.border.defaultNormalBorder
    }`};
  border-radius: 8px;
  background-color: ${({ theme, hasFile }) => (hasFile ? theme.color.base.grey[15] : 'transparent')};
  text-align: center;

  .drag-over & {
    background: ${({ theme }) => theme.color.base.green[14]};
  }

  span {
    word-break: break-all;
  }
`;
