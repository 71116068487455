import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        d="M51.4286 16.0357V8.09874H60.7223V5.81303H2.28571V0H0V64H2.28571V8.09874H20.5714V16.0101L8 16V46.8571H64V16.0457L51.4286 16.0357ZM22.8571 8.09874H49.1429V16.0338L22.8571 16.0119V8.09874ZM10.2857 28.5714H15.9771V34.2857H10.2857V28.5714ZM61.7143 44.5714H10.2857V40H16V34.3086H21.6914V40.0229H27.4514V34.3086H33.12V40.0229H38.88V34.3086H44.5486V40.0229H50.3086V34.3086H56V40H61.7143V44.5714ZM21.7371 28.5943H27.4057V34.2629H21.7371V28.5943ZM33.1657 28.5943H38.8343V34.2629H33.1657V28.5943ZM44.5943 28.5943H50.2629V34.2629H44.5943V28.5943ZM61.7143 34.2857H56.0229V28.5714H61.7143V34.2857ZM61.7143 22.8571H56V28.5486H50.3086V22.8343H44.5486V28.5486H38.88V22.8343H33.12V28.5486H27.4514V22.8343H21.6914V28.5486H16V22.8571H10.2857V18.2857L61.7143 18.3278V22.8571Z"
      />
    </svg>
  );
}
