import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.62314 4.5C6.50825 4.5 5.48684 4.9196 4.69739 5.62055C3.777 6.43775 3.41743 7.62167 3.51532 8.95169C3.61382 10.2901 4.17699 11.7096 5.012 12.8481C6.37862 14.7113 8.58815 16.9474 11.9994 19.1217C15.4107 16.9474 17.6204 14.7113 18.9871 12.8481C19.8221 11.7096 20.3852 10.2901 20.4837 8.95169C20.5816 7.62167 20.2221 6.43775 19.3017 5.62055C18.5122 4.9196 17.4908 4.5 16.3759 4.5C15.2698 4.5 14.3542 4.93931 13.7141 5.53547C13.0552 6.14909 12.7494 6.86602 12.7494 7.36274C12.7494 7.77696 12.4136 8.11274 11.9994 8.11274C11.5852 8.11274 11.2494 7.77696 11.2494 7.36274C11.2494 6.86599 10.9437 6.14905 10.2848 5.53545C9.64475 4.9393 8.72927 4.5 7.62314 4.5ZM11.9995 5.22892C11.8003 4.94672 11.5672 4.67998 11.3072 4.43779C10.4156 3.6074 9.14291 3 7.62314 3C6.12201 3 4.75075 3.56723 3.70147 4.49888C2.34143 5.70644 1.89712 7.40085 2.01936 9.06179C2.14099 10.7143 2.82194 12.3984 3.80247 13.7352L4.40724 13.2917L3.80247 13.7352C5.34272 15.8352 7.81604 18.2995 11.6046 20.645C11.8465 20.7948 12.1523 20.7948 12.3942 20.645C16.1828 18.2995 18.6563 15.8352 20.1966 13.7352C21.1771 12.3984 21.8581 10.7143 21.9797 9.06179C22.1019 7.40085 21.6576 5.70644 20.2976 4.49888C19.2483 3.56723 17.877 3 16.3759 3C14.8562 3 13.5835 3.60739 12.6918 4.43777C12.4318 4.67997 12.1986 4.94671 11.9995 5.22892Z"
        fill={fill}
      />
    </svg>
  );
}
