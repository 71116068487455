import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5C5.33579 5 5 5.33579 5 5.75C5 6.16421 5.33579 6.5 5.75 6.5H16.4383L5.21961 17.7211C4.92675 18.014 4.9268 18.4889 5.21973 18.7817C5.51265 19.0746 5.98752 19.0745 6.28039 18.7816L17.4987 7.56093V18.2513C17.4987 18.6655 17.8345 19.0013 18.2487 19.0013C18.6629 19.0013 18.9987 18.6655 18.9987 18.2513V5.75134C18.9987 5.75045 18.9987 5.74955 18.9987 5.74866V5H18.2487H5.75Z"
        fill={fill}
      />
    </svg>
  );
}
