import { useState, useEffect, ReactNode } from 'react';
import { spacings } from 'spacings';
import { NavigationWrapper, NavigationProgressBarWrapper, NavigationProgressBarStyled } from './Navigation.styles';

const formatToPercentageProgress = (progress: number) => {
  return `${progress}%`;
};

export interface NavigationWrapperProps {
  children: ReactNode;
  progress?: number;
  horizontalPaddings?: string;
  verticalPaddings?: string;
}

export default function Navigation({
  children,
  progress,
  horizontalPaddings = spacings[4],
  verticalPaddings = spacings[4],
  ...restComponentProps
}: NavigationWrapperProps) {
  const [progressPercentage, setProgressPercentage] = useState(formatToPercentageProgress(0));

  useEffect(() => {
    if (progress !== null && progress !== undefined) {
      setProgressPercentage(formatToPercentageProgress(progress));
    }
  }, [progress]);

  return (
    <NavigationWrapper
      {...restComponentProps}
      horizontalPaddings={horizontalPaddings}
      verticalPaddings={verticalPaddings}
    >
      {progress && progress >= 0 && <NavigationProgressBar progress={progressPercentage} />}
      {children}
    </NavigationWrapper>
  );
}

function NavigationProgressBar({ progress }: { progress: string }) {
  return (
    <NavigationProgressBarWrapper>
      <NavigationProgressBarStyled progress={progress} />
    </NavigationProgressBarWrapper>
  );
}
