import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.99622 7.9985H21.0037"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8655 5.49252L10.8605 5.49752L10.8655 5.50252L10.8705 5.49752L10.8655 5.49252"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.32546 5.49252L8.32046 5.49752L8.32546 5.50252L8.33047 5.49752L8.32546 5.49252"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.77639 5.48739L5.77139 5.4924L5.77639 5.4974L5.78139 5.4924L5.77639 5.48739"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99872 21.0038H4.99705C4.46635 21.0038 3.95735 20.7932 3.58209 20.418C3.20682 20.0427 2.99622 19.5337 2.99622 19.003V4.99717C2.99622 4.46647 3.20682 3.95747 3.58209 3.58221C3.95735 3.20695 4.46635 2.99634 4.99705 2.99634H19.0029C19.5336 2.99634 20.0426 3.20695 20.4178 3.58221C20.7931 3.95747 21.0037 4.46647 21.0037 4.99717V8.99884"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 16.1509V21.0038"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0037 21.0038V16.1509"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0021 21.0038V19.0029"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5202 21.0039H21.4839"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5166 16.2442V16.2442C12.9896 16.2436 12.5006 15.9696 12.2248 15.5205C11.949 15.0714 11.9258 14.5113 12.1636 14.041L12.8226 12.7391C13.0525 12.2865 13.5167 12.001 14.0243 12H19.9771C20.4856 11.9997 20.9512 12.2849 21.1819 12.7381L21.8408 14.04C22.0786 14.5103 22.0555 15.0704 21.7797 15.5195C21.5039 15.9686 21.0149 16.2426 20.4878 16.2432C19.7403 16.2434 19.0757 15.7673 18.8354 15.0595C18.8194 15.0232 18.7835 14.9997 18.7438 14.9997C18.7041 14.9997 18.6682 15.0232 18.6521 15.0595C18.4122 15.7671 17.748 16.2432 17.0007 16.2432C16.2535 16.2432 15.5893 15.7671 15.3493 15.0595C15.3333 15.0232 15.2973 14.9997 15.2577 14.9997C15.218 14.9997 15.182 15.0232 15.166 15.0595C14.9255 15.7672 14.261 16.2431 13.5136 16.2432"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
