import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.25 12C19.25 16.0045 16.0045 19.25 12 19.25C7.99555 19.25 4.75 16.0045 4.75 12C4.75 7.99555 7.99555 4.75 12 4.75C16.0045 4.75 19.25 7.99555 19.25 12Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.941 9.05801L14.823 11.354C15.314 11.644 15.314 12.355 14.823 12.645L10.941 14.941C10.441 15.237 9.80902 14.876 9.80902 14.295V9.70401C9.80902 9.12301 10.441 8.76201 10.941 9.05801V9.05801Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
