import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 28C55 42.9117 42.9117 55 28 55C13.0883 55 1 42.9117 1 28C1 13.0883 13.0883 1 28 1C42.9117 1 55 13.0883 55 28ZM52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5 42.5C26.5 43.3284 27.1716 44 28 44C28.8284 44 29.5 43.3284 29.5 42.5V16.8403L36.0035 22.6211C36.6226 23.1715 37.5708 23.1157 38.1211 22.4965C38.6715 21.8774 38.6157 20.9293 37.9966 20.3789L29.002 12.3837C28.7364 12.1451 28.3851 12 28 12C27.6154 12 27.2645 12.1448 26.999 12.3828L18.0035 20.3789C17.3843 20.9293 17.3285 21.8774 17.8789 22.4965C18.4293 23.1157 19.3774 23.1715 19.9966 22.6211L26.5 16.8403V42.5Z"
        fill={fill}
      />
    </svg>
  );
}
