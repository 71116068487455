import { IconSVGProps } from '../types';

export default function SVGComponent({ fill, stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32 40C32 42.2091 30.2091 44 28 44C25.7909 44 24 42.2091 24 40C24 37.7909 25.7909 36 28 36C30.2091 36 32 37.7909 32 40Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 55C42.9117 55 55 42.9117 55 28C55 13.0883 42.9117 1 28 1C13.0883 1 1 13.0883 1 28C1 42.9117 13.0883 55 28 55ZM28 52C41.2548 52 52 41.2548 52 28C52 14.7452 41.2548 4 28 4C14.7452 4 4 14.7452 4 28C4 41.2548 14.7452 52 28 52Z"
          fill={fill}
        />
        <path d="M28 14L28 30" stroke={stroke} stroke-width="3" strokeLinecap="round" />
      </svg>
    </svg>
  );
}
