import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 6C18.4477 6 18 6.44772 18 7V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V7C20 6.44772 19.5523 6 19 6ZM9.53033 8.53033C9.82322 8.23744 9.82322 7.76256 9.53033 7.46967C9.23744 7.17678 8.76256 7.17678 8.46967 7.46967L4.46967 11.4697C4.17678 11.7626 4.17678 12.2374 4.46967 12.5303L8.46967 16.5303C8.76256 16.8232 9.23744 16.8232 9.53033 16.5303C9.82322 16.2374 9.82322 15.7626 9.53033 15.4697L6.81121 12.7506H15C15.4142 12.7506 15.75 12.4148 15.75 12.0006C15.75 11.5863 15.4142 11.2506 15 11.2506H6.81011L9.53033 8.53033Z"
        fill={fill}
      />
    </svg>
  );
}
