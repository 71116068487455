import { Label } from '../Text/Label';
import { Text } from '../Text/Text';
import styled from 'styled-components';
import { TextSize } from 'typography';
import { LiProps, UlProps } from './types';
import { createContext, useContext } from 'react';
import { useMedia } from 'hooks/useMedia';
import React from 'react';

const listContext = createContext<{ size: TextSize; isCompact: boolean }>({ size: 'm', isCompact: false });
const ListContextProvider = listContext.Provider;

export const Ul = ({ fontSize, isCompact, children, ...props }: UlProps) => {
  return (
    <ListContextProvider value={{ size: fontSize || 'm', isCompact: isCompact || false }}>
      <ListWrapper {...props}>{children}</ListWrapper>
    </ListContextProvider>
  );
};

export const Li = ({ label, value, highlighted = false, children, ...props }: LiProps) => {
  const { size, isCompact } = useContext(listContext);
  const isMobile = useMedia('mobile');
  const isMobileCompact = isCompact && isMobile;

  return (
    <ListItemWrapper>
      <LabelValueWrapper isMobile={isMobileCompact} highlighted={highlighted} {...props}>
        <LabelStyled size={isMobileCompact ? 'xs' : size} hasValue={!!value} isMobile={isMobileCompact}>
          {label}
        </LabelStyled>
        {value ? (
          Array.isArray(value) ? (
            <MultipleValues columnCount={value.length}>
              {value.map((val, i) =>
                typeof val === 'string' ? (
                  <Text key={`${label}-value${i}`} size={size}>
                    {val}
                  </Text>
                ) : (
                  <React.Fragment key={`${label}-value${i}`}>{val}</React.Fragment>
                )
              )}
            </MultipleValues>
          ) : typeof value === 'string' ? (
            <Text size={size}>{value}</Text>
          ) : (
            value
          )
        ) : null}
      </LabelValueWrapper>
      {children}
    </ListItemWrapper>
  );
};

const LabelValueWrapper = styled.div<{ isMobile: boolean; highlighted: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: ${({ isMobile }) => (isMobile ? 'wrap' : 'nowrap')};
  gap: 0 ${({ theme }) => theme.spacings[4]};
  padding: ${({ theme }) => `${theme.spacings[0.5]} 0 ${theme.spacings[0.5]} 1em`};
  border-bottom: ${({ highlighted, theme }) =>
    `1px solid ${highlighted ? theme.color.border.defaultNormalBorder : theme.color.border.disabledBorder}`};
  font-weight: ${({ highlighted }) => (highlighted ? '600' : 'normal')};
`;

const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItemWrapper = styled.li`
  overflow-wrap: anywhere;

  // Sets initial indent for first-level list items using font-size
  font-size: 0;

  // Sets calculated indent for each nested level using font-size
  & & {
    font-size: calc(1em + ${({ theme }) => theme.spacings[3]});
  }
`;

const LabelStyled = styled(Label)<{ isMobile: boolean; hasValue?: boolean }>`
  // If it's just label, we don't limit it's width as it has plenty of space to grow
  width: ${({ isMobile, hasValue, theme }) => (!isMobile && hasValue ? theme.spacings[16] : '100%')};
  padding-bottom: ${({ isMobile, hasValue, theme }) => (isMobile && hasValue ? theme.spacings[1.5] : '0')};
  flex-shrink: 0;
`;

const MultipleValues = styled.div<{ columnCount: number }>`
  display: flex;
  gap: 0 ${({ theme }) => theme.spacings[2]};
  flex-grow: 1;

  // Set column width based on number of passed values
  > * {
    flex: 0 0 ${({ columnCount }) => 100 / columnCount + '%'};
  }
`;
