import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke, fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0008 14.0014H1.99585"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99622 14.0014V13.001C2.99622 12.205 3.31235 11.4416 3.87521 10.8787C4.43806 10.3159 5.20147 9.99971 5.99747 9.99976H9.99913C10.7951 9.99971 11.5585 10.3159 12.1214 10.8787C12.6842 11.4416 13.0004 12.205 13.0004 13.001V14.0014"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0004 18.0031V17.0027H2.99622V18.0031C2.99607 18.5338 3.20682 19.0428 3.58209 19.4181C3.95735 19.7933 4.46635 20.0041 4.99705 20.0039H10.9995C11.5302 20.0041 12.0393 19.7933 12.4145 19.4181C12.7898 19.0428 13.0005 18.5338 13.0004 18.0031V18.0031Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9996 2.24585H10.9993C10.0329 2.2462 9.24949 3.02956 9.24915 3.996V3.99627V5.24756H7.49812C6.25514 5.24756 5.2475 6.2552 5.2475 7.49818V7.99839C5.2475 8.41261 5.58328 8.74839 5.9975 8.74839C6.41171 8.74839 6.7475 8.41261 6.7475 7.99839V7.49818C6.7475 7.08362 7.08356 6.74756 7.49812 6.74756H19.5031C19.9177 6.74756 20.2537 7.08363 20.2537 7.49818V17.5032C20.2537 17.9177 19.9177 18.2538 19.5031 18.2538H16.0017C15.5875 18.2538 15.2517 18.5896 15.2517 19.0038C15.2517 19.418 15.5875 19.7538 16.0017 19.7538H19.5031C20.7461 19.7538 21.7537 18.7462 21.7537 17.5032V7.49818C21.7537 6.2552 20.7461 5.24756 19.5031 5.24756H17.7521V3.99627V3.996C17.7517 3.02956 16.9684 2.2462 16.0019 2.24585H16.0016H10.9996ZM10.9998 3.74585H16.0014H16.0015C16.1399 3.74596 16.252 3.85814 16.2521 3.99654V5.24752H10.7491V3.99654V3.99643C10.7493 3.85805 10.8614 3.7459 10.9998 3.74585Z"
        fill={fill}
      />
    </svg>
  );
}
