import { createContext, ReactNode, useMemo, useContext, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, LoomisTheme } from './theme';
import GlobalFonts from './fonts/fonts';
import { i18nVault } from './i18n';

type VaultContextType = {
  theme: LoomisTheme;
  appElementSelector?: string;
  locale?: string;
};

type Props = {
  children: ReactNode;
  appElementSelector?: string;
  locale?: string;
};

const VaultContext = createContext<VaultContextType | null>(null);

export const useVaultContext = () => {
  const context = useContext(VaultContext);

  if (!context) throw new Error('No Vault Context provided');

  return context;
};

const VaultProvider = ({ appElementSelector, locale, children }: Props) => {
  const providerValue = useMemo(() => {
    return {
      theme: lightTheme,
      appElementSelector,
    };
  }, [appElementSelector]);

  useEffect(() => {
    i18nVault.changeLanguage(locale);
  }, [locale]);

  return (
    <VaultContext.Provider value={providerValue}>
      <GlobalFonts />
      <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
    </VaultContext.Provider>
  );
};

export default VaultProvider;
