import { ReactNode } from 'react';
import { fontFaces, fontSize, fontWeight, lineHeight, LabelSize, letterSpacing, TextAlign } from '../../typography';
import styled from 'styled-components';
import { ForegroundColors } from '../../theme/colors';
import { useTheme } from '../../hooks/useTheme';

type LabelStyleProps = {
  size: LabelSize;
  color?: string;
  align?: TextAlign;
};

const Styled = styled.span<LabelStyleProps>`
  font-family: ${fontFaces.Roobert};
  font-size: ${({ size }) => fontSize.label[size]};
  line-height: ${({ size }) => lineHeight.label[size]};
  letter-spacing: ${({ size }) => letterSpacing.label[size]};
  font-weight: ${fontWeight.semiBold};
  text-align: ${({ align }) => align || 'inherit'};
  color: ${({ color }) => color};
`;

export type LabelProps = {
  /**
   * Content of the component.
   */
  children: ReactNode;
  /**
   * Define size of the Label. You can use only predefined values.
   */
  size?: LabelSize;
  /**
   * Text color
   */
  color?: ForegroundColors;
  /**
   * Text align
   */
  align?: TextAlign;
};

/**
 The default label size is 016 (M). Labels are to only be used within UI to indicate clickability.
Commonly used in:

- Buttons
- Navigation
 */
export const Label = ({ size = 'm', color = 'primary', children, ...props }: LabelProps) => {
  const theme = useTheme();

  return (
    <Styled size={size} color={theme.color[color]} {...props}>
      {children}
    </Styled>
  );
};

type VaultInternalLabelProps = Omit<LabelProps, 'color'> & {
  color?: string;
};

export const VaultInternalLabel = ({ size = 'm', children, color, ...props }: VaultInternalLabelProps) => (
  <Styled size={size} color={color} {...props}>
    {children}
  </Styled>
);
