import Button from '../Button/Button';
import { Children, isValidElement, cloneElement } from 'react';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

export const MENU_ANIMATION_DURATION = 250;
export interface HamburgerMenuProps {
  children?: ReactNode;
}

export const HamburgerMenu = ({ children, ...props }: HamburgerMenuProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
    setIsSubmenuVisible(false);
  };

  return (
    <div {...props}>
      <Button type="button" variant="tertiary" icon={isExpanded ? 'chevronDown' : 'menu'} onClick={toggleMenu} />
      <HamburgerMenuWrapper isExpanded={isExpanded} isSubmenuVisible={isSubmenuVisible}>
        {isExpanded &&
          Children.map(children, child => {
            return <>{isValidElement(child) ? cloneElement(child, { ...{ setIsSubmenuVisible } }) : child}</>;
          })}
      </HamburgerMenuWrapper>
    </div>
  );
};

const HamburgerMenuWrapper = styled.nav<{ isExpanded: boolean; isSubmenuVisible: boolean }>`
  position: absolute;
  left: ${({ isSubmenuVisible }) => (isSubmenuVisible ? '-100%' : '0')};
  width: 100%;
  padding: ${({ theme, isExpanded }) => (isExpanded ? theme.spacings['2'] : '0')};
  max-height: ${({ isExpanded }) => (isExpanded ? '100vh' : '0')};
  background: ${({ theme }) => theme.color.background.primaryBg};
  transition: max-height ${MENU_ANIMATION_DURATION}ms ease-in, left ${MENU_ANIMATION_DURATION}ms ease-in;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

export const HamburgerMenuItem = styled.div<{ stretched?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  word-break: break-word;

  > * {
    justify-content: ${({ stretched }) => (stretched ? 'space-between' : 'flex-start')};
    padding: ${({ theme }) => theme.spacings['2']} 0;
    width: 100%;
    text-align: left;
  }

  * {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacings['1']};
    text-decoration: none;
  }
`;
