import { InputWrapper, InputWrapperSharedProps } from '../Input/InputWrapper/InputWrapper';
import { Text } from '../../Text/Text';
import { VisuallyHidden } from '../../VisuallyHidden/VisuallyHidden';
import { ForwardedRef, forwardRef, useState } from 'react';
import styled from 'styled-components';

export interface RadioProps {
  options: {
    value: string;
    label: string;
  }[];
  name: string;
  disabled?: boolean;
  required?: string;
  defaultCheckedValue?: string;
  label?: string;
  testId?: string;
  initialValue?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelVisuallyHidden?: boolean;
}

export const Radio = forwardRef(
  (
    {
      options = [],
      name,
      disabled = false,
      required,
      infoText,
      errorText,
      hintText,
      warningText,
      label,
      testId,
      defaultCheckedValue,
      onBlur,
      onChange,
      labelVisuallyHidden = false,
      ...restOfProps
    }: RadioProps & InputWrapperSharedProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultCheckedValue);

    return (
      <InputWrapper
        hintText={hintText}
        warningText={warningText}
        errorText={errorText}
        infoText={infoText}
        // Redundant field as each input has its own label - passing string to satisfy InputWrapper
        inputId="radio"
        label={label}
        visuallyHidden={labelVisuallyHidden}
        required={required}
      >
        <RadiosList>
          {options.map(({ value, label }) => (
            <label htmlFor={value} key={value}>
              <RadioWrapper onClick={() => setSelectedOption(value)}>
                <VisuallyHidden>
                  <input
                    {...restOfProps}
                    type="radio"
                    name={name}
                    value={value}
                    id={value}
                    ref={ref}
                    onChange={onChange}
                    defaultChecked={defaultCheckedValue === value}
                  />
                </VisuallyHidden>

                <RadioCustomDot isActive={value === selectedOption}>
                  <RadioCustomDotInner />
                </RadioCustomDot>

                <Text size="s">{label}</Text>
              </RadioWrapper>
            </label>
          ))}
        </RadiosList>
      </InputWrapper>
    );
  }
);

const RadioCustomDot = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.border.defaultNormalBorder};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.background.invertedPrimaryBg : theme.color.background.primaryBg};
`;

const RadioCustomDotInner = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.color.background.primaryBg};
  border-radius: 50%;
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[1]};
  cursor: pointer;
`;

const RadiosList = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings[1]};
`;
