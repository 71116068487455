import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings[0.5]};
  border: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  border-radius: ${({ theme }) => theme.spacings[1]};
  padding: ${({ theme }) => `${theme.spacings[1.5]} ${theme.spacings[2]}`};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
