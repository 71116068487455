import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89581 3.2321C7.16205 2.9148 7.12066 2.44173 6.80335 2.17548C6.48605 1.90923 6.01298 1.95062 5.74673 2.26793L2.36467 6.29858C1.54511 7.27531 1.54511 8.72327 2.36467 9.70001L5.74673 13.7307C6.01298 14.048 6.48605 14.0894 6.80335 13.8231C7.12066 13.5569 7.16205 13.0838 6.89581 12.7665L3.51375 8.73584C3.16208 8.31673 3.16208 7.68185 3.51375 7.26275L6.89581 3.2321ZM11.8988 3.2321C12.1651 2.9148 12.1237 2.44173 11.8064 2.17548C11.4891 1.90923 11.016 1.95062 10.7497 2.26793L7.36769 6.29858C6.54812 7.27531 6.54812 8.72327 7.36769 9.70001L10.7497 13.7307C11.016 14.048 11.4891 14.0894 11.8064 13.8231C12.1237 13.5569 12.1651 13.0838 11.8988 12.7665L8.51676 8.73584C8.1651 8.31673 8.1651 7.68185 8.51676 7.26275L11.8988 3.2321Z"
        fill={fill}
      />
    </svg>
  );
}
