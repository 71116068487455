import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6339 12.8541L15.2591 10.5206C15.5687 10.2454 16.0428 10.2733 16.318 10.5829C16.5932 10.8924 16.5653 11.3665 16.2557 11.6417L12.3707 15.095C12.0865 15.3476 11.6583 15.3476 11.3741 15.095L7.4891 11.6417C7.17951 11.3665 7.15163 10.8924 7.42682 10.5829C7.70201 10.2733 8.17606 10.2454 8.48565 10.5206L11.1318 12.8727C11.1667 11.2166 11.3291 9.32344 12.0842 7.72166C12.584 6.66157 13.3473 5.71458 14.498 5.04041C15.6423 4.37001 17.1172 4 18.9949 4C19.4091 4 19.7449 4.33579 19.7449 4.75C19.7449 5.16421 19.4091 5.5 18.9949 5.5C17.3112 5.5 16.1152 5.83145 15.2563 6.33465C14.4038 6.83408 13.8316 7.53282 13.441 8.36129C12.8207 9.67712 12.6703 11.2808 12.6339 12.8541ZM6.75 5.5002C6.05964 5.5002 5.5 6.05984 5.5 6.7502V17.2543C5.5 17.9446 6.05964 18.5043 6.75 18.5043H17.2541C17.9444 18.5043 18.5041 17.9446 18.5041 17.2543V8.70585C18.5041 8.29164 18.8398 7.95585 19.2541 7.95585C19.6683 7.95585 20.0041 8.29164 20.0041 8.70585V17.2543C20.0041 18.773 18.7728 20.0043 17.2541 20.0043H6.75C5.23122 20.0043 4 18.773 4 17.2543V6.7502C4 5.23142 5.23122 4.0002 6.75 4.0002H10.0242C10.4384 4.0002 10.7742 4.33598 10.7742 4.7502C10.7742 5.16441 10.4384 5.5002 10.0242 5.5002H6.75Z"
        fill={fill}
      />
    </svg>
  );
}
