import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8536 15.8536C21.0488 15.6583 21.0488 15.3417 20.8536 15.1464C20.6583 14.9512 20.3417 14.9512 20.1464 15.1464L15.1464 20.1464C14.9512 20.3417 14.9512 20.6583 15.1464 20.8536C15.3417 21.0488 15.6583 21.0488 15.8536 20.8536L20.8536 15.8536ZM20.8536 18.8536C21.0488 18.6583 21.0488 18.3417 20.8536 18.1464C20.6583 17.9512 20.3417 17.9512 20.1464 18.1464L18.1464 20.1464C17.9512 20.3417 17.9512 20.6583 18.1464 20.8536C18.3417 21.0488 18.6583 21.0488 18.8536 20.8536L20.8536 18.8536Z"
        fill={fill}
      />
    </svg>
  );
}
