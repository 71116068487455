import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7422 6.18401C20.0547 6.45578 20.0878 6.9295 19.8161 7.24209L11.2814 17.0589C10.1878 18.3168 8.23552 18.3204 7.13732 17.0665L4.18587 13.6966C3.91296 13.385 3.94433 12.9112 4.25593 12.6383C4.56752 12.3654 5.04136 12.3967 5.31427 12.7083L8.26572 16.0782C8.7649 16.6481 9.65229 16.6465 10.1494 16.0748L18.6841 6.25793C18.9558 5.94534 19.4296 5.91224 19.7422 6.18401Z"
        fill={fill}
      />
    </svg>
  );
}
