import {
  defaultTools,
  getAgeFactory,
  getBirthdayFactory,
  getGenderFactory,
  validateFactory
} from './commonTools'
import {
  CountryFunc,
  CountryTools,
  GetAgeFunc,
  GetBirthdayFunc,
  GetCensoredFunc,
  GetCountryFunc,
  GetGenderFunc,
  GetYearFunc,
  ValidateFunc
} from './interfaces'
import {
  calculateBitCheckSum
} from './utilities'

const poland: CountryFunc = (personalNumber: string): CountryTools => {
  const format = '^([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{3})([0-9])([0-9])$'

  const regex: RegExp = new RegExp(format)

  const matches: string[] | null = regex.exec(personalNumber)

  if (!matches) {
    return defaultTools()
  }

  const getMonth = (): string => {
    const month = parseInt(matches[2], 10)

    const newMonth = month % 20

    // TODO padStart when TS can transpile it to es5
    return (newMonth < 10 ? '0' : '') + newMonth.toString()
  }

  /* tslint:disable:object-literal-sort-keys */
  const parts = {
    day: matches[3],
    month: getMonth(),
    year: matches[1],
    individualNumber: matches[4],
    gender: matches[5],
    checksum: matches[6]
  }
  /* tslint:enable:object-literal-sort-keys */

  const getYear: GetYearFunc = (): number => {
    const month = parseInt(matches[2], 10)
    let century = '19'

    if (month < 20) {
      century = '19'
    } else if (month > 20 && month < 33) {
      century = '20'
    } else if (month > 40 && month < 53) {
      century = '21'
    } else if (month > 60 && month < 73) {
      century = '22'
    }

    return parseInt(century + parts.year, 10)
  }

  const localValidate: ValidateFunc = (): boolean => {
    const numbers = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]

    const sum = calculateBitCheckSum(personalNumber, numbers)

    const modulo = sum % 10

    const tenthDigit = parseInt(personalNumber[10], 10)

    return (modulo === 0 && tenthDigit === 0) || (tenthDigit === 10 - modulo)
  }

  const validate: ValidateFunc = validateFactory(personalNumber, regex, parts, getYear, localValidate)

  if (!validate()) {
    return defaultTools()
  }

  const getBirthday: GetBirthdayFunc = getBirthdayFactory(parts, getYear)

  const getAge: GetAgeFunc = getAgeFactory(getBirthday)

  const getGender: GetGenderFunc = getGenderFactory(parts)

  const getCensored: GetCensoredFunc = (): string => `${parts.year}${matches[2]}${parts.day}*****`

  const getCountry: GetCountryFunc = (): string => 'poland'

  return {
    getAge,
    getBirthday,
    getCensored,
    getCountry,
    getGender,
    getYear,
    validate
  }
}

export default poland
