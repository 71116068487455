import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.75C7.99555 4.75 4.75 7.99555 4.75 12C4.75 16.0045 7.99555 19.25 12 19.25C16.0045 19.25 19.25 16.0045 19.25 12C19.25 7.99555 16.0045 4.75 12 4.75ZM12 3.25C7.16712 3.25 3.25 7.16712 3.25 12C3.25 16.8329 7.16712 20.75 12 20.75C16.8329 20.75 20.75 16.8329 20.75 12C20.75 7.16712 16.8329 3.25 12 3.25ZM10.75 11.25C10.75 10.8358 11.0858 10.5 11.5 10.5H12.5C12.9142 10.5 13.25 10.8358 13.25 11.25V16.25C13.25 16.6642 12.9142 17 12.5 17C12.0858 17 11.75 16.6642 11.75 16.25V12H11.5C11.0858 12 10.75 11.6642 10.75 11.25ZM12 9.25C12.5523 9.25 13 8.80228 13 8.25C13 7.69772 12.5523 7.25 12 7.25C11.4477 7.25 11 7.69772 11 8.25C11 8.80228 11.4477 9.25 12 9.25Z"
        fill={fill}
      />
    </svg>
  );
}
