import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.91938 10H6.08062C5.24212 10 4.77595 9.03007 5.29976 8.3753L7.21913 5.97609C7.61946 5.47568 8.38054 5.47568 8.78087 5.97609L10.7002 8.37531C11.2241 9.03007 10.7579 10 9.91938 10Z"
        fill={fill}
      />
    </svg>
  );
}
