import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75006 4C4.78356 4 4.00006 4.7835 4.00006 5.75V9.5517C4.00006 10.5182 4.78356 11.3017 5.75006 11.3017H9.55176C10.5183 11.3017 11.3018 10.5182 11.3018 9.5517V5.75C11.3018 4.7835 10.5183 4 9.55176 4H5.75006ZM5.50006 5.75C5.50006 5.61193 5.61199 5.5 5.75006 5.5H9.55176C9.68983 5.5 9.80176 5.61193 9.80176 5.75V9.5517C9.80176 9.68977 9.68983 9.8017 9.55176 9.8017H5.75006C5.61199 9.8017 5.50006 9.68977 5.50006 9.5517V5.75ZM7.65091 12.7026C5.6346 12.7026 4.00006 14.3371 4.00006 16.3534C4.00006 18.3697 5.6346 20.0043 7.65091 20.0043C9.66722 20.0043 11.3018 18.3697 11.3018 16.3534C11.3018 14.3371 9.66722 12.7026 7.65091 12.7026ZM5.50006 16.3534C5.50006 15.1655 6.46303 14.2026 7.65091 14.2026C8.83879 14.2026 9.80176 15.1655 9.80176 16.3534C9.80176 17.5413 8.83879 18.5043 7.65091 18.5043C6.46303 18.5043 5.50006 17.5413 5.50006 16.3534ZM12.7024 7.65085C12.7024 5.63454 14.3369 4 16.3532 4C18.3695 4 20.0041 5.63454 20.0041 7.65085C20.0041 9.66716 18.3695 11.3017 16.3532 11.3017C14.3369 11.3017 12.7024 9.66716 12.7024 7.65085ZM16.3532 5.5C15.1654 5.5 14.2024 6.46297 14.2024 7.65085C14.2024 8.83873 15.1654 9.8017 16.3532 9.8017C17.5411 9.8017 18.5041 8.83873 18.5041 7.65085C18.5041 6.46297 17.5411 5.5 16.3532 5.5ZM14.4524 12.7026C13.4859 12.7026 12.7024 13.4861 12.7024 14.4526V18.2543C12.7024 19.2208 13.4859 20.0043 14.4524 20.0043H18.2541C19.2206 20.0043 20.0041 19.2208 20.0041 18.2543V14.4526C20.0041 13.4861 19.2206 12.7026 18.2541 12.7026H14.4524ZM14.2024 14.4526C14.2024 14.3145 14.3143 14.2026 14.4524 14.2026H18.2541C18.3922 14.2026 18.5041 14.3145 18.5041 14.4526V18.2543C18.5041 18.3923 18.3922 18.5043 18.2541 18.5043H14.4524C14.3143 18.5043 14.2024 18.3923 14.2024 18.2543V14.4526Z"
        fill={fill}
      />
    </svg>
  );
}
