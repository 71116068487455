import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 6.08062L6 9.91938C6 10.7579 6.96993 11.2241 7.6247 10.7002L10.0239 8.78087C10.5243 8.38054 10.5243 7.61946 10.0239 7.21913L7.62469 5.29976C6.96993 4.77595 6 5.24212 6 6.08062Z"
        fill={fill}
      />
    </svg>
  );
}
