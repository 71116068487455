import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6.07709C10.0178 4.19024 6.93067 3.99648 4.72178 5.49581C4.26133 5.80803 4 6.32585 4 6.86399V17.8264C4 18.4813 4.73333 18.8612 5.34044 18.5532C7.48 17.4676 10.1973 17.7832 12 19.5V6.07709C13.9822 4.19024 17.0693 3.99648 19.2782 5.49581C19.7387 5.80803 20 6.32585 20 6.86399V17.8264C20 18.4813 19.2667 18.862 18.6596 18.5532C16.52 17.4676 13.8027 17.7832 12 19.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
