import { IconSVGProps } from '../types';

export default function SVGComponentAccounts({ fill }: IconSVGProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12781 3.48741C7.95623 3.00109 9.02135 3.2845 9.50734 4.1404C9.52047 4.16352 9.53464 4.18567 9.54976 4.20684C9.98139 5.04622 9.69507 6.08196 8.90784 6.56965C8.89459 6.57641 8.88147 6.5836 8.86848 6.59122C7.87551 7.17415 7.30564 8.20572 7.2531 9.27612C7.2525 9.28837 7.2522 9.30063 7.2522 9.31289V10C7.2522 10.4142 7.58798 10.75 8.0022 10.75C8.41641 10.75 8.7522 10.4142 8.7522 10V9.33285C8.78565 8.75839 9.08641 8.21958 9.59189 7.90649C9.60514 7.89973 9.61827 7.89254 9.63126 7.88491C11.1843 6.97323 11.7071 4.96509 10.8179 3.39906C10.8004 3.36824 10.781 3.33913 10.76 3.3118C9.84355 1.8064 7.89947 1.29503 6.36842 2.19384C5.39385 2.76596 4.8268 3.77042 4.7565 4.8192C4.75551 4.83398 4.75496 4.84878 4.75484 4.86359L4.74997 5.49842C4.74678 5.91262 5.07998 6.25098 5.49418 6.25416C5.90838 6.25734 6.24674 5.92414 6.24992 5.50994L6.25461 4.89923C6.29947 4.32476 6.6115 3.79052 7.12781 3.48741ZM8.00118 13.9971C8.61064 13.9971 9.10471 13.5064 9.10471 12.9012C9.10471 12.2959 8.61064 11.8053 8.00118 11.8053C7.39172 11.8053 6.89765 12.2959 6.89765 12.9012C6.89765 13.5064 7.39172 13.9971 8.00118 13.9971Z"
        fill={fill}
      />
    </svg>
  );
}
