import styled from 'styled-components';
import baseColors from '../../theme/colors/base';
import { ButtonIconPosition, ButtonSizes, ButtonVariants } from './types';
import { fontFaces } from '../../typography';
import { ForegroundColors } from 'theme/colors';

type ButtonStyledProps = {
  size: ButtonSizes;
  variant: ButtonVariants;
  iconPosition: ButtonIconPosition;
  interactionFontColor?: string;
  interactionBgColor?: string;
  fullWidth: boolean;
  isSelected?: boolean;
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: ${({ iconPosition }) => (iconPosition === 'left' ? 'row-reverse' : 'row')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
  padding: ${({ size, variant }) => getButtonPadding(size, variant)};
  border: ${({ variant }) => (variant === 'secondary' ? `1px solid ${baseColors.grey.black}` : 'none')};
  border-radius: 4px;
  font-size: ${({ size }) => (size === 's' ? '14px' : size === 'm' ? '16px' : '14px')};
  line-height: 24px;
  background-color: ${({ variant, isSelected }) =>
    isSelected ? getButtonFocusBackground(variant) : getButtonBackground(variant)};
  color: ${({ theme, variant, isSelected }) => theme.color[getButtonTextColor(variant, isSelected)]};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  font-family: ${fontFaces.Roobert};
  text-align: center;
  justify-content: center;

  &:focus,
  &:hover {
    background-color: ${({ variant, disabled, interactionBgColor }) =>
      !disabled ? interactionBgColor || getButtonFocusBackground(variant) : getButtonBackground(variant)};
    color: ${({ theme, variant, disabled, interactionFontColor }) =>
      !disabled
        ? interactionFontColor || theme.color[getButtonTextColor(variant, true)]
        : theme.color[getButtonTextColor(variant)]};

    // Removing only because we're indicating focus status in other way
    outline: none;
  }
`;

// Styling utils based on variant

const getButtonBackground = (variant: ButtonVariants) => {
  switch (variant) {
    case 'primary':
      return baseColors.grey.black;
    case 'destructive':
      return baseColors.red[14];
    case 'positive':
      return baseColors.green[14];
    default:
      return 'transparent';
  }
};

const getButtonFocusBackground = (variant: ButtonVariants) => {
  switch (variant) {
    case 'primary':
      return baseColors.green[5];
    case 'secondary':
      return baseColors.green[14];
    case 'tertiary':
      return baseColors.green[14];
    case 'destructive':
      return baseColors.red[7];
    case 'destructive-teriary':
      return baseColors.red[14];
    case 'positive':
      return baseColors.green[8];
  }
};

export const getButtonTextColor = (variant: ButtonVariants, isHighlighted?: boolean): ForegroundColors => {
  switch (variant) {
    case 'primary':
      return 'invertedPrimary';
    case 'secondary':
      return 'secondary';
    case 'tertiary':
      return 'linkNormal';
    case 'destructive':
      return isHighlighted ? 'invertedPrimary' : 'negative';
    case 'destructive-teriary':
      return 'negative';
    case 'positive':
      return 'linkNormal';
  }
};

const getButtonPadding = (size: ButtonSizes, variant: ButtonVariants) => {
  let paddingTopBotton = 0;
  let paddingLeftRight = 0;

  if (size === 's') {
    paddingTopBotton = 8;
    paddingLeftRight = 12;
  }

  if (size === 'm') {
    paddingTopBotton = 12;
    paddingLeftRight = 16;
  }

  if (variant === 'secondary') {
    // Makes space for border
    paddingTopBotton -= 2;
    paddingLeftRight -= 2;
  }

  return `${paddingTopBotton}px ${paddingLeftRight}px`;
};
