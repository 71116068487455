import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5C5.23122 5 4 6.23122 4 7.75V16.2506C4 17.7694 5.23122 19.0006 6.75 19.0006H17.2543C18.773 19.0006 20.0043 17.7694 20.0043 16.2506V7.75C20.0043 6.23122 18.773 5 17.2543 5H6.75ZM5.5 7.75C5.5 7.05965 6.05965 6.5 6.75 6.5H17.2543C17.9446 6.5 18.5043 7.05965 18.5043 7.75V9.05741H5.5V7.75ZM5.5 12.0574V16.2506C5.5 16.9409 6.05965 17.5006 6.75 17.5006H17.2543C17.9446 17.5006 18.5043 16.9409 18.5043 16.2506V12.0574H5.5Z"
        fill={fill}
      />
    </svg>
  );
}
