import { IconSVGProps } from '../types';

export default function SVGComponent({ stroke }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2487 9.01075C16.2487 9.01075 16.2487 5.99878 11.9975 5.99878C9.3493 5.99878 7.74982 7.50394 7.74982 9.01075C7.74982 11.1898 9.74024 11.7214 11.9975 12.0227M7.74982 15.0358C7.74982 15.0358 7.74982 18.0478 12.0009 18.0478C14.6492 18.0478 16.2487 16.5426 16.2487 15.0358C16.2487 12.8451 14.2582 12.3252 12.0009 12.0238M11.9973 3.75V5.92246M11.9973 18.0782V20.2507"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
