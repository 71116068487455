import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 28C55 42.9117 42.9117 55 28 55C13.0883 55 1 42.9117 1 28C1 13.0883 13.0883 1 28 1C42.9117 1 55 13.0883 55 28ZM52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5602 20.5611C38.146 19.9754 38.146 19.0256 37.5602 18.4398C36.9745 17.854 36.0247 17.854 35.4389 18.4398L28 25.8787L20.5611 18.4398C19.9754 17.854 19.0256 17.854 18.4398 18.4398C17.854 19.0256 17.854 19.9754 18.4398 20.5611L25.8787 28L18.4398 35.4389C17.854 36.0247 17.854 36.9745 18.4398 37.5602C19.0256 38.146 19.9754 38.146 20.5611 37.5602L28 30.1214L35.4389 37.5602C36.0247 38.146 36.9745 38.146 37.5602 37.5602C38.146 36.9745 38.146 36.0247 37.5602 35.4389L30.1214 28L37.5602 20.5611Z"
        fill={fill}
      />
    </svg>
  );
}
