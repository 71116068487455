import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.1393 17.3543C36.7251 17.9402 36.7252 18.89 36.1394 19.4756L31.6139 24L36.1405 28.5269C36.7263 29.1128 36.7263 30.0625 36.1405 30.6483C35.5547 31.234 34.6049 31.234 34.0191 30.6482L29.4923 26.121L24.9682 30.644C24.3825 31.2296 23.4327 31.2293 22.8469 30.6434C22.2611 30.0574 22.2611 29.1077 22.8468 28.522L27.3707 23.9993L22.848 19.4761C22.2622 18.8903 22.2622 17.9406 22.8481 17.3548C23.4339 16.7691 24.3836 16.7691 24.9694 17.3549L29.4923 21.8782L34.018 17.3537C34.6038 16.768 35.5535 16.7683 36.1393 17.3543Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5721 6.5C14.8476 6.5 13.223 7.30877 12.1836 8.6847L3.11811 20.6847C1.63608 22.6465 1.63609 25.3535 3.11811 27.3153L12.1836 39.3153C13.223 40.6912 14.8476 41.5 16.5721 41.5H41.9835C45.021 41.5 47.4835 39.0376 47.4835 36V12C47.4835 8.96243 45.021 6.5 41.9835 6.5H16.5721ZM14.5773 10.493C15.0498 9.86762 15.7882 9.5 16.5721 9.5H41.9835C43.3642 9.5 44.4835 10.6193 44.4835 12V36C44.4835 37.3807 43.3642 38.5 41.9835 38.5H16.5721C15.7882 38.5 15.0498 38.1324 14.5773 37.507L5.51183 25.507C4.83818 24.6152 4.83818 23.3848 5.51183 22.493L14.5773 10.493Z"
        fill={fill}
      />
    </svg>
  );
}
