import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 3.5C29.5 2.67157 28.8284 2 28 2C27.1716 2 26.5 2.67157 26.5 3.5V4.32038C13.7407 5.13478 2.5 16.2174 2.5 29C2.5 29.5766 2.83052 30.1022 3.35022 30.352C3.86992 30.6017 4.48679 30.5315 4.93704 30.1713C7.24683 28.3235 9.42638 27.5 11.5 27.5C13.5736 27.5 15.7532 28.3235 18.063 30.1713C18.6108 30.6096 19.3892 30.6096 19.937 30.1713C21.6765 28.7798 24.0339 27.9067 26.5 27.6118V48C26.5 49.1136 26.1025 49.9478 25.4785 50.5151C24.8416 51.0941 23.8496 51.5 22.5 51.5C21.1504 51.5 20.1584 51.0941 19.5215 50.5151C18.8975 49.9478 18.5 49.1136 18.5 48C18.5 47.1716 17.8284 46.5 17 46.5C16.1716 46.5 15.5 47.1716 15.5 48C15.5 49.8864 16.2025 51.5522 17.5035 52.7349C18.7916 53.9059 20.5496 54.5 22.5 54.5C24.4504 54.5 26.2084 53.9059 27.4965 52.7349C28.7975 51.5522 29.5 49.8864 29.5 48V27.5409C32.1739 27.7375 34.5128 28.6341 35.9393 30.0607C36.5251 30.6464 37.4749 30.6464 38.0607 30.0607C41.4185 26.7028 46.5403 26.5532 51.063 30.1713C51.5132 30.5315 52.1301 30.6017 52.6498 30.352C53.1695 30.1022 53.5 29.5766 53.5 29C53.5 16.2174 42.2593 5.13478 29.5 4.32038V3.5ZM11.5 24.5C9.52749 24.5 7.60311 25.0345 5.73431 26.0421C7.1876 16.8745 15.2162 9.09182 24.6189 7.54971C20.8744 12.287 18.1381 18.6213 17.5977 26.2264C15.625 25.0998 13.5895 24.5 11.5 24.5ZM20.6056 26.2298C21.1908 18.6369 24.1489 12.5116 28 8.18507C31.8177 12.474 34.7578 18.5307 35.3786 26.0323C33.2948 24.985 30.8165 24.5 28.375 24.5C25.7418 24.5 22.9935 25.0618 20.6056 26.2298ZM38.3886 26.039C41.9733 23.9438 46.3143 24.0166 50.272 26.0822C48.8358 16.8973 40.7975 9.09412 31.3811 7.54972C35.0949 12.248 37.8169 18.5172 38.3886 26.039Z"
        fill={fill}
      />
    </svg>
  );
}
