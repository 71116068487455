import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7811 5.28033C20.074 4.98744 20.074 4.51256 19.7811 4.21967C19.4882 3.92678 19.0134 3.92678 18.7205 4.21967L12.0005 10.9396L5.28051 4.21967C4.98762 3.92678 4.51275 3.92678 4.21985 4.21967C3.92696 4.51256 3.92696 4.98744 4.21985 5.28033L10.9398 12.0003L4.21985 18.7203C3.92696 19.0132 3.92696 19.4881 4.21985 19.7809C4.51275 20.0738 4.98762 20.0738 5.28051 19.7809L12.0005 13.061L18.7205 19.781C19.0134 20.0738 19.4882 20.0738 19.7811 19.781C20.074 19.4881 20.074 19.0132 19.7811 18.7203L13.0612 12.0003L19.7811 5.28033Z"
        fill={fill}
      />
    </svg>
  );
}
