import React, { ForwardedRef, ReactNode, useEffect, useState } from 'react';
import { InputWrapper } from '../Input/InputWrapper';
import { VisuallyHidden } from '../../VisuallyHidden/VisuallyHidden';
import styled from 'styled-components';

export interface ToggleProps {
  label: string;
  testId?: string;
  children?: ReactNode;
  disabled?: boolean;
  hintText?: string;
  errorText?: string;
  infoText?: string;
  warningText?: string;
  onChange?: (checked: boolean) => void;
  inputProps?: {
    onBlur?: () => void;
    name?: string;
    value: string | number | readonly string[] | undefined;
  };
}

export const Toggle = React.forwardRef(
  (
    {
      label,
      testId,
      children,
      onChange,
      disabled,
      errorText,
      hintText,
      warningText,
      infoText,
      inputProps,
    }: ToggleProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [isChecked, setIsChecked] = useState(inputProps?.value === 'true');

    const onClick = () => {
      onChange && onChange(!isChecked);
      setIsChecked(!isChecked);
    };

    useEffect(() => {
      setIsChecked(inputProps?.value === 'true');
    }, [inputProps?.value]);

    return (
      <div onClick={() => !disabled && onClick()}>
        <InputWrapper
          label={label}
          inputId={inputProps?.name || ''}
          hintText={hintText}
          errorText={errorText}
          infoText={infoText}
          warningText={warningText}
        >
          <VisuallyHidden>
            <input
              ref={ref}
              disabled={disabled}
              id={inputProps?.name || ''}
              data-testid={testId}
              type="checkbox"
              defaultChecked={isChecked}
              value={isChecked.toString()}
              checked={isChecked}
              {...inputProps}
            />
          </VisuallyHidden>
          <ToggleWrapper data-testid={`toggle-${testId || inputProps?.name}`}>
            <ToggleBackground isChecked={isChecked} disabled={disabled}>
              <ToggleSwitch isChecked={isChecked} />
            </ToggleBackground>
            {children && <div>{children}</div>}
          </ToggleWrapper>
        </InputWrapper>
      </div>
    );
  }
);

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[2]};
`;

const ToggleBackground = styled.div<{ isChecked: boolean; disabled?: boolean }>`
  flex-basis: 44px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 24px;
  padding: 2px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: ${({ theme, isChecked }) => (isChecked ? theme.color.base.green[6] : theme.color.base.grey[8])};
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: background-color;
  opacity: ${({ disabled }) => (disabled ? '0.4' : 1)};
`;

const ToggleSwitch = styled.div<{ isChecked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.base.grey.white};
  transform: ${({ isChecked }) => (isChecked ? 'translateX(20px)' : 'translateX(0)')};
  transition-duration: 200ms;
  transition-property: transform;
`;
