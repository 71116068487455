import { useVaultContext } from '../../VaultProvider';
import Button from '../Button/Button';
import Container from '../Container/Container';
import { Text } from '../Text/Text';
import { useMedia } from '../../hooks/useMedia';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import { useEffect } from 'react';

export interface DialogFullScreenProps extends Omit<ReactModal.Props, 'onRequestClose'> {
  heading?: string;
  subheading?: string;
  closeButtonLabel?: string;
  children?: React.ReactNode;
  onRequestClose?: () => void;
}

export default function DialogFullScreen({
  heading,
  subheading,
  children,
  closeButtonLabel,
  isOpen,
  ...props
}: DialogFullScreenProps) {
  const vaultContext = useVaultContext();
  const isMobile = useMedia('mobile');

  if (!vaultContext.appElementSelector)
    throw new Error(
      'You need to add the app container ID to the VaultProvider if you want to use the Modal component.'
    );
  ReactModal.setAppElement(vaultContext.appElementSelector);

  const customStyles: ReactModal.Styles = {
    overlay: {
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    content: {
      position: 'absolute',
      inset: 0,
      border: 0,
      padding: 0,
      borderRadius: 0,
    },
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'initial';
  }, [isOpen]);

  return (
    <ReactModal isOpen={isOpen} {...props} style={customStyles}>
      <ModalHeader isMobile={isMobile}>
        <Container>
          <Text size="l" weight="semiBold">
            {heading}
          </Text>
          <Text weight="light">{subheading}</Text>
        </Container>

        <Button variant="tertiary" onClick={props.onRequestClose} testId="dialog-fullscreen-close">
          <Icon size="m" name="close" />
          {closeButtonLabel}
        </Button>
      </ModalHeader>
      <ModalBody>
        <Container>{children}</Container>
      </ModalBody>
    </ReactModal>
  );
}

const ModalHeader = styled.header<{ isMobile?: boolean }>`
  position: relative;
  min-height: 97px;
  padding-top: ${({ theme, isMobile }) => (isMobile ? theme.spacings[2] : theme.spacings[3])};
  padding-bottom: ${({ theme, isMobile }) => (isMobile ? theme.spacings[2] : theme.spacings[3])};
  border-bottom: 1px solid ${({ theme }) => theme.color.border.disabledBorder};
  box-sizing: border-box;

  > span {
    display: block;
  }

  > button {
    position: absolute;
    top: ${({ theme, isMobile }) => (isMobile ? theme.spacings[2] : theme.spacings[3])};
    right: ${({ theme, isMobile }) => (isMobile ? theme.spacings[2] : theme.spacings[3])};
  }
`;

const ModalBody = styled.div<{ isMobile?: boolean }>`
  padding-top: ${({ theme, isMobile }) => (isMobile ? theme.spacings[2] : theme.spacings[5])};
  padding-bottom: ${({ theme, isMobile }) => (isMobile ? theme.spacings[2] : theme.spacings[5])};
`;
