import { IconSVGProps } from '../types';

export default function SVGComponent({ fill }: IconSVGProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.5C15.5899 18.5 18.5 15.5899 18.5 12C18.5 8.41015 15.5899 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5899 8.41015 18.5 12 18.5ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13.305 9.17917C12.9083 8.54885 11.9748 8.2904 11.2234 8.68842C10.7779 8.92439 10.5403 9.31769 10.5041 9.70565L10.5 10.1929C10.4964 10.6071 10.1578 10.9401 9.74361 10.9365C9.32942 10.933 8.9965 10.5944 9.00003 10.1802L9.00441 9.66515C9.00455 9.64874 9.00523 9.63234 9.00645 9.61597C9.07541 8.68768 9.62895 7.83552 10.5213 7.36289C11.8945 6.63553 13.6704 7.02627 14.5313 8.31373C14.5477 8.33453 14.5632 8.3564 14.5776 8.37933C15.448 9.76237 14.9011 11.4987 13.472 12.2557C13.4646 12.2596 13.4573 12.2634 13.4498 12.267C13.0013 12.5131 12.7697 12.9207 12.7481 13.3171C12.7256 13.7307 12.3721 14.0477 11.9585 14.0252C11.5449 14.0027 11.2279 13.6492 11.2504 13.2356C11.3019 12.2875 11.8593 11.4118 12.7685 10.9302C12.7759 10.9263 12.7833 10.9226 12.7908 10.9189C13.474 10.5442 13.6644 9.80053 13.3358 9.22449C13.3251 9.20988 13.3148 9.19476 13.305 9.17917ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        fill={fill}
      />
    </svg>
  );
}
