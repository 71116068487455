import styled from 'styled-components';
import { Text } from '../Text/Text';
import { MediumIcons } from '../Icon/types';
import Icon from '../Icon/Icon';
import { BackgroundColors, ForegroundColors } from '../../theme/colors';

export interface TagProps {
  text: string;
  icon?: MediumIcons;
  type?: TagType;
}

type Colors = {
  backgroundColor: BackgroundColors;
  color: ForegroundColors;
};

const getColors = (type: TagType): Colors => {
  switch (type) {
    case 'neutral':
      return {
        backgroundColor: 'tertiaryBg',
        color: 'primary',
      };
    case 'positive':
      return {
        backgroundColor: 'positiveBg',
        color: 'positive',
      };
    case 'info':
      return {
        backgroundColor: 'infoBg',
        color: 'info',
      };
    case 'warning': {
      return {
        backgroundColor: 'warningBg',
        color: 'warning',
      };
    }
    case 'negative': {
      return {
        backgroundColor: 'negativeBg',
        color: 'negative',
      };
    }
    default:
      return {
        backgroundColor: 'tertiaryBg',
        color: 'primary',
      };
  }
};

const TagStyled = styled.span<{ type: TagType }>`
  padding: ${({ theme }) => theme.spacings['0.5']} ${({ theme }) => theme.spacings['1.5']};
  background-color: ${({ type, theme }) => theme.color.background[getColors(type).backgroundColor]};
  border-radius: ${({ theme }) => theme.spacings['0.5']};

  display: flex;
  max-width: fit-content;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings['0.5']};
  align-items: center;
`;

export const tagTypeArray = ['positive', 'neutral', 'warning', 'negative', 'info'] as const;
export type TagType = (typeof tagTypeArray)[number];

export const Tag = ({ text, icon, type = 'neutral' }: TagProps) => {
  const color = getColors(type).color;

  return (
    <TagStyled type={type}>
      {icon && <Icon size="m" name={icon} color={color} />}
      <Text size="s" weight="semiBold" color={color}>
        {text}
      </Text>
    </TagStyled>
  );
};
